import { memo, useState, useEffect } from 'react'
import { Navigate as Redirect, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import _ from 'lodash'
import { useLocalStorage } from '@rehooks/local-storage'

import LeftMenu from './components/LeftMenu/LeftMenu'
import { leftMenuList } from './components/LeftMenu/LeftMenuData'
import { theme } from './styles/themes/theme'

type Props = {
  children: React.ReactNode
  location?: string
  layout: string
}

const Layout = memo((props: Readonly<Props>) => {
  const lastLocation = useLocation()
  const user = localStorage.getItem('user')
  const [leftMenuLS, setLeftMenuLS] = useLocalStorage<boolean[]>('leftMenu2')
  const [menu, setMenu] = useState<ReturnType<typeof leftMenuList>>([])

  const [leftMenuDrawer, setLeftMenuDrawer] = useState(false)

  const leftMenu = leftMenuList()

  useEffect(() => {
    if (menu.length === 0) {
      setLeftMenuLS(
        leftMenu.map((item) => {
          return item.active
        })
      )
      if (_.isNil(leftMenuLS)) {
        setMenu(leftMenu)
      } else {
        setMenu(
          leftMenu.map((item, i: number) => {
            return { ...item, active: leftMenuLS[i] }
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (props.layout === 'login' && !user) {
    return <>{props.children}</>
  }

  if (user && props.layout === 'login') {
    window.location.href = '/'
  }

  if (user === null) {
    const redirectTo = `${lastLocation.search}`
    const inOf = redirectTo.indexOf('?state')
    if (inOf === 0) {
      window.history.replaceState(null, '', lastLocation.pathname)
    }
  }

  return (
    <Box>
      {user === null ? (
        <>
          {props.layout !== 'login' && (
            <LeftMenu
              menu={menu}
              setMenu={setMenu}
              open={leftMenuDrawer}
              setOpen={setLeftMenuDrawer}
            />
          )}

          <Box
            sx={{
              mt: 10,
              ml: 0,
              [theme.breakpoints.up('md')]: {
                ml: 5,
              },
            }}
          >
            {props.children}
          </Box>
        </>
      ) : (
        <Redirect to="/" replace state={{ from: props.location }} />
      )}
    </Box>
  )
})

Layout.displayName = 'Layout'

export default Layout
