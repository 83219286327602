import { Box, Typography } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'
import { themeColor } from '../../../../../../styles/themes/themeData'
import CustomButton from '../../../../../../components/Input/CustomButton'
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp'
import { META } from '../../../../../../constants/webMeta'

export function AssignReject() {
  const { isLoading } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )
  return (
    <CustomCard
      isLoading={isLoading}
      sx={{ height: '145px', alignContent: 'center' }}
    >
      <Box display={'flex'} gap={2}>
        <Box
          sx={{
            p: 1,
            pb: 0,
            borderRadius: 2,
            backgroundColor: themeColor.grey.grey100,
            position: 'relative',
          }}
          width={'62px'}
          height={'62px'}
          alignItems={'center'}
          textAlign={'center'}
        >
          <img
            alt={META.ALT}
            src={`${process.env.PUBLIC_URL}/icons/icons-cross.png`}
            style={{
              position: 'absolute',
              display: 'block',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
        <Box>
          <Typography variant="body1b">ไม่ผ่านคุณสมบัติ</Typography>
          <Typography mb={1} variant="body2" sx={{ color: 'text.gray' }}>
            คลิ๊กเพื่อเปิดดูรายละเอียด
          </Typography>
          <CustomButton size={'small'} variant={'outlined'}>
            <Typography variant="body2">เปิดดูรายละเอียด</Typography>{' '}
            <KeyboardArrowRightSharpIcon />
          </CustomButton>
        </Box>
      </Box>
    </CustomCard>
  )
}
