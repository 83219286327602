import { useState } from 'react'
import Step from '@mui/material/Step'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'

import { CPageContainer } from '../../../components/CRUD/components/pageContainer/index'
//
import UploadFileStep from './UploadFileStep'
import CheckInformationStep from './CheckInformationStep'
import ImportDataStep from './ImportDataStep'
import Header from './Header'
import { steps } from './model/import-data'
import { StepperStyle, StepLabelStyle } from './styled'

export default function ProspectImportData() {
  const [activeStep, setActiveStep] = useState(0)

  const theme = useTheme()

  const importStepNumber = new Map([
    [0, <UploadFileStep key="uploadFile" setActiveStep={setActiveStep} />],
    [1, <CheckInformationStep key="checkInfo" setActiveStep={setActiveStep} />],
    [2, <ImportDataStep key="importData" setActiveStep={setActiveStep} />],
  ])

  return (
    <CPageContainer
      header={<Header />}
      content={
        <>
          <Typography variant="h6" mb={3}>
            การนำเข้าข้อมูล
          </Typography>

          <StepperStyle activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabelStyle>{label}</StepLabelStyle>
              </Step>
            ))}
          </StepperStyle>

          {/* Render step content */}
          {importStepNumber.get(activeStep)}
        </>
      }
      sxProps={{ boxShadow: `0px 0px 4px 0px ${theme.palette.common.black}33` }}
    />
  )
}
