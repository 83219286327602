import React from 'react'
import {
  LoadingCircular,
  StyledCard,
  StyledContainer,
  StyledContent,
  StyledHeader,
} from '../../../Styled'
import Table from '../../../Table'
import { useSelector } from '../../../../redux/store'
import { selectTableLoading } from '../../../../redux/selector'
import { CContainerProps } from './types/cContainerProps'

export const CContainer: React.FC<CContainerProps> = ({
  header,
  drawer,
  content,
}) => {
  const isLoading = useSelector(selectTableLoading)

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>{header}</StyledHeader>
        <StyledCard id="list-table" isLoading={isLoading}>
          {content}

          {/* TABLE SECTION */}
          <Table />
        </StyledCard>
        {drawer}
      </StyledContent>

      <LoadingCircular isLoading={isLoading} />
    </StyledContainer>
  )
}
