import { crudConfig } from '../../../crudConfig'
import { getModuleTitle } from '../../../utils/getModuleTitle'

export function breadcrumb(uuid: string) {
  return [
    {
      title: 'Master Data',
      link: '/',
      pointer: false,
    },
    {
      title: crudConfig.moduleTitleSecond,
      link: crudConfig.modulePath,
      pointer: true,
    },
    {
      title: getModuleTitle(uuid, crudConfig.modulesTitleShort),
      link: `${crudConfig.modulePath}/form`,
      pointer: false,
    },
  ]
}
