export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterItems = [
  {
    label: 'ช่องทางภาษาอังกฤษ',
    nameCheckBox: 'channelENChecked',
    nameInput: 'channelEN',
    type: 'text',
  },
  {
    label: 'ช่องทางภาษาไทย',
    nameCheckBox: 'channelTHChecked',
    nameInput: 'channelTH',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
