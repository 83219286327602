import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'SOCIAL_SUB_SOURCE',
  moduleSlug: 'social-sub-source',
  moduleTitleFull: 'รายการแหล่งย่อยทางสังคมสำหรับการสร้างการเลือกแหล่งย่อย',
  moduleTitleSecond: 'รายการแหล่งย่อยสังคม',
  modulesTitleShort: 'แหล่งย่อยสังคม',
  modulePath: '/social-sub-source',
  filterPath: `${CRUDListFilterPath}`,
  headerCells,
}
