export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterDropFromSystemItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'startChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'closeChecked',
  },
]

export const filterItems = [
  {
    label: 'เหตุผล',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    type: 'text',
  },
  {
    label: 'สถานะทิ้งโดยระบบ',
    nameCheckBox: 'dropFromSystemChecked',
    nameInput: 'dropFromSystem',
    type: 'checkbox',
    list: filterDropFromSystemItems,
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
