import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'

import { ContentHistory, HeadLabelHistory } from './styled'
import { DrawerHistory } from './components/DrawerHistory'
import { setFieldValue } from '../../../CRUD/slices'
import Table from '../../../Table'
import { fetchHistory } from '../../handler/fetchHistory'
import {
  selectPaginationTable,
  selectTableLoading,
  selectOpenHistoryDrawer,
} from '../../../../redux/selector'
import { useSelector, useDispatch } from '../../../../redux/store'

const CHistoryDrawer = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()

  const { page, sort, limit } = useSelector(selectPaginationTable)
  const isLoading = useSelector(selectTableLoading)
  const isShowHistoryDrawer = useSelector(selectOpenHistoryDrawer)

  const realPage = page <= 0 ? 1 : page
  const params = `?limit=${limit}&page=${realPage}`

  useEffect(() => {
    if (isShowHistoryDrawer) {
      fetchHistory({ uuid, params }).then()
    }
  }, [isShowHistoryDrawer, page, sort, limit, params, uuid])

  return (
    <DrawerHistory
      anchor="right"
      open={isShowHistoryDrawer}
      onClose={() =>
        dispatch(setFieldValue({ key: 'isShowHistoryDrawer', value: false }))
      }
    >
      <ContentHistory isLoading={isLoading}>
        <HeadLabelHistory>
          <Box>
            <Typography variant="h5">ประวัติการแก้ไข</Typography>
            <IconButton
              data-testid="btn-close-history"
              color="primary"
              component="span"
              onClick={() =>
                dispatch(
                  setFieldValue({ key: 'isShowHistoryDrawer', value: false })
                )
              }
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ mx: 4, mt: 3 }}>
            <Table />
          </Box>
        </HeadLabelHistory>
      </ContentHistory>
    </DrawerHistory>
  )
}

export default CHistoryDrawer
