import dayjs from 'dayjs'

interface DisableDate {
  startDate: Date
  finishDate: Date
}

export const getDisabledDates = (dates: DisableDate[]): Date[] => {
  const arr: Date[] = []

  if (dates && dates.length > 0) {
    dates.forEach((disable) => {
      const { startDate, finishDate } = disable
      for (
        const date = new Date(startDate);
        date <= new Date(finishDate);
        date.setDate(date.getDate() + 1)
      ) {
        arr.push(new Date(date))
      }
    })
  }

  return arr
}

export const formatDateDisplay = (date: Date): string =>
  dayjs(new Date(date)).format(
    window.__env__.REACT_APP_DATE_SHOW || 'DD/MM/YYYY'
  )
