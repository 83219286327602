import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, ProspectDetailState } from './model'
import { ProspectDetail } from '../type/types'

const ProspectDetailSlice = createSlice({
  name: 'prospect-detail',
  initialState,
  reducers: {
    setProspectDetail: (
      state: ProspectDetailState,
      action: PayloadAction<ProspectDetail>
    ) => {
      state.data = action.payload
    },
    setProspectDetailIsLoading: (
      state: ProspectDetailState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload
    },
  },
})
export const { setProspectDetail, setProspectDetailIsLoading } =
  ProspectDetailSlice.actions

export default ProspectDetailSlice.reducer
