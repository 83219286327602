import { store } from '../../../../../../redux/store'
import { STATUS } from '../model/status'

export const mutateFilterSateToProp = () => {
  const { filterState }: any = store.getState().table
  let status = []
  let dropFromSystem = []

  if (filterState?.statusChecked) {
    if (filterState?.status?.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState?.status?.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState?.status?.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }

  if (filterState?.dropFromSystemChecked) {
    if (filterState?.dropFromSystem?.startChecked) {
      dropFromSystem.push(STATUS.ACTIVE)
    }
    if (filterState?.dropFromSystem?.closeChecked) {
      dropFromSystem.push(STATUS.INACTIVE)
    }
  } else {
    dropFromSystem = []
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    dropFromSystem: dropFromSystem,
    status: status,
  }
}
