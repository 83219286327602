export const headerCells: any = [
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'firstName',
    label: 'First Name',
  },
  {
    id: 'lastName',
    label: 'Last Name',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: '/manage/staff-profile/view',
    editPath: '/manage/staff-profile/form',
    hideDelete: true,
  },
]
