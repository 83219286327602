import {
  startLoading,
  stopLoading,
} from '../../../../../../components/Table/redux'
import { dispatch, store } from '../../../../../../redux/store'
import { downloadFileManager } from '../../../../../../services/utils/downloadFile'
import { ManagerDownloadAllPath } from '../../../../../../utils/apiPath'

export const onDownloadFile = async () => {
  const { crudConfig } = store.getState().crud
  dispatch(startLoading())

  dispatch(
    downloadFileManager({
      url: ManagerDownloadAllPath,
      fileName: `ไฟล์${crudConfig?.moduleTitleFull}.xlsx`,
    })
  )
  dispatch(stopLoading())
}
