export const filterItems = [
  {
    label: 'เลขที่ตัวแทนผู้จัดการ',
    nameCheckBox: 'agentCodeChecked',
    nameInput: 'agentCode',
    type: 'text',
  },
  {
    label: 'ชื่อ ผู้มุ่งหวัง',
    nameCheckBox: 'firstNameChecked',
    nameInput: 'firstName',
    type: 'text',
  },
  {
    label: 'นามสกุล ผู้มุ่งงหวัง',
    nameCheckBox: 'lastNameChecked',
    nameInput: 'lastName',
    type: 'text',
  },
  {
    label: 'อีเมล',
    nameCheckBox: 'emailChecked',
    nameInput: 'email',
    type: 'text',
  },
  {
    label: 'รอบนำเข้า',
    nameCheckBox: 'importCountChecked',
    nameInput: 'importCount',
    type: 'text',
  },
]
