import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import isEmpty from 'lodash/isEmpty'
import {
  useMemo,
  type Dispatch as DispatchState,
  type SetStateAction,
} from 'react'
import Button from '@mui/material/Button'
import Stack, { type StackProps } from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import useTheme from '@mui/material/styles/useTheme'

import BasicUpload from '../../../components/Upload/BasicUpload'
import ProgressCircularWithLabel from '../../../components/Progress/ProgressCircularWithLabel'
import {
  dispatch as appDispatch,
  useDispatch,
  useSelector,
} from '../../../redux/store'
import { UPLOAD_IN_PROGRESS, UPLOAD_SUCCESS } from '../../../constants/ma'
import {
  clearUploadedData,
  getUploadExcelData,
  hasError,
} from '../../../components/Upload/slices'
import { themeColor } from '../../../styles/themes/themeData'
import Image from '../../../components/Image/Image'
import UploadSingleFile from '../../../components/Upload/UploadSingleFile'
import { handleUploadFile } from '../../../utils/dispatchError'

interface IStackProps extends StackProps {
  isError: boolean
}

const RootStyle = styled(Stack, {
  shouldForwardProp: (props) => props !== 'isError',
})<IStackProps>(({ theme, isError }) => ({
  minHeight: 78,
  width: '100%',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  borderRadius: 8,
  backgroundColor: !isError
    ? `${theme.palette.blue.oceanBlue}14`
    : `${theme.palette.text.error}14`,

  [theme.breakpoints.up('sm')]: {
    minHeight: 'unset',
    height: 78,
  },
}))

function getUploadFile(file?: File) {
  // Get the selected file from the input element
  if (file) {
    // handle file upload
    appDispatch(hasError(null))
    appDispatch(getUploadExcelData(file))
  }
}

type Props = {
  setActiveStep: DispatchState<SetStateAction<number>>
}

export default function UploadFileStep({ setActiveStep }: Readonly<Props>) {
  const navigate = useNavigate()

  const theme = useTheme()

  const dispatch = useDispatch()
  const { data, error, uploadProgress, isLoading } = useSelector(
    (state) => state.upload
  )

  const uploadFileNextStep = () => {
    if (!data?.uploadData?.length) {
      dispatch(hasError('กรุณาเลือกไฟล์'))

      return
    }

    setActiveStep((prev) => prev + 1)
  }

  const uploadFileCancel = () => {
    navigate('/candidate')
  }

  const disabledButton = useMemo(
    () => isLoading && uploadProgress !== UPLOAD_SUCCESS,
    [isLoading, uploadProgress]
  )

  return (
    <>
      <Typography variant="h6" my={2}>
        อัปโหลดไฟล์
      </Typography>

      {/* UPLOAD RESULT */}
      {uploadProgress >= UPLOAD_IN_PROGRESS ? (
        <UploadResult />
      ) : (
        // UPLOAD COMPONENT
        <UploadSingleFile
          imgPath="/images/manager/amico.svg"
          description={<UploadDragAndDrop />}
          accept={{
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              [], // .xlsx
          }}
          onDrop={(file) => handleUploadFile(file, ['xlsx'], getUploadFile)}
          sx={{
            mb: { xs: 2, md: 0 },
            width: 1,
            cursor: 'pointer',
            borderRadius: '8px',
            border: `2px dashed ${theme.palette.common.black}1F`,
            padding: theme.spacing(0, 4),

            ...(!isEmpty(error) && {
              backgroundColor: `${theme.palette.text.error}14 !important`,
              borderColor: theme.palette.error.main,
            }),
          }}
          textError={typeof error === 'string' ? error : error?.message}
        />
      )}

      <Stack flexDirection="row" gap={1.5} justifyContent="flex-end" mt={1}>
        <Button
          variant="outlined"
          sx={{ borderRadius: '4px', color: themeColor.primary.light }}
          onClick={uploadFileCancel}
          disabled={disabledButton}
        >
          ยกเลิก
        </Button>

        <Button
          variant="contained"
          sx={{ borderRadius: '4px', color: themeColor.primary.contrast }}
          onClick={uploadFileNextStep}
          disabled={disabledButton}
        >
          ถัดไป
        </Button>
      </Stack>
    </>
  )
}

function UploadDragAndDrop() {
  return (
    <BasicUpload
      handleUploadFile={(file: any) =>
        handleUploadFile(file, ['xlsx'], getUploadFile)
      }
      required
      sx={{
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        gap: { xs: 2, md: 6 },
        py: { xs: 4, md: 0 },
        cursor: 'pointer',
      }}
      acceptFiles=".xlsx"
      description={
        <>
          <Stack justifyContent="center" alignItems="center">
            <Typography
              variant="body1"
              color={themeColor.text.primary}
              fontWeight={700}
            >
              วางไฟล์ที่นี่
            </Typography>
            <Typography variant="body1" color={themeColor.text.secondary}>
              .xlsx
            </Typography>
            <Typography variant="body1" color={themeColor.text.secondary}>
              ขนาดไฟล์ไม่เกิน 2 MB
            </Typography>
          </Stack>

          <Typography
            variant="body1"
            color={themeColor.text.primary}
            fontWeight={700}
          >
            หรือ
          </Typography>
        </>
      }
    />
  )
}

function UploadResult() {
  const { data, error, uploadProgress } = useSelector((state) => state.upload)

  const dispatch = useDispatch()

  const handleCancel = () => {
    dispatch(clearUploadedData())
  }

  return (
    <RootStyle isError={!isEmpty(error)}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack flexDirection="row" alignItems="center" gap={3}>
          {uploadProgress !== UPLOAD_SUCCESS ? (
            <>
              {/* UPLOAD IN-PROGRESS */}
              <ProgressCircularWithLabel value={uploadProgress} />

              <Image
                src="/icons/icon-excel.svg"
                alt="Upload in-progress"
                effect="opacity"
              />
            </>
          ) : (
            <>
              {/* UPLOAD SUCCESSFUL */}
              <CheckCircleIcon sx={{ color: themeColor.text.success }} />

              <Image
                src="/icons/icon-export-excel.svg"
                alt="Import manager"
                effect="opacity"
              />
            </>
          )}

          <Box>
            <Typography variant="body1" color="primary">
              {data.fileName}
            </Typography>
          </Box>
        </Stack>

        <IconButton onClick={handleCancel}>
          <CloseRoundedIcon sx={{ color: 'common.black' }} />
        </IconButton>
      </Stack>
    </RootStyle>
  )
}
