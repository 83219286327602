import React, {
  memo,
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react'
import TruncateMarkup from 'react-truncate-markup'
import { StyledTruncateBox } from './styled'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'

interface TruncateProps {
  children: React.ReactNode
}

const Truncate: React.FC<TruncateProps> = memo(({ children }) => {
  const [expand, setExpand] = useState(false)
  const [isTruncated, setIsTruncated] = useState(false)
  const [flag, setFlag] = useState(false)
  const [width, setWidth] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && ref.current.clientWidth !== width) {
      setWidth(ref.current.clientWidth)
    }
  }, [width])

  useEffect(() => {
    setExpand(false)
    setFlag((prev) => !prev)
  }, [children])

  if (!children) {
    return null
  }

  return (
    <div ref={ref}>
      {!expand && flag && (
        <TextWithTruncate
          width={width}
          isTruncated={isTruncated}
          setIsTruncated={setIsTruncated}
          setExpand={setExpand}
        >
          {children}
        </TextWithTruncate>
      )}
      {!expand && !flag && (
        <TextWithTruncate
          width={width}
          isTruncated={isTruncated}
          setIsTruncated={setIsTruncated}
          setExpand={setExpand}
        >
          {children}
        </TextWithTruncate>
      )}
      {expand && (
        <StyledTruncateBox
          data-testid="truncate-expand"
          maxWidth={width}
          isTruncated={isTruncated}
          onClick={() => setExpand(false)}
        >
          {' '}
          {children} {isTruncated && <KeyboardArrowUp />}
        </StyledTruncateBox>
      )}
    </div>
  )
})

Truncate.displayName = 'Truncate'

export default Truncate

interface TextWithTruncateProps {
  width: number
  isTruncated: boolean
  setIsTruncated: Dispatch<SetStateAction<boolean>>
  setExpand: Dispatch<SetStateAction<boolean>>
  children: React.ReactNode
}

export const TextWithTruncate: React.FC<TextWithTruncateProps> = memo(
  (props) => {
    const { width, isTruncated, setIsTruncated, setExpand, children } = props

    return (
      <TruncateMarkup
        lines={1}
        onTruncate={(isTrunC) => setIsTruncated(isTrunC)}
        ellipsis={<span>... </span>}
      >
        <StyledTruncateBox
          data-testid="truncate-not-expand"
          maxWidth={width}
          isTruncated={isTruncated}
          onClick={() => setExpand(true)}
        >
          {children}
        </StyledTruncateBox>
      </TruncateMarkup>
    )
  }
)

TextWithTruncate.displayName = 'TextWithTruncate'
