import { store } from '../../../../../../redux/store'
import { STATUS } from '../model/status'

export function mutateFilterSateToProp() {
  const { filterState }: any = store.getState().table
  let status = []
  if (filterState?.statusChecked) {
    if (filterState?.status?.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState?.status?.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState?.status?.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }

  return {
    title: filterState.titleChecked ? filterState.title : '',
    description: filterState.descriptionChecked ? filterState.description : '',
    status: status,
  }
}
