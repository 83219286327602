import { useEffect, type Dispatch, type SetStateAction } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import InProgress from './InProgress'
import { WrapperInProgressStyle } from './styled'
import {
  useDispatch,
  dispatch as appDispatch,
  useSelector,
} from '../../../redux/store'
import ProgressCircularWithValueLabel from '../../../components/Progress/ProgressCircularWithLabel'
import type { IImportProspect } from './CheckInformationStep'

import { startLoading } from '../../../components/Table/redux'
import { callAPI } from '../../../utils/callAPI'
import { importProspect } from '../../../utils/apiPath'
import {
  hasError,
  uploadProgress as setUploadProgress,
  clearUploadedData,
} from '../../../components/Upload/slices'
import { selectCrudFormData } from '../../../redux/selector'

function importProspectSubmit(
  importData: IImportProspect[],
  leadSource: 'event' | 'offline',
  subSource: string
) {
  return async () => {
    appDispatch(startLoading())

    const payload = {
      data: importData,
      leadSource,
      subSource,
    }

    try {
      await callAPI({
        baseURL: window.__env__.REACT_APP_API_URL,
        url: importProspect,
        body: payload,
        method: 'POST',
      })
    } catch (error) {
      appDispatch(hasError(error)) // * Set error
      appDispatch(setUploadProgress(0)) // * Reset progress in case of error
    }
  }
}

type Props = {
  setActiveStep: Dispatch<SetStateAction<number>>
}

export default function ImportDataStep({ setActiveStep }: Readonly<Props>) {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { error, uploadProgress, isLoading, data } = useSelector(
    (state) => state.upload
  )

  const crudFormData = useSelector(selectCrudFormData)

  const theme = useTheme()

  const handlePrev = () => {
    setActiveStep((prev) => prev - 1)
  }

  const handleImportSuccess = () => {
    navigate('/candidate')

    dispatch(clearUploadedData())
  }

  useEffect(() => {
    dispatch(
      importProspectSubmit(
        data.uploadData,
        crudFormData?.leadSource?.value,
        crudFormData?.subSource?.value
      )
    )
  }, [
    crudFormData?.leadSource?.value,
    crudFormData?.subSource?.value,
    data.uploadData,
    dispatch,
  ])

  return (
    <Box mb={2}>
      <Typography variant="h6">นำเข้าข้อมูล</Typography>

      <WrapperInProgressStyle isError={!!error}>
        {isLoading ? (
          <>
            {/* IMPORT IN-PROGRESS */}
            <InProgress
              icon={<ProgressCircularWithValueLabel value={uploadProgress} />}
              title="กำลังนำเข้าข้อมูล"
              desc="กรุณาอย่าปิดหน้าจนกว่าจะทำรายการสำเร็จ"
              key="import-manager-in-progress"
            />
          </>
        ) : (
          <>
            {/* IMPORT IS AN ERROR */}
            {error ? (
              <InProgress
                icon={
                  <CancelRoundedIcon
                    color="error"
                    fontSize="large"
                    sx={{ width: 56, height: 56 }}
                  />
                }
                title="ผิดพลาด"
                desc="กรุณาลองใหม่อีกครั้ง"
                key="import-manager-failure"
              />
            ) : (
              // IMPORT IS SUCCESSFUL
              <InProgress
                icon={
                  <CheckCircleIcon
                    color="success"
                    fontSize="large"
                    sx={{ width: 56, height: 56 }}
                  />
                }
                title="นำเข้าสำเร็จ!"
                desc=""
                key="import-manager-failure"
              />
            )}
          </>
        )}
      </WrapperInProgressStyle>

      <Stack alignItems="center" justifyContent="flex-end">
        {/* BUTTON ACTIONS */}
        {isLoading ? (
          // IMPORT IN-PROGRESS
          <Button
            variant="outlined"
            disabled
            sx={{
              color: theme.palette.primary.light,
              borderRadius: '4px',
              margin: '1rem 0 0 auto',
            }}
          >
            ยกเลิก
          </Button>
        ) : (
          <>
            {!error ? (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  borderRadius: '4px',
                  margin: '1rem 0 0 auto',
                }}
                onClick={handleImportSuccess}
              >
                เสร็จสิ้น
              </Button>
            ) : (
              <Button
                variant="outlined"
                sx={{
                  color: theme.palette.blue.indigo,
                  borderRadius: '4px',
                  margin: '1rem 0 0 auto',
                }}
                onClick={handlePrev}
              >
                ย้อนกลับ
              </Button>
            )}
          </>
        )}
      </Stack>
    </Box>
  )
}
