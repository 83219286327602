import { useCallback, useEffect, useState } from 'react'

import { CPageContainer } from '../../../../../components/CRUD/components/pageContainer'
import Header from './Header'
import BasicInfo from './components/basicInfo'
import { useDispatch } from '../../../../../redux/store'
import {
  setFieldValue,
  setFormIsLoading,
} from '../../../../../components/CRUD/slices'
import { callAPI } from '../../../../../utils/callAPI'
import {
  simpleRuleProvince,
  simpleRuleWorkingArea,
} from '../../../../../utils/apiPath'
import type {
  ResponseProvince,
  ResponseWorkingArea,
} from '../../../../../types/locations'

export default function NearbyAreaForm() {
  const dispatch = useDispatch()

  const [topPosition, setTopPosition] = useState(0)

  const getLocations = useCallback(async () => {
    dispatch(setFormIsLoading(true))

    const [provinces, workingArea] = await Promise.all([
      callAPI<{ totalCount: number; result: ResponseProvince[] }>({
        url: simpleRuleProvince,
        method: 'GET',
      }),
      callAPI<{
        status: boolean
        result: ResponseWorkingArea
        totalCount: number
      }>({
        url: simpleRuleWorkingArea,
        method: 'POST',
        // * get all working area by falsy values
        body: {
          quickSearch: null,
          area: '',
          limit: -1,
          page: 1,
          order: 'ASC',
          sort: 'area',
        },
      }),
    ])

    dispatch(
      setFieldValue({ key: 'formData.provinces', value: provinces.result })
    )
    dispatch(
      setFieldValue({ key: 'formData.workingAreas', value: workingArea.result })
    )

    dispatch(setFormIsLoading(false))
  }, [dispatch])

  useEffect(() => {
    getLocations()
  }, [getLocations])

  return (
    <CPageContainer
      gap={3}
      header={<Header topPosition={topPosition} />}
      content={<BasicInfo setTopPosition={setTopPosition} />}
    />
  )
}
