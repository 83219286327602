import { store } from '../../../redux/store'
import { setInitialTable, startLoading, stopLoading } from '../../Table/redux'
import { callAPI } from '../../../utils/callAPI'
import { historyEvent } from '../enum/historyEvent'
import _ from 'lodash'
import { StyledTable } from '../components/History/styled'

export const headCellsHistory = [
  {
    id: 'event',
    label: 'รายการ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'author',
    label: 'ผู้แก้ไข',
    disablePadding: false,
    hideSortIcon: true,
  },

  {
    id: 'createdAt',
    label: 'วันเวลา',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const fetchHistory = async ({ uuid }: { uuid: any; params?: any }) => {
  const { table } = store.getState().table
  const { crudConfig } = store.getState().crud

  const realPage = table.page <= 0 ? 1 : +table.page
  store.dispatch(startLoading())

  store.dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: [],
      allCount: 0,
      headCells: headCellsHistory,
    })
  )

  const url = `/crud/history/${uuid}?limit=${table.limit}&page=${realPage}`

  const response = await callAPI({
    method: 'get',
    url,
    headers: { 'x-type': crudConfig?.moduleId || '' },
  })

  interface HistoryEvent {
    event: 'CREATED' | 'UPDATED' | 'DELETED' // Define the possible event types
  }
  if (response) {
    const rows = response.result.map((row: HistoryEvent) => ({
      ...row,
      event: historyEvent[row.event] || '',
      createdBy: _.get(row, 'author', ''),
    }))
    store.dispatch(
      setInitialTable({
        isCheckBox: false,
        rows,
        allCount: _.get(response, 'totalCount', 0),
        headCells: headCellsHistory,
        customStyle: StyledTable,
      })
    )
  }
  store.dispatch(stopLoading())
}
