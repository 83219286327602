import { enumStatus } from '../../../../../../components/CRUD/enum/status'
import * as yup from 'yup'

export const formValidationSchema = yup.object({
  channelEN: yup
    .string()
    .required('กรุณาระบุช่องทาง (อังกฤษ)')
    .max(100, 'กรุณาระบุช่องทาง (อังกฤษ)ไม่เกิน 100 ตัวอักษร')
    .matches(
      /^[a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>~`[\]\\\/+=-_]*$/,
      'กรุณาระบุเฉพาะภาษาอังกฤษเท่านั้น'
    ),
  channelTH: yup
    .string()
    .required('กรุณาระบุช่องทาง (ไทย)')
    .max(100, 'กรุณาระบุช่องทาง (ไทย)ไม่เกิน 100 ตัวอักษร')
    .matches(
      /^[\u0E00-\u0E7F0-9\s!@#$%^&*(),.?":{}|<>~`[\]\\\/+=-_]*$/,
      'กรุณาระบุเฉพาะภาษาไทยเท่านั้น'
    )
    .matches(/^[^a-zA-Z]*$/, 'กรุณาระบุเฉพาะภาษาไทยเท่านั้น'),
  status: yup.string().oneOf(Object.values(enumStatus)),
})
