export const defaultFilter = {
  nameChecked: false,
  name: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  descriptionChecked: false,
  description: '',
  emailChecked: false,
  email: '',
  locationChecked: false,
  location: '',
  dateChecked: false,
  date: '',
  dropFromSystemChecked: false,
  dropFromSystem: {
    startChecked: false,
    closeChecked: false,
  },
  sendChecked: false,
  send: {
    sendActiveChecked: false,
    sendInActiveChecked: false,
  },
  channelENChecked: false,
  channelEN: '',
  channelTHChecked: false,
  channelTH: '',
  titleChecked: false,
}
