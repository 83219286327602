import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'
import dayjs from 'dayjs'

export const postBodyMutation = (data: any) => {
  const status = get(data, 'status', enumStatus.INACTIVE)
  const startDate = formatDate(get(data, 'startDate', null))
  const endDate = formatDate(get(data, 'endDate', null))
  const renewDate = formatDate(get(data, 'renewDate', null))

  return {
    uuid: get(data, 'uuid', undefined),
    id: get(data, 'id', ''),
    name: get(data, 'name', ''),
    email: get(data, 'email', ''),
    status: !isEmpty(status) ? status : enumStatus.INACTIVE,
    startDate: startDate,
    endDate: endDate,
    renewDate: renewDate,
  }
}

const formatDate = (value: any) => {
  const format = 'MM/DD/YYYY'
  let dateValue = value

  if (!isEmpty(dateValue)) dateValue = dayjs(new Date(dateValue)).format(format)
  else dateValue = null

  return dateValue
}
