import { createAsyncThunk } from '@reduxjs/toolkit'
import { callAPI } from '../../../../../../../utils/callAPI'
import { API_URL } from '../../../../../../../constants/envConfig'
import { FormData } from '../../../../../../../components/CRUD/types/CrudState'

export const callAssignManager = createAsyncThunk(
  'appointment/call-assign-manager',
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/admin/assign-manager`,
        method: 'post',
        body: payload,
      })
      return response
    } catch (err) {
      console.log('err ->>', err)

      return rejectWithValue(null)
    }
  }
)
export const callManagerList = createAsyncThunk(
  'appointment/call-manager-list',
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/simple-rule/list/manager`,
        method: 'post',
        body: payload,
      })
      return response
    } catch (err) {
      console.log('err ->>', err)
      return rejectWithValue(null)
    }
  }
)

export const callProvinceList = createAsyncThunk(
  'simple-rule/provinces',
  async (_, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/simple-rule/provinces`,
        method: 'get',
      })
      return response
    } catch (err) {
      console.log('err ->>', err)
      return rejectWithValue(null)
    }
  }
)
