import { setClearForm } from '../../../../../../../components/CRUD/slices'
import { AppDispatch } from '../../../../../../../redux/store'
import { FormData } from '../../../../../../../components/CRUD/types/CrudState'
import { ManagerGrid } from '../../../../../../../components/Lookup/ManagerLookup/slices/model'

import { handleSubmitFormAssign } from './submitFormAssign'
import { openDialog } from '../../../../../../../components/dialog/slices'
import { Manager } from '../../../../type/types'

export const handleConfirmAssign =
  (row: ManagerGrid | undefined, form: FormData) =>
  async (dispatch: AppDispatch) => {
    if (!row) return
    const param: Manager = {
      ...form,
      ...row,
      prospectUuid: form.prospectUuid,
      managerUuid: row.uuid,
    }

    dispatch(
      openDialog({
        title: 'ยืนยันบันทึก',
        message: 'คุณต้องการบันทึกนี้หรือไม่',
        handleConfirm: () => {
          dispatch(handleSubmitFormAssign(param))
        },
        isCloseDialog: false,
        type: 'confirm',
      })
    )
    dispatch(setClearForm())
  }
