import { store } from '../../../../../../redux/store'
import { STATUS } from '../model/status'
import { getFormatDate } from '../../../../../../utils/getFormatDate'
import { formatDate } from '../constants/formatDate'

export const mutateFilterSateToProp = () => {
  const { filterState }: any = store.getState().table
  let status = []
  if (filterState?.statusChecked) {
    if (filterState?.status?.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState?.status?.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState?.status?.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    description: filterState.descriptionChecked ? filterState.description : '',
    location: filterState.locationChecked ? filterState.location : '',
    dateStartDate: filterState.dateChecked
      ? getFormatDate(filterState.dateStartDate, formatDate)
      : '',
    dateExprityDate: filterState.dateChecked
      ? getFormatDate(filterState.dateExprityDate, formatDate)
      : '',
    status: status,
  }
}
