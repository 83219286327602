import debounce from 'lodash/debounce'
import { CContainer } from '../../../../../components/CRUD/components/Container'
import { CHeader } from '../../../../../components/CRUD/components/Header'
import { pageConfig as initialCrudConfig } from '../../pageConfig'
import { useEffect } from 'react'
import _ from 'lodash'
import CFilterDrawer from '../../../../../components/CRUD/components/FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { breadcrumb } from './model/breadcrumb'
import { handleChange } from '../../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../../components/CRUD/handler/handleFiltering'
import { fetchDataList } from './handler/fetchDataList'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'
import isUndefined from 'lodash/isUndefined'
import { mutateFilterPropsToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { DEBOUNCE_WAIT_TIME } from '../../../../../constants/envConfig'
import {
  selectPaginationTable,
  selectCrudConfig,
  selectFilterProp,
} from '../../../../../redux/selector'
import { useSelector } from '../../../../../redux/store'

const ProspectList = () => {
  const { page, sort, order, limit, search } = useSelector(
    selectPaginationTable
  )
  const crudConfig = useSelector(selectCrudConfig)
  const filterProp = useSelector(selectFilterProp)

  useEffect(() => {
    initialConfig(initialCrudConfig)
  }, [])

  useEffect(() => {
    if (!isUndefined(crudConfig)) {
      search
        ? debounce(
            () => fetchDataList(mutateFilterPropsToBody),
            DEBOUNCE_WAIT_TIME
          )()
        : fetchDataList(mutateFilterPropsToBody)
    }
  }, [limit, order, page, sort, crudConfig, search, filterProp])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <CContainer
      drawer={
        <CFilterDrawer
          handleChange={handleChange}
          handleFiltering={() => handleFiltering(mutateFilterSateToProp)}
          filterItems={filterItems}
          defaultFilter={defaultFilter}
        />
      }
      header={
        <CHeader title={crudConfig.moduleTitleFull} breadcrumbs={breadcrumb} />
      }
    />
  )
}

export default ProspectList
