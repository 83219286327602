import { useCallback, useState } from 'react'
import { Typography, Box } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'

import { breadcrumb } from './model/breadcrumb'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { BoxButton, BoxHeader, ButtonStyle } from './styled'
import { useDispatch, useSelector } from '../../../../../redux/store'
import { setFieldValue } from '../../../../../components/CRUD/slices'
import { selectCrudFormData } from '../../../../../redux/selector'
import { callAPI } from '../../../../../utils/callAPI'
import { simpleRuleNearbyArea } from '../../../../../utils/apiPath'
import { CrudFormData } from './model/form'

type Props = {
  topPosition: number
}

function Header({ topPosition }: Readonly<Props>) {
  const formData = useSelector(selectCrudFormData) as CrudFormData

  const [isSuccess, setIsSuccess] = useState(false)

  const dispatch = useDispatch()

  const handleSubmitClick = useCallback(async () => {
    const payload: { provinceUuid: string; workingAreaUuid: string | null }[] =
      []

    for (const [index, area] of formData.workingArea.entries()) {
      if (area) {
        payload.push({
          provinceUuid: formData.provinces[index].uuid,
          workingAreaUuid: area.status !== 'RESET' ? area.uuid : null,
        })
      }
    }

    await callAPI({
      url: simpleRuleNearbyArea,
      method: 'POST',
      body: payload,
      onSuccess() {
        setIsSuccess(true)
        dispatch(setFieldValue({ key: `formData.inputChanged`, value: false }))
      },
    })
  }, [dispatch, formData.provinces, formData.workingArea])

  const handleClose = () => {
    setIsSuccess(false)
  }

  return (
    <>
      <BoxHeader>
        <Box>
          <Typography variant="h4">Nearby Area</Typography>
          <Breadcrumbs menuList={breadcrumb} />
        </Box>
        <BoxButton>
          <ButtonStyle
            type="submit"
            data-testid="btn-submit"
            variant="contained"
            size="medium"
            onClick={handleSubmitClick}
            disabled={!formData.inputChanged}
            currentTop={topPosition}
          >
            บันทึกและเผยแพร่
          </ButtonStyle>
        </BoxButton>
      </BoxHeader>

      {/* SNACKBAR SUCCESS */}
      <Snackbar
        open={isSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
        message="บันทึกข้อมูลสำเร็จ"
        key="bottom-right"
      />
    </>
  )
}

export default Header
