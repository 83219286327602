import { ProcessResultApprovalStatus } from '../../../../constants/status'
import PlaceIcon from '@mui/icons-material/Place'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
export interface AgentStepList {
  id: number
  image?: string
  icon?: React.ReactNode
  status: {
    inprogress: string[]
    done: string[]
  }
  title: string
  titleMobile: string
}
export const agentStepList: AgentStepList[] = [
  {
    id: 1,
    icon: <PlaceIcon />,
    status: {
      inprogress: [],
      done: [],
    },
    title: 'กรอกใบสมัคร',
    titleMobile: 'กรอก',
  },
  {
    id: 2,
    icon: <FiberManualRecordIcon />,
    status: {
      inprogress: [
        ProcessResultApprovalStatus.ACTIVE,
        ProcessResultApprovalStatus.DL_SUBMITTED_SHORT_FORM,
        ProcessResultApprovalStatus.I_ACCEPTED_INTERVIEW,
        ProcessResultApprovalStatus.I_DECLINED_INTERVIEW,
        ProcessResultApprovalStatus.I_FAILED_INTERVIEW,
        ProcessResultApprovalStatus.I_FAILED_OUT_OF_LIMIT,
        ProcessResultApprovalStatus.I_TIME_OUT_OF_LIMIT,
        ProcessResultApprovalStatus.DL_CHANGED,
        ProcessResultApprovalStatus.DL_ASSIGNED_MA,
      ],
      done: [ProcessResultApprovalStatus.I_PASSED_INTERVIEW],
    },
    title: 'สัมภาษณ์กับผู้จัดการ',
    titleMobile: 'สัมภาษณ์',
  },
  {
    id: 3,
    icon: <FiberManualRecordIcon />,
    status: {
      inprogress: [
        ProcessResultApprovalStatus.I_PASSED_INTERVIEW,
        ProcessResultApprovalStatus.DS_SAVED_FULL_FORM,
        ProcessResultApprovalStatus.DS_SUBMITTED_FULL_FORM,
      ],
      done: [ProcessResultApprovalStatus.DS_REVIEWED],
    },
    title: 'สมัครตัวแทน <br/>กรุงไทย-แอกซ่า',
    titleMobile: 'สมัคร',
  },
  {
    id: 4,
    icon: <FiberManualRecordIcon />,
    status: {
      inprogress: [
        ProcessResultApprovalStatus.DCV_ACCEPTED_REVIEW,
        ProcessResultApprovalStatus.DCV_REQUESTED_MEMO,
        ProcessResultApprovalStatus.DS_SAVED_FULL_FORM_MEMO,
        ProcessResultApprovalStatus.DS_SUBMITTED_FULL_FORM_MEMO,
        ProcessResultApprovalStatus.DCV_PASSED_PROSPECT_HISTORY,
        ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION,
        ProcessResultApprovalStatus.DCV_UPDATED_DESCISION,
        ProcessResultApprovalStatus.DCV_APPROVED_TO_BE_AGENT,
      ],
      done: [ProcessResultApprovalStatus.DCV_APPROVED_TO_BE_AGENT],
    },
    title: 'ตรวจสอบเอกสาร <br/>และยื่นเรื่องกับคปภ.',
    titleMobile: 'ตรวจสอบ',
  },
  {
    id: 5,
    icon: <FlagOutlinedIcon />,
    status: {
      inprogress: [ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE],
      done: [ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING],
    },
    title: 'ออกรหัสตัวแทน <br/>กรุงไทย-แอกซ่า',
    titleMobile: 'ออกรหัส',
  },
]
