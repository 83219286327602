import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import type { History } from 'history'

export interface UseHistory
  extends Pick<History, 'push' | 'replace'>,
    Partial<Omit<History, 'push' | 'replace'>> {
  goBack: VoidFunction
  listen: any
}

function useHistory(): UseHistory {
  const navigate = useNavigate()
  const location: any = useLocation()
  const listeners: Array<(location: Location) => void> = []
  useEffect(() => {
    listeners.forEach((listener) => listener(location))
  }, [location])

  return {
    push: (url: string) => navigate(url, { replace: false }),
    replace: (url: string) => navigate(url, { replace: true }),
    goBack: () => navigate(-1),
    listen: (listener: (location: Location) => void) => {
      listeners.push(listener)
      return () => {
        const index = listeners.indexOf(listener)
        if (index > -1) {
          listeners.splice(index, 1)
        }
      }
    },
  }
}

export default useHistory
