import React from 'react'
import _ from 'lodash'
import { closeDialog, openDialog } from '../../../components/dialog/slices'
import { StaffAutocompleteInput } from '.'
import {
  setAddStaff,
  setChange,
  setDeleteStaff,
} from '../../../components/Permission/slices'

export const handleInputChange = (e: any) => (dispatch: any) => {
  const key = e.target.name
  const value = e.target.value
  dispatch(setChange({ key, value }))
}

export const onView = (row: { uuid: string }) => {
  window.open('/manage/staff-profile/form/' + row.uuid)
}

export const onDelete = (index: any) => (dispatch: any) => {
  dispatch(
    openDialog({
      type: 'delete',
      title: 'ยืนยันลบข้อมูล',
      message: 'คุณต้องการลบต้องการลบพนักงานคนนี้ออกจากกลุ่มนี้หรือไม่',
      isCloseDialog: false,
      handleConfirm: () => {
        dispatch(setDeleteStaff(index))
        dispatch(closeDialog())
      },
    })
  )
}

// Define types for the list items
interface StaffItem {
  uuid: string
  firstName: string
  lastName: string
  agentCode?: string
  area?: Array<{
    default: boolean
    department?: { initial: string }
    station?: { station: string }
  }>
}

export const convertData = (list: StaffItem[]) => {
  return _.compact(list).map((item) => {
    return {
      ...item,
      firstName: `${item.firstName} ${item.lastName}`,
      agentCode: _.get(item, 'id', ''),
    }
  })
}

export const handleSetSelectedProps =
  (list: string[], permissions: Array<{ uuid: string; id: string }>) =>
  (dispatch: any) => {
    const getName: string[] = []
    let strPermission = ''
    permissions.forEach((permission) => {
      list.forEach((listItem) => {
        if (listItem === permission.uuid) {
          getName.push(permission.id)
        }
      })
    })
    strPermission = getName.join(',')
    dispatch(setChange({ key: 'permissionGroup', value: strPermission }))
    dispatch(setChange({ key: 'permission', value: list }))
  }

export const onAddMemberClick =
  (staff: any, staffOptions: any) => (dispatch: any) => {
    let value = ''
    dispatch(
      openDialog({
        type: 'content',
        title: 'เพิ่มสมาชิก',
        content: (
          <StaffAutocompleteInput
            staff={staff}
            staffOptions={staffOptions}
            onChange={(e: any) => (value = e.target.id)}
          />
        ),
        isCloseDialog: false,
        handleConfirm: () => {
          if (!_.isEmpty(value)) dispatch(setAddStaff(value))
          dispatch(closeDialog())
        },
      })
    )
  }
