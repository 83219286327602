import { combineReducers } from '@reduxjs/toolkit'

import table from '../components/Table/redux'
import dialog from '../components/dialog/slices'
import crud from '../components/CRUD/slices'
import upload from '../components/Upload/slices'
import prospect from '../modules/Prospect/slices/reducer'
import managerLookup from '../components/Lookup/ManagerLookup/slices/index'
import province from '../components/Lookup/ManagerLookup/slices/provinceSlice'
import permission from '../components/Permission/slices'

const reducer = combineReducers({
  table,
  dialog,
  crud,
  upload,
  prospect,
  managerLookup,
  province,
  permission,
})

export default reducer
