import { store } from '../../../../../../redux/store'
import _ from 'lodash'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    email: _.get(filterProp, 'email', ''),
    name: _.get(filterProp, 'firstName', ''),
    lastName: _.get(filterProp, 'lastName', ''),
    status: [],
  }
}
