import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import Divider from '@mui/material/Divider'

export const BasicInfo = () => {
  return (
    <>
      <CustomInput
        required
        labelName="ชื่อ SRAM"
        fieldName="name"
        placeholder="ระบุชื่อ SRAM"
      />
      <CustomInput
        required
        labelName="อีเมล"
        fieldName="email"
        placeholder="อีเมล"
      />
      <CustomSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
    </>
  )
}
