import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'

export const handlePreview = (
  e: any,
  isStopPropagation: any,
  showPreview: any,
  setShowPreview: any
) => {
  if (isStopPropagation) {
    e.stopPropagation()
  }
  setShowPreview(!showPreview)
}

const PreviewImg = (props: any) => {
  const {
    srcImg,
    setSrc,
    isPreview,
    title,
    style,
    isStopPropagation,
    onDelete,
  } = props

  const [showPreview, setShowPreview] = useState(false)

  return (
    <Box
      sx={{ position: 'relative', justifyCentent: 'center', display: 'flex' }}
    >
      {!isPreview && (
        <CancelUploadButton
          data-testid="cancel-upload"
          onClick={() => {
            setSrc('')
            onDelete()
          }}
        >
          <CloseIcon sx={{ fontSize: '16px' }} />
        </CancelUploadButton>
      )}

      <SmallImg
        style={style}
        src={srcImg}
        data-testid="small-image"
        onClick={(e) =>
          handlePreview(e, isStopPropagation, showPreview, setShowPreview)
        }
      />
      {showPreview && (
        <Dialog
          open={true}
          onClose={(e) =>
            handlePreview(e, isStopPropagation, showPreview, setShowPreview)
          }
          maxWidth="md"
        >
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {title}
            <IconButton
              data-testid="close-preview"
              color="primary"
              onClick={(e) =>
                handlePreview(e, isStopPropagation, showPreview, setShowPreview)
              }
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <BigImg src={srcImg} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}

export const getHasInvalidFiles = (files: any) => {
  let hasInvalidFiles = true
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const type = ['jpg', 'png', 'jpeg']
    const getNameImage = _.get(file, 'name', '')
    const splitName = getNameImage.split('.')
    const checkType = type.includes(
      splitName[splitName.length - 1].toLowerCase()
    )
    if (!checkType) {
      hasInvalidFiles = false
    } else {
      hasInvalidFiles = true
    }
  }
  return hasInvalidFiles
}

const BasicUpload = (props: any) => {
  const {
    handleUploadFile,
    value,
    textError,
    isShowDescription,
    isShowTextError = true,
    isPreview,
    style = {},
    isStopPropagation = false,
    titlePreviewImg,
    onDelete,
    disabled,
  } = props
  const [src, setSrc] = useState(value)
  useEffect(() => {
    if (value && value !== '') {
      setSrc(`${window.__env__.REACT_APP_API_URL}/file/${value}`)
    }
  }, [value])

  const onUpload = (e: any) => handleUploadFile(e)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: isShowDescription === false ? 'end' : 'unset',
      }}
    >
      {src === '' && (
        <label htmlFor="icon-button-file">
          <Button sx={{ width: '100%' }} variant="upload" component="span">
            {!disabled && (
              <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={onUpload}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <UploadFileIcon
                sx={{ color: 'text.silver', cursor: 'pointer' }}
              />
              <Typography color="text.silver" sx={{ cursor: 'pointer' }}>
                อัปโหลด
              </Typography>
            </Box>
          </Button>
        </label>
      )}
      {src !== '' && (
        <>
          <PreviewImg
            title={titlePreviewImg}
            srcImg={src}
            style={style}
            setSrc={setSrc}
            isPreview={isPreview}
            isStopPropagation={isStopPropagation}
            onDelete={onDelete}
          />
        </>
      )}
      {isShowDescription !== false && (
        <Typography color="body2">ขนาดแนะนำ 900x600px</Typography>
      )}

      {textError ? (
        <Typography variant="body2" color="error">
          {textError}
        </Typography>
      ) : (
        <Box sx={{ height: isShowTextError ? '25px' : 'unset' }}>
          {textError}
        </Box>
      )}
    </Box>
  )
}

const CancelUploadButton = styled(IconButton)(({ theme }: any) => ({
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  backgroundColor: theme?.palette?.error?.main,
  color: theme?.palette?.primary?.contrast,
  position: 'absolute',
  top: '-5px',
  right: '-5px',
  '&:hover': {
    backgroundColor: theme?.palette?.error?.main,
  },
}))

export const SmallImg = styled('img')((style) => ({
  ...style,
  width: '100%',
  height: '100%',
  maxWidth: '120px',
  maxHeight: '120px',
  cursor: 'pointer',
}))

const BigImg = styled('img')(() => ({
  width: '100%',
  height: 'auto',
  maxWidth: '2100px',
  maxHeight: '1600px',
}))

const Input = styled('input')({
  display: 'none',
})

export default BasicUpload
