import debounce from 'lodash/debounce'
import { CContainer } from '../../../../../components/CRUD/components/Container'
import { CHeader } from '../../../../../components/CRUD/components/Header'
import localforage from 'localforage'
import { crudConfig as initialCrudConfig } from '../../crudConfig'
import { ListHeaderAction } from '../../../../../components/CRUD/components/Header/components/ListHeaderAction'
import { useEffect } from 'react'
import CFilterDrawer from '../../../../../components/CRUD/components/FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { breadcrumb } from './model/breadcrumb'
import { handleChange } from '../../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../../components/CRUD/handler/handleFiltering'
import { fetchDataList } from '../../../../../components/CRUD/handler/fetchDataList'
import useHistory from '../../../../../utils/useHistory'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropsToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { DEBOUNCE_WAIT_TIME } from '../../../../../constants/envConfig'
import { useSelector } from '../../../../../redux/store'
import {
  selectCrudConfig,
  selectPaginationTable,
  selectFilterProp,
} from '../../../../../redux/selector'

const ReasonRequestMemo = () => {
  const { page, sort, order, limit, search } = useSelector(
    selectPaginationTable
  )
  const crudConfig = useSelector(selectCrudConfig)
  const filterProp = useSelector(selectFilterProp)

  useEffect(() => {
    initialConfig(initialCrudConfig)
  }, [])

  const history = useHistory()

  useEffect(() => {
    if (!_.isUndefined(crudConfig)) {
      search
        ? debounce(
            () => fetchDataList(mutateFilterPropsToBody),
            DEBOUNCE_WAIT_TIME
          )()
        : fetchDataList(mutateFilterPropsToBody)
    }
  }, [limit, order, page, sort, crudConfig, search, filterProp])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <CContainer
      drawer={
        <CFilterDrawer
          handleChange={handleChange}
          handleFiltering={() => handleFiltering(mutateFilterSateToProp)}
          filterItems={filterItems}
          defaultFilter={defaultFilter}
        />
      }
      header={
        <CHeader
          title={crudConfig.moduleTitleFull}
          breadcrumbs={breadcrumb}
          action={
            <ListHeaderAction
              onClick={() => {
                localforage.removeItem('persist:root').then()
                history.push(`${crudConfig.modulePath}/form`)
              }}
            />
          }
        />
      }
    />
  )
}

export default ReasonRequestMemo
