import { store } from '../../../../../../redux/store'
import _ from 'lodash'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    agentCode: _.get(filterProp, 'agentCode', ''),
    managerName: _.get(filterProp, 'managerName', ''),
    workingArea: _.get(filterProp, 'workingArea', ''),
    province: _.get(filterProp, 'province', ''),
  }
}
