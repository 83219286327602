import { CContainer } from '../../../../components/CRUD/components/Container'
import { CHeader } from '../../../../components/CRUD/components/Header'
import localforage from 'localforage'
import { pageConfig as initialPageConfig } from './pageConfig'
import { ListHeaderAction } from './components/ListHeaderAction'
import { useEffect } from 'react'
import FilterDrawerAction from './components/FilterDrawerAction'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { breadCrumb } from './model/breadCrumb'
import { handleChange } from '../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../components/CRUD/handler/handleFiltering'
import { fetchDataList } from './handler/fetchDataList'
import useHistory from '../../../../utils/useHistory'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import {
  selectPaginationTable,
  selectCrudConfig,
  selectFilterProp,
} from '../../../../redux/selector'
import { setInitialTable } from '../../../../components/Table/redux'
import { useDispatch, useSelector } from '../../../../redux/store'

const ImportCandidateList = () => {
  const { page, sort, order, limit } = useSelector(selectPaginationTable)
  const crudConfig = useSelector(selectCrudConfig)
  const filterProp = useSelector(selectFilterProp)

  const dispatch = useDispatch()

  useEffect(() => {
    initialConfig(initialPageConfig)
    dispatch(setInitialTable({ sort: 'createdAt' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory()

  useEffect(() => {
    if (!_.isUndefined(crudConfig)) {
      fetchDataList(mutateFilterPropToBody)
    }
  }, [limit, order, page, sort, crudConfig, filterProp, dispatch])

  if (_.isUndefined(crudConfig)) {
    return null
  }

  return (
    <CContainer
      drawer={
        <FilterDrawerAction
          handleChange={handleChange}
          handleFiltering={() => handleFiltering(mutateFilterSateToProp)}
          filterItems={filterItems}
          defaultFilter={defaultFilter}
        />
      }
      header={
        <CHeader
          title={crudConfig.moduleTitleFull}
          breadcrumbs={breadCrumb}
          action={
            <ListHeaderAction
              onClick={() => {
                localforage.removeItem('persist:root')
                history.push('/candidate/import')
              }}
            />
          }
        />
      }
    />
  )
}

export default ImportCandidateList
