import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialStateBasic, type InitialStateBasic } from './model'

const basicTableSlice = createSlice({
  name: 'basicTable',
  initialState: initialStateBasic,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setInitialTable: (
      state,
      {
        payload,
      }: PayloadAction<{
        rows: { [key: string]: any }[]
        allCount: number
        headCells: { [key: string]: any }[]
        isShowPagination: boolean
      }>
    ) => {
      //   state.isCheckBox = _.get(payload, 'isCheckBox', true)
      state.rows = _.get(payload, 'rows', [])
      state.allCount = _.get(payload, 'allCount', 0)
      state.headCells = _.get(payload, 'headCells', [])
      //   state.searchKey = _.get(payload, 'searchKey', '')
      state.isShowPagination = _.get(payload, 'isShowPagination', true)
      //   state.sxTable = _.get(payload, 'sxTable', {})
      //   state.tableHeadText = _.get(payload, 'tableHeadText', null)
    },
    setDefaultSort: (
      state,
      {
        payload,
      }: PayloadAction<Pick<InitialStateBasic, 'defaultSort'>['defaultSort']>
    ) => {
      state.defaultSort = payload
    },
    setOrder: (
      state,
      { payload }: PayloadAction<Pick<InitialStateBasic, 'order' | 'sort'>>
    ) => {
      state.order = payload.order
      state.sort = payload.sort
    },
    setChangeRowsPerPage: (
      state,
      {
        payload,
      }: PayloadAction<Pick<InitialStateBasic, 'rowsPerPage' | 'limit'>>
    ) => {
      state.rowsPerPage = payload.rowsPerPage
      state.limit = payload.limit
      state.tablePage = 0
      state.page = 1
    },
    setChangePage: (
      state,
      { payload }: PayloadAction<Pick<InitialStateBasic, 'page' | 'tablePage'>>
    ) => {
      state.page = payload.page
      state.tablePage = payload.tablePage
    },
    resetTablePage: (state) => {
      state.tablePage = 0
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setInitialTable,
  setDefaultSort,
  setOrder,
  setChangeRowsPerPage,
  setChangePage,
  resetTablePage,
} = basicTableSlice.actions

export default basicTableSlice.reducer
