import { store } from '../../../../../../redux/store'
import _ from 'lodash'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    name: _.get(filterProp, 'name', ''),
    dropFromSystem: _.get(filterProp, 'dropFromSystem', []),
    status: _.get(filterProp, 'status', []),
  }
}
