import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../../constants/envConfig'
import { callAPI } from '../../../../utils/callAPI'

export const callProspectDetail = createAsyncThunk(
  'appointment/call-prospect-detail',
  async (payload: { prospectUuid: string }, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/appointment/detail`,
        method: 'post',
        body: payload,
      })
      return response
    } catch (err) {
      console.log('err ->>', err)
      return rejectWithValue(null)
    }
  }
)
