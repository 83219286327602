import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button, { type ButtonProps } from '@mui/material/Button'
import CustomDropdown, {
  type CustomDropdownProps,
} from '../../../../../components/Input/Dropdown'

export const BoxHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexBasis: '100%',
  gap: theme.spacing(4),
}))

export const BoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    width: '100%',
    '& > .MuiIconButton-root': {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}))

export const ButtonStyle = styled(Button, {
  shouldForwardProp: (props) => props !== 'currentTop',
})<ButtonProps & { currentTop: number }>(({ currentTop }) => ({
  ...(currentTop < 40 &&
    currentTop !== 0 && {
      position: 'fixed',
      right: 24,
      bottom: 48,
      zIndex: 1000,
    }),
}))

export const DropdownStyle = styled((props: CustomDropdownProps) => (
  <CustomDropdown {...props} />
))(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: theme.spacing(1), // 8px
    borderRadius: '4px',
  },

  '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    paddingTop: theme.spacing(0.5), // 4px
    paddingBottom: theme.spacing(0.5), // 4px
  },
}))
