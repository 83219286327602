import * as yup from 'yup'

export const formValidationSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .required('กรุณาระบุชื่อ SRAM')
    .max(100, 'กรุณาระบุชื่อ SRAM ไม่เกิน 100 ตัวอักษร'),
  email: yup
    .string()
    .nullable()
    .required('กรุณาระบุอีเมล')
    .email('รูปแบบ E-mail ไม่ถูกต้อง')
    .max(100, 'กรุณาระบุอีเมลไม่เกิน 100 ตัวอักษร'),
})
