import React from 'react'
import { useSelector } from '../../../redux/store'
import { selectQuickFilter } from '../../../redux/selector'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import * as Component from './styled'

const QuickFilter: React.FC = () => {
  const { onQuickFilter, quickFilter } = useSelector(selectQuickFilter)
  return (
    <Component.Container>
      <Typography sx={{ minWidth: 90 }}>Quick Filters:</Typography>
      {quickFilter.map((item) => (
        <Component.FilterBox
          key={item.title}
          clicked={item.clicked}
          onClick={() => onQuickFilter?.(item)}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            variant="subtitle2"
            sx={{ mr: item?.info ? '0px' : '12px', ml: '4px' }}
          >
            {item.title}
          </Typography>
          {item?.info && (
            <Tooltip
              title={<Typography>{item.info}</Typography>}
              arrow
              placement="bottom"
            >
              <InfoOutlinedIcon
                sx={{
                  fontSize: 'inherit',
                  margin: '0px 3px',
                }}
              />
            </Tooltip>
          )}
          <Typography variant="body2">
            {item.count !== 0 ? item.count : ''}
          </Typography>
        </Component.FilterBox>
      ))}
    </Component.Container>
  )
}
export default QuickFilter
