import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import get from 'lodash/get'
import {
  initialState,
  type OpenDialogPayload,
  type OpenErrorDialogPayload,
  type DialogState,
} from '../model'

const DialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (
      state: DialogState,
      { payload }: PayloadAction<OpenDialogPayload>
    ) => {
      state.type = get(payload, 'type', 'confirm')
      state.isOpen = true
      state.isLoading = get(payload, 'isLoading', false)
      state.isConfirmation = true
      state.title = get(payload, 'title', '')
      state.icon = get(payload, 'icon', undefined)
      state.message = get(payload, 'message', '')
      state.content = get(payload, 'content', null)
      state.maxWidth = get(payload, 'maxWidth', null)
      state.agreeText = get(payload, 'agreeText', undefined)
      state.disagreeText = get(payload, 'disagreeText', undefined)
      state.disableButton = get(payload, 'disableButton', false)
      state.handleConfirm = get(payload, 'handleConfirm', null)
      state.handleCancel = get(payload, 'handleCancel', null)
      state.handleError = get(payload, 'handleError', null)
      state.colorDisagreeText = get(payload, 'colorDisagreeText', undefined)
      state.colorAgreeText = get(payload, 'colorAgreeText', undefined)
      state.variantDisagreeText = get(payload, 'variantDisagreeText', undefined)
      state.isCloseDialog = get(payload, 'isCloseDialog', true)
      state.fullWidth = get(payload, 'fullWidth', true)
      state.sx = get(payload, 'sx', undefined)
      state.dataField = get(payload, 'dataField', '')
      state.showButton = get(payload, 'showButton', true)
    },
    openSuccessDialog: (
      state,
      {
        payload,
      }: PayloadAction<{
        title: string
        content: string | null
        message: string
        handleConfig: VoidFunction | null
        agreeText?: string
        maxWidth: string | null
        showButton: boolean
      }>
    ) => {
      state.type = 'success'
      state.isOpen = true
      state.isConfirmation = false
      state.isLoading = false
      state.disableButton = false
      state.title = get(payload, 'title', 'สำเร็จ')
      state.content = get(payload, 'content', null)
      state.message = get(payload, 'message', 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว')
      state.handleConfirm = get(payload, 'handleConfirm', null)
      state.agreeText = get(payload, 'agreeText', undefined)
      state.maxWidth = get(payload, 'maxWidth', null)
      state.showButton = get(payload, 'showButton', true)
    },
    openErrorDialog: (
      state: DialogState,
      { payload }: PayloadAction<OpenErrorDialogPayload>
    ) => {
      state.type = get(payload, 'type', 'fail')
      state.isCloseDialog = get(payload, 'isCloseDialog', true)
      state.isOpen = true
      state.isConfirmation = false
      state.isLoading = false
      state.disableButton = false
      state.icon = get(payload, 'icon', undefined)
      state.title = get(payload, 'title', payload.name ?? '')
      state.message = get(payload, 'message', payload.message)
      state.handleError = get(payload, 'handleError', null)
      state.maxWidth = get(payload, 'maxWidth', null)
      state.sx = get(payload, 'sx', undefined)
      state.content = get(payload, 'content', null)
      state.showButton = get(payload, 'showButton', true)
      state.agreeText = get(payload, 'agreeText', undefined)
      state.handleConfirm = get(payload, 'handleConfirm', null)
      state.sxActions = get(payload, 'sxActions', undefined)
    },
    closeDialog: (state) => {
      state.isOpen = false
      state.isLoading = false
    },
    loadingDialog: (state) => {
      state.isCloseDialog = false
      state.isLoading = true
    },
    stopLoadingDialog: (state) => {
      state.isLoading = false
    },
    setEnableButton: (state) => {
      state.disableButton = false
    },
    setDisableButton: (state) => {
      state.disableButton = true
    },
    setHandleError: (
      state,
      { payload }: PayloadAction<VoidFunction | null>
    ) => {
      state.handleError = payload
    },
  },
})

export const {
  openDialog,
  openSuccessDialog,
  openErrorDialog,
  closeDialog,
  loadingDialog,
  stopLoadingDialog,
  setEnableButton,
  setDisableButton,
  setHandleError,
} = DialogSlice.actions

export default DialogSlice.reducer
