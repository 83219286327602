import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import Divider from '@mui/material/Divider'

export const BasicInfo = () => {
  return (
    <>
      <CustomInput
        required
        labelName="ประเภทของเหตุผลการปฎิเสธนัดสัมภาษณ์"
        fieldName="name"
        placeholder="ระบุประเภทของเหตุผลการปฎิเสธนัดสัมภาษณ์"
      />
      <CustomSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
    </>
  )
}
