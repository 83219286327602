import Container from '@mui/material/Container'
import { Breakpoint, SxProps } from '@mui/material/styles'

export const defaultMaxWidth: Breakpoint = 'lg'
const CustomContainer = (props: {
  maxWidth?: Breakpoint | false //false is fullwidth
  sx?: SxProps
  children: React.ReactNode
}) => {
  return (
    <Container
      sx={{ px: { xs: '25px' }, ...props?.sx }}
      maxWidth={props.maxWidth || defaultMaxWidth}
    >
      {props.children}
    </Container>
  )
}
export default CustomContainer
