import { memo } from 'react'
import { type DropzoneOptions, useDropzone } from 'react-dropzone'
// @mui
import Stack from '@mui/material/Stack'
import { type SxProps, type Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

interface Props extends DropzoneOptions {
  sx?: SxProps<Theme>
  imgPath: string
  description: React.ReactNode
  textError?: string
}

function UploadSingleFile({
  sx,
  imgPath,
  description,
  textError,
  ...other
}: Readonly<Props>) {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    ...other,
  })

  return (
    <>
      <Stack
        {...getRootProps()}
        width={{ xs: 1, md: 'unset' }}
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 2, md: 6 }}
        sx={sx}
      >
        <input {...getInputProps()} />

        <img src={imgPath} alt="Upload file" />

        {description}
      </Stack>

      {textError && (
        <Typography variant="body1" color="error">
          {textError}
        </Typography>
      )}
    </>
  )
}

export default memo(UploadSingleFile)
