import { CircularProgress, SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'
import CustomContainer, { defaultMaxWidth } from '../CustomContainer'
const CustomSection = (props: {
  isLoading?: boolean
  fullwidth?: boolean
  sx?: SxProps
  children: React.ReactNode
}) => {
  return (
    <Box
      sx={{
        py: { xs: '25px', sm: '0px' },
        bgcolor: 'white.light',
        float: 'left',
        width: '100%',
        display: 'inline-block',
        position: 'relative',
        ...props.sx,
      }}
    >
      <CustomContainer maxWidth={props.fullwidth ? false : defaultMaxWidth}>
        {props.isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Box sx={{ visibility: props.isLoading ? 'hidden' : 'visible' }}>
          {props.children}
        </Box>
      </CustomContainer>
    </Box>
  )
}
export default CustomSection
