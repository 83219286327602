import { useCallback } from 'react'
import { useAuth } from 'react-oidc-context'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import styled from '@emotion/styled'
import { handleLogout } from '../../components/AppBar/Appbar'

export default function Index() {
  const auth = useAuth()
  const login = useCallback(() => handleLogout(auth, true), [auth])
  console.log(window.location.origin, '< check url ')
  console.log(window.__env__.REACT_APP_APP_URL, '< check REACT_APP_APP_URL')
  return (
    <div>
      <Main />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', width: '300px', margin: 'auto' }}
      >
        <img src="/logo/axa.png" alt="DMS" height="50px" />
        <h2>
          DMS Backend {window.__env__.ENV === 'PROD' ? '' : window.__env__.ENV}
        </h2>
        <Button fullWidth variant="contained" onClick={login}>
          เข้าสู่ระบบ
        </Button>
      </Grid>
    </div>
  )
}

Index.templateLayout = 'login'

const Main = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  opacity: 0.1;
  background-color: #e5e5f7;
  background-image: repeating-linear-gradient(
      55deg,
      #e0f7fa,
      #e0f7fa 5px,
      transparent 1px,
      transparent 20px
    ),
    linear-gradient(to top right, #009fd6, #e0f7fa, #b2ebf2, #a7ffeb);
`
