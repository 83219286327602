import { useCallback, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import { useLocalStorage } from '@rehooks/local-storage'

import type { UserFromStorage } from '../types/user'

export const useAuthGuard = () => {
  const navigate = useNavigate()

  const [userFromStorage] = useLocalStorage<UserFromStorage>('authUser')

  const { pathname } = useLocation()

  const { isAuthenticated } = useAuth()

  const handleRedirect = useCallback(() => {
    if (!isAuthenticated && !userFromStorage?.access_token) {
      navigate('/')

      return
    }

    if (pathname === '/') {
      navigate('/home')
    }
  }, [isAuthenticated, navigate, pathname, userFromStorage?.access_token])

  useEffect(() => {
    handleRedirect()
  }, [handleRedirect])
}
