import { store } from '../../../redux/store'
import { setFieldValue } from '../../CRUD/slices'
import { callAPI } from '../../../utils/callAPI'
import { handleFetchError } from './handlerFetchError'
import { CRUDListFilterPath } from '../../../utils/apiPath'
import _ from 'lodash'

interface ModuleData {
  moduleId: string
  filterPath: string
  key: string
}

export interface MasterData {
  [key: string]: {
    isLoading: boolean
    options: { label: string; value: string }[]
  }
}

export const fetchDetail = async (
  uuid: string,
  getBodyMutation: (response: any) => any
) => {
  startDialogLoading()

  try {
    await fetchData(getBodyMutation, uuid)
  } catch (error) {
    console.log(error)
    handleFetchError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))
}

const stopDialogLoading = () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}

const fetchData = async (
  getBodyMutation: (response: any) => any,
  uuid = ''
) => {
  const { crudConfig } = store.getState().crud
  const url = `/crud/${uuid}`
  const headers = { 'x-type': crudConfig?.moduleId || '' }

  const response = await callAPI({
    method: 'get',
    url,
    headers,
  })
  store.dispatch(
    setFieldValue({ key: 'formData', value: getBodyMutation(response) })
  )
}

export const fetchMasterDataDropdown = async () => {
  const { crudConfig, masterData } = store.getState().crud
  let masterDataValue: MasterData = masterData

  await Promise.all(
    _.map(
      _.get(crudConfig, 'masterDataModuleId', []),
      async (moduleData: ModuleData) => {
        const url = _.get(moduleData, 'filterPath', CRUDListFilterPath)
        const headers = { 'x-type': moduleData?.moduleId || '' }

        const body = {
          limit: '-1',
          page: 1,
          order: 'DESC',
          sort: 'updatedAt',
          quickSearch: '',
          name: '',
          status: ['ACTIVE'],
          createdBy: '',
          updatedBy: '',
          stockCategoryName: '',
        }

        const response = await callAPI({
          method: 'post',
          url,
          headers,
          body,
        })

        const result = _.map(_.get(response, 'result', []), (item) => ({
          label: getLabel(item, moduleData.moduleId) ?? '',
          value: item.uuid,
        }))

        masterDataValue = {
          ...masterDataValue,
          [moduleData.key]: {
            isLoading: false,
            options: result,
          },
        }
      }
    )
  )

  store.dispatch(setFieldValue({ key: 'masterData', value: masterDataValue }))
}

type MasterDataItem = {
  id: number
  uuid: string
  name: string
  status: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  title: string
  description: string
  descriptionName?: string // * COST CENTER
}

function getLabel(item: MasterDataItem, moduleId: string) {
  if (moduleId === 'COST_CENTER') {
    return item.descriptionName
  }

  return moduleId !== 'EVENT_SUB_SOURCE' ? item.title : item.name
}
