import _ from 'lodash'
import { handleKeyValue } from './handleKeyValue'

export type Filter = {
  [key: string]: boolean | string | object | any[]
}

export const handleFilterTotal = (filter: Filter): number => {
  const keyChecked: string[] = []
  const keyValue: string[] = []

  let filterTotal = 0

  for (const key in filter) {
    if (key.includes('displayDate')) continue
    if (key.includes('displayExpiryDate')) continue
    if (key.includes('displayEffectiveDate')) continue

    if (typeof filter[key] === 'boolean' && filter[key] === true) {
      keyChecked.push(key.replace('Checked', ''))
    }
    if (!key.includes('Checked') || typeof filter[key] !== 'boolean') {
      keyValue.push(key)
    }
  }

  keyChecked.forEach((key) => {
    const valueKey = keyValue.find((val) =>
      _.toLower(val).includes(_.toLower(key))
    )

    let value
    if (valueKey !== undefined) {
      value = filter[valueKey]
      // Continue with your logic using value
    }

    const valueType = typeof value

    if (valueType === 'object' && !Array.isArray(value)) {
      filterTotal = handleKeyValue(value, filterTotal, 0)
    }

    if (
      (valueType === 'boolean' && value) ||
      (_.isArray(value) && value.length > 0)
    ) {
      filterTotal++
    }

    if (valueType === 'string' && value) {
      filterTotal++
    }
  })

  return filterTotal
}
