export const setLineClamp = (num = 1) => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: `${num}`,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  }
}
