import {
  type AxiosRequestConfig,
  type ResponseType,
  type Method,
  type RawAxiosRequestHeaders,
  type AxiosHeaders,
} from 'axios'

import axios from './axios'

type MethodsHeaders = Partial<
  {
    [Key in Method as Lowercase<Key>]: AxiosHeaders
  } & { common: AxiosHeaders }
>

export interface CallAPIProps
  extends Omit<
    AxiosRequestConfig,
    'url' | 'method' | 'body' | 'headers' | 'responseType'
  > {
  url: string
  method: Method
  body?: any
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
  onSuccess?: (data: any) => void
  onError?: (error: any) => void
  onFinally?: () => void
  responseType?: ResponseType
}

export const callAPI = async <T = any>({
  url,
  method,
  body = {},
  headers = {},
  onSuccess,
  onError = (error) => {
    console.log(error)
  },
  onFinally,
  responseType = 'json',
  ...other
}: CallAPIProps) => {
  const config: AxiosRequestConfig = {
    method,
    url,
    headers,
    responseType,
    data: body,
    ...other,
  }

  try {
    const response = await axios.request<T>(config)

    onSuccess && onSuccess(response.data)

    return response.data
  } catch (error) {
    onError && onError(error)

    throw error
  } finally {
    onFinally && onFinally()
  }
}
