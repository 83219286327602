import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import routes from './routeList'
import Layout from '../Layout'

let previousPath = ''
let currentPath = ''

const withLayout = (WL: any) => {
  const Comp = (props: any) => {
    return (
      <Layout layout={WL.templateLayout}>
        <WL {...props} />
      </Layout>
    )
  }
  Comp.displayName = 'withLayout'
  return Comp
}

const AppRoutes: React.FC = () => {
  const location = useLocation()
  useEffect(() => {
    if (!previousPath && !currentPath) {
      previousPath = location.pathname
      currentPath = location.pathname
    } else {
      previousPath = currentPath
      currentPath = location.pathname
    }
  }, [location.pathname])

  return (
    <Routes>
      {routes.map((item) => {
        return (
          <Route
            key={item.path}
            path={item.path}
            Component={withLayout(item.component)}
          />
        )
      })}
    </Routes>
  )
}

export default AppRoutes
