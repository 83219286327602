import styled from '@mui/material/styles/styled'
import Stack, { type StackProps } from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import StepLabel from '@mui/material/StepLabel'

export const WrapperInProgressStyle = styled(Stack, {
  shouldForwardProp: (props) => props !== 'isError',
})<StackProps & { isError: boolean }>(({ theme, isError }) => ({
  width: '100%',
  height: 224,
  borderRadius: '8px',
  backgroundColor: !isError
    ? `${theme.palette.info.main}14`
    : `${theme.palette.text.error}14`,
  border: `1px solid ${theme.palette.blue.blueLine}99`,
}))

export const StepperStyle = styled(Stepper)({
  '&.MuiStepper-horizontal': {
    '& .MuiStep-horizontal': {
      '&:first-of-type': {
        paddingLeft: 0,
      },
      '&:last-of-type': {
        paddingRight: 0,
      },
    },
  },
})

export const StepLabelStyle = styled(StepLabel)({
  '& .MuiStepLabel-iconContainer .MuiSvgIcon-root': {
    width: 40,
    height: 40,
    fontSize: 20,
  },

  '& .MuiStepLabel-labelContainer .MuiStepLabel-label': {
    fontSize: 20,
  },
})
