import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'SRAM_PROFILE',
  moduleSlug: 'sram-profile',
  moduleTitleFull: 'ข้อมูลโปรไฟล์ SRAM (SRAM Profile)',
  moduleTitleSecond: 'ข้อมูลโปรไฟล์ SRAM',
  modulesTitleShort: 'ข้อมูลโปรไฟล์ SRAM',
  modulePath: '/sram-profile',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
