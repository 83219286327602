import {
  setAddFormErrors,
  setFormIsLoading,
} from '../../../../../../../components/CRUD/slices'
import {
  closeDialog,
  openDialog,
} from '../../../../../../../components/dialog/slices'
import { ApiStatus } from '../../../../../../../constants/status'
import { AppDispatch } from '../../../../../../../redux/store'
import { callAssignManager } from '../service/thunk'

import _ from 'lodash'
import { Manager } from '../../../../type/types'

export const handleSubmitFormAssign =
  (params: Manager) => async (dispatch: AppDispatch) => {
    dispatch(setFormIsLoading(true))
    if (_.get(params, 'uuid', '') === '') {
      dispatch(
        setAddFormErrors({
          path: 'uuid',
          message: 'กรุณาเลือก Manager',
        })
      )
      dispatch(setFormIsLoading(false))
      return
    }

    const { payload } = await dispatch(callAssignManager(params))
    if (payload && payload.status === ApiStatus.SUCCESS) {
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          agreeText: 'ตกลง',
          isCloseDialog: false,
          handleConfirm: () => {
            dispatch(closeDialog())
            window.location.reload()
          },
        })
      )
    } else {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ไม่สำเร็จ',
          message: 'ไม่สามารถบันทึกข้อมูลได้',
          agreeText: 'ตกลง',
          isCloseDialog: false,
          handleConfirm: () => {
            dispatch(closeDialog())
            window.location.reload()
          },
        })
      )
    }
    dispatch(setFormIsLoading(false))
  }
