import { store } from '../../../../../redux/store'

export const mutateFilterSateToProp = () => {
  const { filterState }: any = store.getState().table

  return {
    agentCode: filterState.agentCodeChecked ? filterState.agentCode : '',
    firstName: filterState.firstNameChecked ? filterState.firstName : '',
    lastName: filterState.lastNameChecked ? filterState.lastName : '',
    email: filterState.emailChecked ? filterState.email : '',
    importCount: filterState.importCountChecked ? filterState.importCount : '',
  }
}
