import React, { ChangeEvent, FocusEvent, MouseEvent } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import InputAdornment from '@mui/material/InputAdornment'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import useTheme from '@mui/system/useTheme'
import type { SxProps, Theme, TypographyVariant } from '@mui/material/styles'

interface DialogRight {
  text: string
  color: string
}

interface IndexProps {
  boxSx?: SxProps<Theme>
  sx?: SxProps<Theme>
  type?: string
  placeholder?: string
  labelText?: string
  labelVariant?: TypographyVariant
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  id?: string
  name?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onClick?: (e: MouseEvent<HTMLInputElement>) => void
  textError?: string
  textInfo?: string
  value?: any
  defaultValue?: string
  multiline?: boolean
  rows?: number
  startAdornment?: React.ReactNode
  endAdornmentText?: React.ReactNode
  inputProps?: InputBaseProps['inputProps']
  isShowTextError?: boolean
  dialogRight?: DialogRight
  inputAdd?: any
  maxRows?: number
  maxlength?: number | string
  showMaxLength?: number
  sxTextError?: object
}

const Index: React.FC<IndexProps> = ({
  boxSx,
  sx,
  type,
  placeholder,
  labelText,
  labelVariant = 'body2',
  required,
  disabled,
  readOnly,
  id,
  name,
  onChange,
  onBlur,
  onClick,
  textError,
  textInfo,
  value,
  defaultValue,
  multiline = false,
  rows,
  startAdornment,
  endAdornmentText,
  inputProps,
  isShowTextError = true,
  dialogRight,
  inputAdd,
  maxRows,
  maxlength = 'unset',
  showMaxLength = 0,
  sxTextError,
}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...boxSx,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant={labelVariant}
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
        {dialogRight && (
          <Box sx={{ ml: 'auto', display: 'flex' }}>
            <InfoOutlinedIcon
              sx={{
                color: `${theme?.palette?.warning?.main}`,
                cursor: 'pointer',
              }}
            />
            <Typography
              sx={{ ml: 1 }}
              variant="body2"
              color={dialogRight.color}
            >
              {dialogRight.text}
            </Typography>
          </Box>
        )}
      </Box>
      <InputBase
        id={id}
        name={name}
        disabled={disabled}
        sx={{
          ...sx,
          borderRadius: 0,
          borderColor:
            textError && textError !== ''
              ? `${theme?.palette?.error?.main} !important`
              : undefined,
        }}
        type={type}
        placeholder={placeholder}
        onClick={onClick}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        key={defaultValue}
        defaultValue={defaultValue}
        multiline={multiline}
        inputProps={{
          ...inputProps,
          'data-testid': id,
          maxLength: maxlength,
        }}
        rows={rows}
        maxRows={maxRows}
        readOnly={readOnly}
        startAdornment={startAdornment}
        endAdornment={
          <InputAdornment position="end" sx={{ mr: 1 }}>
            {endAdornmentText}
          </InputAdornment>
        }
      />
      {isShowTextError && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mb: textError && textError !== '' ? 'unset' : 3,
          }}
        >
          {textInfo && !(textError && textError !== '') && (
            <Typography height={25} variant="body2" color="text.secondary">
              {textInfo}
            </Typography>
          )}
          {textError && textError !== '' && (
            <Typography
              sx={sxTextError ? sxTextError : { height: '25px' }}
              variant="body2"
              color="error"
            >
              {textError}
            </Typography>
          )}
          {showMaxLength > 0 && (
            <Typography
              sx={{
                height: '25px',
                textAlign: 'end',
                color: value?.length > showMaxLength ? 'error.main' : 'unset',
              }}
              variant="body2"
            >
              {`${value?.length}/${showMaxLength}`}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

export default Index
