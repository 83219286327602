import {
  closeDialog,
  loadingDialog,
  openDialog,
  openErrorDialog,
  stopLoadingDialog,
} from '../../../components/dialog/slices'
import _, { get } from 'lodash'
import { setFieldError } from '../../CRUD/slices'
import scrollToError from './handleScrollToError'
import { store } from '../../../redux/store'
import { callAPI } from '../../../utils/callAPI'
import { displayClientError, displayServerError } from './handlerFetchError'
import type { NavigateFunction } from 'react-router-dom'
import { SUB_LINK_FORM } from '../../../constants/master-data'

export const handleProcessSubmitForm = async (
  uuid: string,
  postBodyMutation: any,
  navigate?: NavigateFunction
) => {
  startDialogLoading()

  try {
    const response = await submitFormData(uuid, postBodyMutation)
    handleSuccessfulSubmission(response, navigate)
  } catch (error) {
    handleFormSubmissionError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(loadingDialog())
}

const stopDialogLoading = () => {
  store.dispatch(stopLoadingDialog())
}

const submitFormData = async (uuid: string, postBodyMutation: any) => {
  const { formData, crudConfig } = store.getState().crud
  const url = `/crud`

  return await callAPI({
    method: _.isEmpty(uuid) ? 'post' : 'put',
    url,
    body: postBodyMutation(formData),
    headers: { 'x-type': crudConfig?.moduleId ?? '' },
  })
}

const handleSuccessfulSubmission = (
  response: string,
  navigate?: NavigateFunction
) => {
  const uuid = get(response, 'uuid', '')

  store.dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      agreeText: 'ตกลง',
      isCloseDialog: false,
      handleConfirm: () => navigateToViewPath(uuid, navigate),
    })
  )
}

export const handleFormSubmissionError = (error: any) => {
  if (error.response) {
    if (error.response.status === 400) {
      handleBadRequestError(error.response)
    } else {
      displayServerError()
    }
  } else {
    displayClientError()
  }
}

const handleBadRequestError = (response: any) => {
  const errorData = get(response, 'data.message[0]', '')
  store.dispatch(
    openErrorDialog({
      message: errorData.constraints.value,
      title: 'ผิดพลาด',
      isCloseDialog: false,
      handleConfirm: () => {
        store.dispatch(
          setFieldError([
            {
              path: errorData.property,
              message: errorData.constraints.value,
            },
          ])
        )

        store.dispatch(closeDialog())
        scrollToError(errorData.property)
      },
    })
  )
}

const navigateToViewPath = (uuid: string, navigate?: NavigateFunction) => {
  const { crudConfig } = store.getState().crud

  const editPath = `${crudConfig?.modulePath}${SUB_LINK_FORM}/${uuid}`

  store.dispatch(closeDialog())
  navigate?.(editPath)
  navigate?.(0)
}
