import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomerSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import Divider from '@mui/material/Divider'

export default function BasicInfo() {
  return (
    <>
      <CustomInput
        required
        labelName="หัวข้อ"
        fieldName="title"
        placeholder="ระบุชื่อหัวข้อ"
      />
      <CustomInput
        required
        labelName="รายละเอียด"
        fieldName="description"
        placeholder="ระบุชื่อรายละเอียด"
      />
      <CustomerSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
    </>
  )
}
