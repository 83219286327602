import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'RAM_PROFILE',
  moduleSlug: 'ram-profile',
  moduleTitleFull: 'ข้อมูลโปรไฟล์ RAM (RAM Profile)',
  moduleTitleSecond: 'ข้อมูลโปรไฟล์ RAM',
  modulesTitleShort: 'ข้อมูลโปรไฟล์ RAM',
  modulePath: '/ram-profile',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
