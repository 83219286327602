import { memo, useState, type Dispatch, type SetStateAction } from 'react'
import Appbar from '../AppBar/Appbar'
import {
  DrawerHeader,
  Drawer,
  DrawerMobile,
  StyledBackG,
} from '../Drawer/Drawer'
import Box from '@mui/material/Box'
import { useLocation, type Location } from 'react-router-dom'
import useHistory from '../../utils/useHistory'
import ListSubheader from '@mui/material/ListSubheader'
import useTheme from '@mui/material/styles/useTheme'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import { useLocalStorage } from '@rehooks/local-storage'
import { type LocalStorageSetStateValue } from '@rehooks/local-storage/lib/use-localstorage'
import { themeColor } from '../../styles/themes/themeData'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import { leftMenuList } from './LeftMenuData'
import type { IMenuItem } from './type'

export const handleMenuClick = (
  i: number,
  menu: ReturnType<typeof leftMenuList>,
  setMenu: Dispatch<SetStateAction<ReturnType<typeof leftMenuList>>>,
  history: any,
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  leftMenuLS: boolean[] | null,
  setLeftMenuLS: (newValue: LocalStorageSetStateValue<boolean[]> | null) => void
) => {
  const menuItem = menu[i]
  if (_.get(menuItem, 'list', []).length === 0) {
    history.push(menuItem.path)
  }

  handleChange({
    open,
    setOpen,
  })

  const newItems = {
    ...menuItem,
    active: menu[i].list.length === 0 ? true : !menuItem?.active,
  }
  menu[i] = newItems

  if (leftMenuLS?.[i]) {
    leftMenuLS[i] = !menuItem?.active
  }

  setLeftMenuLS(leftMenuLS)
  setOpen(true)
  setMenu([...menu])
}

export const redirectTo = (
  menu: any,
  setMenu: any,
  history: any,
  path: any,
  setOpen: any,
  leftMenuLS: any,
  setLeftMenuLS: any
) => {
  const newMenu = menu.map((value: any) => {
    if (value?.list?.length === 0) {
      value.active = false
    }
    value.active = false
    return value
  })
  setOpen(false)
  history.push(path)
  setMenu(newMenu)
  leftMenuLS[0] = false
  setLeftMenuLS(leftMenuLS)
}

export const handleChange = ({
  setOpen,
  open,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  setOpen(!open)
}

type LeftMenuProps = {
  menu: ReturnType<typeof leftMenuList>
  setMenu: Dispatch<SetStateAction<ReturnType<typeof leftMenuList>>>
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  children?: React.ReactNode
}

const LeftMenu = ({
  menu,
  setMenu,
  open,
  setOpen,
  children,
}: Readonly<LeftMenuProps>) => {
  const theme = useTheme()
  const history = useHistory()
  const [isListMenu, setIsListMenu] = useState(false)

  return (
    <Box sx={{ justifyContent: 'flex-start' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {open && <StyledBackG open={open} onClick={() => setOpen(false)} />}

        <Drawer variant="permanent" open={open}>
          <ListSX
            theme={theme}
            menu={menu}
            history={history}
            isListMenu={isListMenu}
            setMenu={setMenu}
            setIsListMenu={setIsListMenu}
            open={open}
            setOpen={setOpen}
          />
        </Drawer>
        <Appbar open={open} setOpen={setOpen} />
        <DrawerMobile variant="permanent" open={open}>
          <ListSX
            theme={theme}
            menu={menu}
            history={history}
            isListMenu={isListMenu}
            setMenu={setMenu}
            setIsListMenu={setIsListMenu}
            open={open}
            setOpen={setOpen}
          />
        </DrawerMobile>
        {children}
      </Box>
    </Box>
  )
}
export default LeftMenu

export const findClassName = (item: any) => {
  let result = 'body1'
  if (
    (_.get(item, 'list', []).length && _.get(item, 'active', false)) ||
    (_.get(item, 'list', []).length === 0 &&
      _.get(item, 'name', '') === 'จัดการหลักสูตร' &&
      _.get(item, 'active', false))
  )
    result = 'body1b'
  return result
}

const MenuView = memo(
  // i = number
  ({ item, i, setMenu, menu, history, open, setOpen }: any) => {
    const [leftMenuLS, setLeftMenuLS] = useLocalStorage<boolean[]>('leftMenu2')

    const theme = useTheme()

    const location = useLocation()

    const list = _.get(item, 'list', [])
    const isList = list.length > 0
    const active = _.get(item, 'active', false)

    const isActive = isList
      ? list.some((itemList: any) => location.pathname.includes(itemList.path))
      : location.pathname.includes(item.path)

    return (
      <div key={item.name}>
        <ListItemButton
          sx={{
            px: 1.5,
            background:
              (!isList || !open) && isActive
                ? theme.palette.primary.outlinedHoverBackground
                : 'unset',
          }}
          onClick={() =>
            handleMenuClick(
              i,
              menu,
              setMenu,
              history,
              open,
              setOpen,
              leftMenuLS,
              setLeftMenuLS
            )
          }
        >
          <ListItemIcon sx={{ minWidth: 44 }}>
            {item?.icon(isActive)}
          </ListItemIcon>
          <ListItemText
            primary={
              isActive ? (
                <Typography sx={{ color: 'primary.main' }}>
                  {_.get(item, 'name', '')}
                </Typography>
              ) : (
                <Typography variant="body1">
                  {_.get(item, 'name', '')}
                </Typography>
              )
            }
          />
          {isList &&
            (active ? (
              <ExpandLess sx={{ color: isActive ? 'primary.main' : 'unset' }} />
            ) : (
              <ExpandMore />
            ))}
        </ListItemButton>
        {open && (
          <Collapse in={active} timeout="auto" unmountOnExit>
            {list?.map((menuItem: any) => {
              return (
                <MenuItem
                  key={menuItem.name}
                  history={history}
                  location={location}
                  menuItem={menuItem}
                  menu={menu}
                  setMenu={setMenu}
                  setOpen={setOpen}
                  leftMenuLS={leftMenuLS}
                  setLeftMenuLS={setLeftMenuLS}
                />
              )
            })}
          </Collapse>
        )}
      </div>
    )
  }
)

MenuView.displayName = 'MenuView'

export { MenuView }

export const handleActivePath = (location: any, menuItem: any) => {
  const pathArr = _.get(location, 'pathname', '').split(menuItem.path)
  const path = _.get(pathArr, '[1]', null)
  const firstLetter = _.get(path, '[0]', null)

  if (menuItem.path === '/manage-class') {
    return (
      path === '' ||
      (firstLetter === '/' &&
        !path.includes('/check-exam') &&
        !path.includes('/oic'))
    )
  }
  if (menuItem.path === '/e-testing') {
    return path === '' || (firstLetter === '/' && !path.includes('/check-exam'))
  }
  return path === '' || firstLetter === '/'
}

type MenuItemProps = {
  menu: ReturnType<typeof leftMenuList>
  setMenu: Dispatch<SetStateAction<ReturnType<typeof leftMenuList>>>
  history: any
  location: Location
  menuItem: IMenuItem
  setOpen: Dispatch<SetStateAction<boolean>>
  leftMenuLS: boolean[] | null
  setLeftMenuLS: (newValue: LocalStorageSetStateValue<boolean[]> | null) => void
}

const MenuItem = memo(
  ({
    menu,
    setMenu,
    history,
    location,
    menuItem,
    setOpen,
    leftMenuLS,
    setLeftMenuLS,
  }: Readonly<MenuItemProps>) => {
    const isPathActive = handleActivePath(location, menuItem)

    const theme = useTheme()

    return (
      <div key={menuItem.name}>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{
              pl: 7,
              background: isPathActive
                ? theme.palette.primary.outlinedHoverBackground
                : undefined,
            }}
            onClick={() =>
              redirectTo(
                menu,
                setMenu,
                history,
                menuItem.path,
                setOpen,
                leftMenuLS,
                setLeftMenuLS
              )
            }
          >
            <ListItemText
              sx={{ color: isPathActive ? 'primary.main' : 'text.primary' }}
              primary={
                <Typography variant={isPathActive ? undefined : 'body1'}>
                  {_.get(menuItem, 'name', '')}
                </Typography>
              }
            />
          </ListItemButton>
        </List>
      </div>
    )
  }
)

export { MenuItem }

export const Badge = ({ number }: any) => {
  return (
    <div
      style={{
        width: '24px',
        lineHeight: '24px',
        borderRadius: '50%',
        textAlign: 'center',
        backgroundColor: themeColor?.secondary?.main,
        color: themeColor?.secondary?.contrast,
      }}
    >
      {number}
    </div>
  )
}

const ListSX = memo(({ theme, menu, setMenu, history, open, setOpen }: any) => {
  return (
    <List
      sx={{
        maxWidth: 300,
        pb: 0,
        boxShadow: themeColor?.shadow?.main,
        height: '100vh',
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          sx={{
            boxShadow: themeColor?.shadow?.main,
            pr: 1,
            border: `1px solid ${themeColor.shadow.main} `,
            maxHeight: '71px',
          }}
        >
          <DrawerHeader>
            <Box
              sx={{
                width: 300,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mr: 1.5,
                  [theme?.breakpoints.up('sm')]: {
                    display: 'none',
                  },
                }}
              >
                <CloseIcon
                  onClick={() => setOpen(false)}
                  width="24"
                  height="24"
                  color="primary"
                  sx={{ mt: 3, mr: 2 }}
                />
                <Divider
                  sx={{
                    maxHeight: '71px',
                    backgroundColor: 'primary.outlinedHoverBackground',
                  }}
                  orientation="vertical"
                />
              </Box>
              <Box
                sx={{
                  mt: 1.5,
                }}
              >
                <img src={'/logo/axa.png'} alt="DMS" height="48px" />
              </Box>
              <IconButton
                onClick={() =>
                  handleChange({
                    open,
                    setOpen,
                  })
                }
                sx={{
                  ml: 16,
                  mt: 2,
                  height: 36,
                  [theme?.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                }}
              >
                {open ? (
                  <svg height="17" viewBox="0 0 19 17">
                    <path
                      d="M19 14.9998V16.9998H1V14.9998H19ZM4.596 0.903809L6.01 2.31781L2.828 5.49981L6.01 8.68181L4.596 10.0958L0 5.49981L4.596 0.903809ZM19 7.99981V9.99981H10V7.99981H19ZM19 0.999809V2.99981H10V0.999809H19Z"
                      fill="#00008F"
                    />
                  </svg>
                ) : (
                  <svg height="17" viewBox="0 0 19 17">
                    <path
                      d="M18 15.0003V17.0003H0V15.0003H18ZM14.404 0.904297L19 5.5003L14.404 10.0963L12.99 8.6823L16.172 5.5003L12.99 2.3183L14.404 0.904297ZM9 8.0003V10.0003H0V8.0003H9ZM9 1.0003V3.0003H0V1.0003H9Z"
                      fill="#00008F"
                    />
                  </svg>
                )}
              </IconButton>
            </Box>
          </DrawerHeader>
        </ListSubheader>
      }
    >
      {menu?.length &&
        menu.map((item: any, i: number) => (
          <MenuView
            key={item.name}
            item={item}
            i={i}
            setMenu={setMenu}
            menu={menu}
            history={history}
            open={open}
            setOpen={setOpen}
          />
        ))}
    </List>
  )
})

ListSX.displayName = 'ListSX'

MenuItem.displayName = 'MenuItem'

export { ListSX }
