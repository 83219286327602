export const headerCells = [
  {
    id: 'status',
    label: 'Status',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '90px',
  },
  {
    id: 'text',
    label: 'เหตุผล',
  },
  {
    id: 'sectionID',
    label: 'Section ID',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    editPath: '/reason-request-memo/form',
    hideDelete: false,
  },
]
