import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'REASON_REQUEST_MEMO',
  moduleSlug: 'reason-request-memo',
  moduleTitleFull: 'รายการเหตุผลสำหรับการร้องขอบันทึกโดยการเลือก DC',
  moduleTitleSecond: 'รายการเหตุผลร้องขอบันทึกโดย DC',
  modulesTitleShort: 'เหตุผลร้องขอบันทึก',
  modulePath: '/reason-request-memo',
  filterPath: `${CRUDListFilterPath}`,
  headerCells,
}
