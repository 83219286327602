import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomerSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import Divider from '@mui/material/Divider'

export default function BasicInfo() {
  return (
    <>
      <CustomInput
        required
        labelName="ช่องทาง (อังกฤษ)"
        fieldName="channelEN"
        placeholder="ระบุช่องทางภาษาอังกฤษ"
      />
      <CustomInput
        required
        labelName="ช่องทาง (ไทย)"
        fieldName="channelTH"
        placeholder="ระบุช่องทางภาษาไทย"
      />
      <CustomerSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
    </>
  )
}
