import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Content from './content'
import { useSelector, shallowEqual } from 'react-redux'
import { SliceModel } from '../../../redux/models'
import { ManagerGrid } from './slices/model'
import { useEffect } from 'react'
import { getProvinceList } from './service'
import { useDispatch } from '../../../redux/store'

interface Props {
  onConfirmClick: (row: ManagerGrid | undefined) => void
  uuid: string | null
}

const ManagerLookup = (props: Props) => {
  const { onConfirmClick } = props
  const dispatch = useDispatch()
  const { isModalOpen } = useSelector(
    (state: SliceModel) => ({
      isModalOpen: state.managerLookup.isModalOpen,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (isModalOpen) {
      dispatch(getProvinceList())
    }
  }, [isModalOpen, dispatch])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Content onConfirmClick={onConfirmClick} />
      </Modal>
    </>
  )
}
export default ManagerLookup
