import Stack, { type StackProps } from '@mui/material/Stack'
import styled from '@mui/material/styles/styled'

interface StyledTruncateBoxProps extends StackProps {
  maxWidth: number | string
  isTruncated?: boolean
}

export const StyledTruncateBox = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'maxWidth' && prop !== 'isTruncated',
})<StyledTruncateBoxProps>(({ maxWidth, isTruncated }) => ({
  flexDirection: 'row',
  maxWidth: maxWidth,
  wordBreak: 'break-all',
  verticalAlign: 'middle',
  cursor: isTruncated ? 'pointer' : 'default',
}))
