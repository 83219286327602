import * as yup from 'yup'

export const formValidationSchema = yup.object({
  email: yup
    .string()
    .nullable()
    .required('กรุณาระบุ Email')
    .email('รูปแบบ Email ไม่ถูกต้อง')
    .max(100, 'กรุณาระบุ Email ไม่เกิน 100 ตัวอักษร'),
  firstName: yup
    .string()
    .nullable()
    .required('กรุณาระบุ First Name')
    .max(100, 'กรุณาระบุ First Name ไม่เกิน 100 ตัวอักษร'),
  lastName: yup
    .string()
    .nullable()
    .required('กรุณาระบุ Last Name')
    .max(100, 'กรุณาระบุ Last Name ไม่เกิน 100 ตัวอักษร'),
})
