import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'CADTO_PROFILE',
  moduleSlug: 'cadto-profile',
  moduleTitleFull: 'ข้อมูลโปรไฟล์ CADTO (CADTO Profile)',
  moduleTitleSecond: 'ข้อมูลโปรไฟล์ CADTO',
  modulesTitleShort: 'ข้อมูลโปรไฟล์ CADTO',
  modulePath: '/cadto-profile',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
