import { store } from '../../../../../../redux/store'

export const mutateFilterSateToProp = () => {
  const { filterState }: any = store.getState().table

  return {
    email: filterState.emailChecked ? filterState.email : '',
    firstName: filterState.firstNameChecked ? filterState.firstName : '',
    lastName: filterState.lastNameChecked ? filterState.lastName : '',
  }
}
