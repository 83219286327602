export const tableResultHeader = [
  'สถานะ',
  'Seq No.',
  'First Name',
  'Last Name',
  'Email',
  'Phone',
  'Agent Code',
]

export enum TABLE_COL_ERR_INDEX {
  seqNo = 0,
  firstName = 1,
  lastName = 2,
  email = 3,
  phone = 4,
  agentCode = 5,
}
