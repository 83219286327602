export const STATUS = {
  WAITING_FOR_REASSIGN_MANAGER: 'WAITING_FOR_REASSIGN_MANAGER', //รอ Assign
  SUBMITTED_SHORT_FORM: 'SUBMITTED_SHORT_FORM', //รอนัดหมาย
  MANAGER_ACCEPTED_INTERVIEW: 'MANAGER_ACCEPTED_INTERVIEW', //รอนัดสัมภาษณ์
  SUBMITTED_SHORT_FORM_INACTIVE_ACC: 'SUBMITTED_SHORT_FORM_INACTIVE_ACC', //รอการยืนยันตัวตน
}

export const LOGIN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}
