import { Box, Divider, Grid, Typography } from '@mui/material'
import { ItemDesktop, ItemMobile } from './Item'
import { shallowEqual, useSelector } from 'react-redux'
import { SliceModel } from '../../../../../redux/models'
import { CustomCard } from '../../../../../components/Layout/CustomCard'
import { STATUS_DISPLAY_TEXT } from '../../../../../constants/status'
import _ from 'lodash'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { AgentStepList, agentStepList } from '../../constants/statusGroup'
import { setLineClamp } from '../../../../../utils/lineClamp'

import { useState } from 'react'

export const getStatusStep = (item: AgentStepList, status: string) => {
  if (item.id === 1) return 'done'

  const groupInprogress: string[] = _.get(
    agentStepList[item.id - 1],
    'status.inprogress',
    []
  )

  if (groupInprogress.includes(status)) return 'inprogress'

  const groupDone: string[] = _.get(
    agentStepList[item.id - 1],
    'status.done',
    []
  )
  if (groupDone.includes(status)) return 'done'

  return 'pending'
}

export function CardStepDesktop() {
  const [statusText, setStatusText] = useState<string>('')
  const { isLoading, data } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.prospect.detail.isLoading,
      data: state.prospect.detail.data,
    }),
    shallowEqual
  )

  const getStatus = (item: AgentStepList) => {
    const status = getStatusStep(item, String(data?.status))
    if (status === 'inprogress') {
      if (statusText === '') setStatusText(item.title.replace('<br/>', ''))
    }
    return status
  }
  return (
    <CustomCard
      isLoading={isLoading}
      sx={{
        p: 0,
        mb: { xs: 2, sm: 3 },
        minHeight: '200px',
        display: { xs: 'none', sm: 'block' },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1b">สถานะผู้สมัครของคุณ : </Typography>
        <Typography variant="body1b" color={'primary'}>
          {_.get(STATUS_DISPLAY_TEXT, `${data?.status}`, '')}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ py: { xs: 2, sm: 3 } }}>
        <Grid container spacing={1}>
          {agentStepList.map((item, index) => (
            <Grid
              key={index}
              item
              xs={2.4}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <ItemDesktop status={getStatus(item)} data={item} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </CustomCard>
  )
}
export function CardStepMobile() {
  const [isMore, setIsMore] = useState<boolean>(false)
  const [statusText, setStatusText] = useState<string>('')

  const { isLoading, data } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.prospect.detail.isLoading,
      data: state.prospect.detail.data,
    }),
    shallowEqual
  )

  const getStatus = (item: AgentStepList) => {
    const status = getStatusStep(item, String(data?.status))
    if (status === 'inprogress') {
      if (statusText === '') setStatusText(item.title.replace('<br/>', ''))
    }
    return status
  }

  return (
    <CustomCard
      isLoading={isLoading}
      sx={{
        p: 0,
        mt: 2,
        minHeight: '100px',
        display: { xs: 'block', sm: 'none' },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={setLineClamp(1)}>
          <Typography variant="body1b">สถานะผู้สมัคร : </Typography>
          <Typography variant="body1b" color={'primary'}>
            {statusText}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: 2, display: isMore ? 'block' : 'none' }}>
        <Grid container>
          {agentStepList.map((item, index) => (
            <Grid key={index} item xs={12}>
              <ItemMobile status={getStatus(item)} data={item} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider />
      <Box
        sx={{ py: 1.5 }}
        textAlign={'center'}
        alignItems={'center'}
        onClick={() => setIsMore(!isMore)}
      >
        <Box display={'inline-flex'}>
          <Typography>ดูสถานะทั้งหมด</Typography>
          {isMore ? (
            <KeyboardArrowUpRoundedIcon />
          ) : (
            <KeyboardArrowDownRoundedIcon />
          )}
        </Box>
      </Box>
    </CustomCard>
  )
}
