import { Breakpoint, SxProps } from '@mui/material/styles'
import { themeColor } from '../../../styles/themes/themeData'
import Box from '@mui/material/Box'

export const defaultMaxWidth: Breakpoint = 'lg'
const BoxText = (props: { sx?: SxProps; children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: themeColor.grey.grey100,
        borderRadius: 2,
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  )
}
export default BoxText
