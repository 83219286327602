import { headerCells } from './features/Listing/model/headerCellsList'
import { prospectList } from '../../../utils/apiPath'

export const pageConfig = {
  moduleId: 'PROSPECT_LIST',
  moduleSlug: 'prospect',
  moduleTitleFull: 'รายการผู้สมัคร',
  moduleTitleSecond: '',
  modulesTitleShort: '',
  modulePath: '/prospect',
  filterPath: prospectList,
  headerCells: headerCells,
}
