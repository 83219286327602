import _ from 'lodash'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'
import dayjs from 'dayjs'

export const postBodyMutation = (data: any) => {
  const status = _.get(data, 'status', enumStatus.INACTIVE)
  const startDate = formatDate(_.get(data, 'startDate', null))
  const expiryDate = formatDate(_.get(data, 'expiryDate', null))

  return {
    uuid: _.get(data, 'uuid', undefined),
    id: _.get(data, 'id', ''),
    name: _.get(data, 'name', ''),
    description: _.get(data, 'description', ''),
    location: _.get(data, 'location', ''),
    status: status && status !== '' ? status : enumStatus.INACTIVE,
    startDate: startDate,
    expiryDate: expiryDate,
  }
}

const formatDate = (value: any) => {
  const format = 'MM/DD/YYYY'
  let dateValue = value
  if (dateValue && dateValue !== '')
    dateValue = dayjs(new Date(dateValue)).format(format)
  else dateValue = null
  return dateValue
}
