import { Box, Avatar, Typography, Skeleton } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { DateFormat } from '../../../../../../constants/dateFormat'
import { SliceModel } from '../../../../../../redux/models'
import {
  calculateAge,
  convertFormatDateTimeV2,
} from '../../../../../../utils/convertFormatDateTime'
import { formatFullNameSimple } from '../../../utils/fotmatTextDisplay'
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp'
import CustomButton from '../../../../../../components/Input/CustomButton'

export default function ProfileInfo() {
  const { data, isLoading } = useSelector(
    (state: SliceModel) => ({
      data: state.prospect.detail.data,
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )

  return (
    <CustomCard
      isLoading={isLoading}
      skeleton={<BoxSkeleton />}
      sx={{ height: '145px' }}
    >
      <Box display={'flex'} gap={2}>
        <Box mt={1.5}>
          <Avatar
            sx={{ width: '64px', height: '64px' }}
            src={`${process.env.PUBLIC_URL}/icons/profile-def.png`}
          />
        </Box>
        <Box>
          <Box mb={0.5}>
            <Typography variant="body1b" color={'primary'}>
              {formatFullNameSimple(data?.firstName, data?.lastName, 35)}
            </Typography>
          </Box>
          <Box display={'flex'}>
            <Typography variant="body2" sx={{ color: 'text.gray' }}>
              อายุ:
            </Typography>
            <Typography variant="body2" sx={{ pl: 0.5 }}>
              {calculateAge(data?.dateOfBirth)} ปี
            </Typography>
          </Box>
          <Box display={'flex'}>
            <Typography variant="body2" sx={{ color: 'text.gray' }}>
              วันที่สมัคร:
            </Typography>
            <Typography variant="body2" sx={{ pl: 0.5 }}>
              {convertFormatDateTimeV2(data?.registeredAt, DateFormat.DDMMYYYY)}
            </Typography>
          </Box>
          <Box mt={1}>
            <CustomButton size={'small'} variant={'outlined'}>
              <PhoneSharpIcon />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {data?.mobileNo}
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  )
}

const BoxSkeleton = () => {
  return (
    <>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Box>
          <Skeleton
            variant={'rounded'}
            sx={{ borderRadius: '50%' }}
            width={64}
            height={64}
          />
        </Box>
        <Box>
          <Box mb={1}>
            <Typography variant="body1b" color={'primary'}>
              <Skeleton width={'200px'} />
            </Typography>
          </Box>
          <Box display={'flex'}>
            <Typography variant="body2">
              <Skeleton width={'70px'} />
            </Typography>
          </Box>
          <Box display={'flex'}>
            <Typography variant="body2">
              <Skeleton width={'100px'} />
            </Typography>
          </Box>
          <Box display={'flex'}>
            <Typography variant="body2">
              <Skeleton width={'100px'} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}
