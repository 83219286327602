import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'REJECTED_REASON_AFTER_INTERVIEW',
  moduleSlug: 'reject-after-interview',
  moduleTitleFull: 'ประเภทเหตุผลการปฎิเสธหลังสัมภาษณ์ (Rejected Reason)',
  moduleTitleSecond: 'ประเภทเหตุผลการปฎิเสธหลังสัมภาษณ์',
  modulesTitleShort: 'ประเภทของเหตุผล',
  modulePath: '/reject-after-interview',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
