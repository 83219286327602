import type { OptionItem } from '../../../../types/input'

export const leadSourceOptions: OptionItem[] = [
  {
    label: 'Event',
    value: 'event',
  },
  {
    label: 'Offline',
    value: 'offline',
  },
]

export const crudConfig = {
  masterDataModuleId: [
    {
      moduleId: 'OFFLINE_SUB_SOURCE',
      key: 'offlineSubSource',
    },
    {
      moduleId: 'EVENT_SUB_SOURCE',
      key: 'eventSubSource',
    },
  ],
}

export type CrudFormData = {
  leadSource: {
    label: string
    value: string
  }
  subSource: {
    label: string
    value: string
  }
}
