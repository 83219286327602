import _ from 'lodash'
import { callAPI } from './callAPI'

export const handleDownload = async ({ url, body, fileName }: any) => {
  await callAPI({
    url: url,
    method: 'POST',
    body: body,
    responseType: 'blob',
    onSuccess: (res) => {
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    },
  })
}

export const validatePermission = ({
  user,
  moduleType,
  permission,
  data,
}: any) => {
  if (_.isNil(user)) {
    return false
  }
  return hasPermission({
    userPermissions: user.permission,
    permissionList: [
      {
        module: moduleType,
        permission: permission,
        owner: 'PIC',
        isValidatePIC: hasPermissionPIC({
          user,
          data: data,
          moduleType: moduleType,
          permission: permission,
        }),
      },
      {
        module: moduleType,
        permission: permission,
        owner: 'ALL',
      },
    ],
  })
}

export const hasPermission = ({
  userPermissions = [],
  permissionList = [],
}: any) => {
  let isAccess = false
  permissionList.forEach((item: any) => {
    const findPermission = userPermissions.find((userPermission: any) => {
      return (
        //first condition with PIC
        (userPermission.module === item.module &&
          userPermission.owner === item.owner &&
          item.permission.find(
            (itemPermission: any) =>
              userPermission.permission === itemPermission
          ) &&
          _.isNil(item.isValidatePIC)) ||
        //or condition without PIC
        (userPermission.module === item.module &&
          userPermission.owner === item.owner &&
          item.permission.find(
            (itemPermission: any) =>
              userPermission.permission === itemPermission
          ) &&
          !_.isNil(item.isValidatePIC) &&
          item.isValidatePIC === true)
      )
    })

    if (findPermission) {
      isAccess = true
    }
  })
  return isAccess
}

const findAreaUID = ({ areaUID, user }: any) => {
  const userAreaList = _.get(user, 'area', [])
  return userAreaList.find((item: any) => item.uuid === areaUID)
}

export const hasPermissionPIC = ({
  user,
  data,
  moduleType,
  permission,
}: any) => {
  if (_.isNil(data)) {
    return true
  }
  const assignee = _.get(data, 'assignee', [])
  const staffProfile = _.get(data, 'staffProfile', [])
  const areaUID = _.get(data, 'area.uuid', '')
  let hasFindPermission = false
  permission.forEach((perm: any) => {
    switch (`${moduleType}_${perm}`) {
      case 'COURSE_SETTING_CREATE':
        hasFindPermission = !!assignee.find(
          (item: any) =>
            _.get(item, 'user.uuid', '') === _.get(user, 'uuid', '')
        )
        break
      case 'COURSE_SETTING_DELETE':
        hasFindPermission = !!assignee.find(
          (item: any) =>
            _.get(item, 'user.uuid', '') === _.get(user, 'uuid', '')
        )
        break
      case 'MONTHLY_PLAN_APPROVE_ZONE':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_ASSISTS_ZONE':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_CREATE_ALL':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_CREATE_LEAVE':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_CREATE_ALL_EXC_HOLIDAY':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_DELETE_ALL':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_DELETE_LEAVE':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_DELETE_ALL_EXC_HOLIDAY':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'TRAINING_PLAN_CREATE':
        hasFindPermission = !!findStaffId({ staffProfile, user })
        break
      case 'E_MANAGECLASS_SETTING_EDIT_ALL_CLASS':
        hasFindPermission = !!findStaffId({ staffProfile, user })
        break
      case 'E_LEARNING_ADMIN':
      case 'E_LEARNING_TRAINER':
        hasFindPermission = _.get(data, 'setting.assignees', []).some(
          (item: any) => _.get(item, 'value', '') === _.get(user, 'uuid', '')
        )
        break
    }
  })
  return hasFindPermission
}

const findStaffId = ({ staffProfile, user }: any) => {
  return staffProfile.find((item: any) => item.uuid === user.uuid)
}

export const validateEPermission = ({ module, permission, data }: any) => {
  const user = JSON.parse(window?.localStorage?.getItem('user') as any)
  const creator = _.get(data, 'createdBy', undefined)
  const assignees = _.get(
    data,
    'assignees',
    _.get(data, 'setting.assignees', [])
  )

  if (_.isNil(user)) {
    return false
  }

  let hasPermission = false
  for (const obj of user.permission) {
    if (hasPermission) break
    if (obj.module === module) {
      if (obj.permission === permission) {
        hasPermission = checkPermissionOwner({
          owner: obj.owner,
          user,
          creator,
          assignees,
        })
      }
    }
  }

  return hasPermission
}

export const checkPermissionOwner = ({
  owner,
  user,
  creator,
  assignees,
}: any) => {
  if (owner === 'ALL') return true

  let hasPermission = false

  if (creator) {
    hasPermission = creator.uuid === user.uuid
  }

  if (!hasPermission) {
    if (assignees) {
      hasPermission = assignees.some(
        (assignee: any) => _.get(assignee, 'uuid', assignee.value) === user.uuid
      )
    }
  }

  return hasPermission
}

export function isDuplicateAgentCode<T extends { agentCode: string }[]>(
  agentCode: string,
  uploadData: T
): boolean {
  return uploadData.filter((data) => data.agentCode === agentCode).length > 1
}
