import type { IImportManager } from '../modules/Manager/import-manager/CheckInformationStep'

import { AREA_QUEUE_FIELD, PROVINCE_QUEUE_FIELD } from '../constants/ma'

type Result = {
  seqNo: number
  message: string
}

export function checkQueueData(data: IImportManager[]): Result[] {
  const areaMap: { [key: string]: { [key: number]: number } } = {}
  const provinceMap: { [key: string]: { [key: number]: number } } = {}
  const seqNoMap: { [key: string]: number } = {}
  const results: Result[] = []

  for (const [index, entry] of data.entries()) {
    const { seqNo, workingArea, areaQueue, province, provinceQueue } = entry

    seqNoMap[seqNo] = seqNoMap[seqNo] ? seqNo + 1 : 1

    // ตรวจสอบว่า areaQueue และ provinceQueue ไม่น้อยกว่า 1, ไม่มากกว่า 999, ไม่มีค่าเป็น null
    if (areaQueue < 1 || areaQueue > 999 || areaQueue === null) {
      results.push({
        seqNo: index + 1,
        message: `รูปแบบ ${AREA_QUEUE_FIELD} ไม่ถูกต้อง`,
      })
    }

    if (provinceQueue < 1 || provinceQueue > 999 || provinceQueue === null) {
      results.push({
        seqNo: index + 1,
        message: `รูปแบบ ${PROVINCE_QUEUE_FIELD} ไม่ถูกต้อง`,
      })
    }

    if (results?.[index]?.message) {
      continue
    }

    // ถ้ายังไม่มี workingArea นี้ใน areaMap ให้สร้างใหม่
    if (!areaMap[workingArea]) {
      areaMap[workingArea] = {}
    }

    // ถ้ายังไม่มี province นี้ใน provinceMap ให้สร้างใหม่
    if (!provinceMap[province]) {
      provinceMap[province] = {}
    }

    // ตรวจสอบการซ้ำของ areaQueue ใน workingArea เดียวกัน
    if (areaMap[workingArea][areaQueue] !== undefined) {
      const duplicateRow = areaMap[workingArea][areaQueue]
      results.push({
        seqNo: index + 1,
        message: `ข้อมูล ${AREA_QUEUE_FIELD} ${areaQueue} ซ้ำกับ row ที่ ${duplicateRow}`,
      })
    } else {
      areaMap[workingArea][areaQueue] = index + 1
    }

    // ตรวจสอบการซ้ำของ provinceQueue ใน province เดียวกัน
    if (provinceMap[province][provinceQueue] !== undefined) {
      const duplicateRow = provinceMap[province][provinceQueue]
      results.push({
        seqNo: index + 1,
        message: `ข้อมูล ${PROVINCE_QUEUE_FIELD} ${provinceQueue} ซ้ำกับ row ที่ ${duplicateRow}`,
      })
    } else {
      provinceMap[province][provinceQueue] = index + 1
    }
  }

  // ตรวจสอบลำดับที่ต่อเนื่องกันของ areaQueue ในแต่ละ workingArea
  for (const area in areaMap) {
    const queues = Object.keys(areaMap[area])
      .map(Number)
      .sort((a, b) => a - b)
    for (let i = 0; i < queues.length; i++) {
      if (queues[i] !== i + 1) {
        const seqNo = areaMap[area][queues[i]]
        results.push({
          seqNo,
          message: `รูปแบบ ${AREA_QUEUE_FIELD} ${queues[i]} ไม่ถูกต้อง`,
        })
      }
    }
  }

  // ตรวจสอบลำดับที่ต่อเนื่องกันของ provinceQueue ในแต่ละ province
  for (const province in provinceMap) {
    const queues = Object.keys(provinceMap[province])
      .map(Number)
      .sort((a, b) => a - b)
    for (let i = 0; i < queues.length; i++) {
      if (queues[i] !== i + 1) {
        const seqNo = provinceMap[province][queues[i]]
        results.push({
          seqNo,
          message: `รูปแบบ ${PROVINCE_QUEUE_FIELD} ${queues[i]} ไม่ถูกต้อง`,
        })
      }
    }
  }

  // Check duplicate seq no
  for (const seqNo in seqNoMap) {
    if (seqNoMap[seqNo] > 1) {
      results.push({
        seqNo: parseInt(seqNo),
        message: `Seq No ${seqNo} ซ้ำกัน`,
      })
    }
  }

  return results
}
