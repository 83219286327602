import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'EVENT_SUB_SOURCE',
  moduleSlug: 'event-sub-source',
  moduleTitleFull: 'รายการแหล่งย่อยอีเว้นท์สำหรับการสร้างการเลือกแหล่งย่อย',
  moduleTitleSecond: 'รายการแหล่งย่อยอีเว้นท์',
  modulesTitleShort: 'แหล่งย่อยอีเว้นท์',
  modulePath: '/event-sub-source',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
