import { store } from '../../../../../../redux/store'
import { getFormatDate } from '../../../../../../utils/getFormatDate'
import { formatDate } from '../constants/formatDate'
import { ProcessResultApprovalStatus } from '../../../../../../constants/status'
import { LOGIN_STATUS } from '../model/status'

export function mutateFilterSateToProp() {
  const { filterState }: any = store.getState().table
  const status = []
  const loginStatus = []
  if (filterState?.prospectStatusChecked) {
    if (filterState?.prospectStatus?.waitingForReAssignChecked) {
      status.push(ProcessResultApprovalStatus.DL_SUCCESSFULLY_SENT_ASSIGN_RULE)
    }
    if (filterState?.prospectStatus?.submittiedShortFormChecked) {
      status.push(ProcessResultApprovalStatus.DL_ASSIGNED_MA)
    }
    if (filterState?.prospectStatus?.submittiedShortFormInactiveChecked) {
      status.push(ProcessResultApprovalStatus.DL_SUBMITTED_SHORT_FORM)
    }
    if (filterState?.prospectStatus?.managerAcceptedInterviewChecked) {
      status.push(ProcessResultApprovalStatus.DL_SUBMITTED_SHORT_FORM)
    }
  } else {
    status.length = 0
  }

  if (filterState?.loginStatusChecked) {
    if (filterState?.loginStatus.statusActiveChecked) {
      loginStatus.push(LOGIN_STATUS.ACTIVE)
    }
    if (filterState?.loginStatus.statusInactiveChecked) {
      loginStatus.push(LOGIN_STATUS.INACTIVE)
    }
  } else {
    loginStatus.length = 0
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    managerCode: filterState.managerCodeChecked ? filterState.managerCode : '',
    managerName: filterState.managerNameChecked ? filterState.managerName : '',
    channel: filterState.channelChecked ? filterState.channel : '',
    startDate: filterState.dateChecked
      ? getFormatDate(filterState.startDate, formatDate)
      : '',
    endDate: filterState.dateChecked
      ? getFormatDate(filterState.endDate, formatDate)
      : '',
    status: status,
    loginStatus,
  }
}
