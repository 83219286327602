import { store } from '../../../../../../redux/store'

export const mutateFilterSateToProp = () => {
  const { filterState }: any = store.getState().table

  return {
    agentCode: filterState.agentCodeChecked ? filterState.agentCode : '',
    managerName: filterState.managerNameChecked ? filterState.managerName : '',
    workingArea: filterState.workingAreaChecked ? filterState.workingArea : '',
    province: filterState.provinceChecked ? filterState.province : '',
  }
}
