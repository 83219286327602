import { type TypeAppState } from './types/initialStateTable'
import { setChangePage } from '.'
import { initialState } from './model'
import type { AppDispatch } from '../../../redux/store'

export const handleResetTable = (state: TypeAppState) => {
  state.table = { ...initialState.table }
  state.filter = { ...initialState.filter }
  state.search = { ...initialState.search }
  state.handleSearch = null
  state.onDownload = null
  state.onQuickFilter = null
  state.onChangeStatus = null
  state.customToolbar = null
  state.customStyle = undefined
}

export const handleChangePage =
  (newPage: number, isBasicTable: boolean) => (dispatch: AppDispatch) => {
    const tablePage = newPage < 0 ? 1 : +newPage
    const page = newPage + 1
    dispatch(setChangePage({ page, tablePage }))
  }

//   export const handleChangeRowsPerPage = (event, isBasicTable) => (dispatch) => {
//     const value = parseInt(event.target.value, 10)
//     const rowsPerPageChange = value > 0 ? value : -1
//     const rowsPerPage = {
//       label: '' + rowsPerPageChange,
//       value: rowsPerPageChange,
//     }
//     const limit = value

//     if (isBasicTable) {
//       dispatch(setBasicChangeRowsPerPage({ rowsPerPage, limit }))
//     } else {
//       dispatch(setChangeRowsPerPage({ rowsPerPage, limit }))
//     }
//   }
