import * as yup from 'yup'

export const formValidationSchema = yup.object({
  name: yup
    .string()
    .required('กรุณาระบุชื่ออีเว้นท์')
    .max(100, 'กรุณาระบุชื่ออีเว้นท์ไม่เกิน 100 ตัวอักษร'),
  description: yup
    .string()
    .required('กรุณาระบุรายละเอียดของอีเว้นท์')
    .max(100, 'กรุณาระบุรายละเอียดของอีเว้นท์ไม่เกิน 100 ตัวอักษร'),
  location: yup
    .string()
    .required('กรุณาระบุสถานที่ของอีเว้นท์')
    .max(100, 'กรุณาระบุสถานที่ของอีเว้นท์ไม่เกิน 100 ตัวอักษร'),
  startDate: yup.string().required('กรุณาระบุเวลาเริ่มต้นอีเว้นท์'),
  expiryDate: yup.string().required('กรุณาระบุเวลาสิ้นสุดอีเว้นท์'),
  status: yup.string().oneOf(['ACTIVE', 'INACTIVE']).required('กรุณาระบุสถานะ'),
})

export type FormValidationSchema = yup.InferType<typeof formValidationSchema>
