import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const DrawerHistory = styled(Drawer)(({ theme }) => ({
  height: '100vh',
  '& .MuiDrawer-paper': {
    width: '105vh',
    backgroundColor: theme.palette.background.default,
  },
}))
