export enum ProcessResultApprovalStatus {
  ACTIVE = 'ACTIVE',
  DL_SUBMITTED_SHORT_FORM = 'DL_SUBMITTED_SHORT_FORM',
  DL_SUCCESSFULLY_SENT_ASSIGN_RULE = 'DL_SUCCESSFULLY_SENT_ASSIGN_RULE',
  DL_ASSIGNED_MA = 'DL_ASSIGNED_MA',
  DL_DROPPED = 'DL_DROPPED',
  DL_CHANGED = 'DL_CHANGED_MA',

  I_ACCEPTED_INTERVIEW = 'I_ACCEPTED_INTERVIEW',
  I_DECLINED_INTERVIEW = 'I_DECLINED_INTERVIEW',
  I_PASSED_INTERVIEW = 'I_PASSED_INTERVIEW',
  I_FAILED_INTERVIEW = 'I_FAILED_INTERVIEW',
  I_FAILED_OUT_OF_LIMIT = 'I_FAILED_OUT_OF_LIMIT',
  I_TIME_OUT_OF_LIMIT = 'I_TIME_OUT_OF_LIMIT',

  DS_SAVED_FULL_FORM = 'DS_SAVED_FULL_FORM',
  DS_SUBMITTED_FULL_FORM = 'DS_SUBMITTED_FULL_FORM',
  DS_REVIEWED = 'DS_REVIEWED',

  DCV_ACCEPTED_REVIEW = 'DCV_ACCEPTED_REVIEW',
  DCV_REQUESTED_MEMO = 'DCV_REQUESTED_MEMO',
  DS_SAVED_FULL_FORM_MEMO = 'DS_SAVED_FULL_FORM_MEMO',
  DS_SUBMITTED_FULL_FORM_MEMO = 'DS_SUBMITTED_FULL_FORM_MEMO',

  DCV_PASSED_PROSPECT_HISTORY = 'DCV_PASSED_PROSPECT_HISTORY',
  DCV_REQUESTED_DESCISION = 'DCV_REQUESTED_DESCISION',
  DCV_UPDATED_DESCISION = 'DCV_UPDATED_DESCISION',
  DCV_APPROVED_TO_BE_AGENT = 'DCV_APPROVED_TO_BE_AGENT',
  DCV_DROPPED = 'DCV_DROPPED',
  DCV_UPDATED_E_LICENCING = 'DCV_UPDATED_E_LICENCING',
  DCV_UPDATED_AGENT_CODE = 'DCV_UPDATED_AGENT_CODE',
}
export enum ApiStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  ERROR_MAX = 'ERROR_MAX',
  ERROR_FORMAT = 'ERROR_FORMAT',
}

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export const STATUS_DISPLAY_TEXT = {
  DL_SUBMITTED_SHORT_FORM: 'ระบบกำลังจับคู่ผู้จัดการ',
  DL_SUCCESSFULLY_SENT_ASSIGN_RULE: 'รอมอบหมายผู้จัดการ',
  DL_ASSIGNED_MA: 'รอนัดหมาย',
  DL_DROPPED: 'ไม่ผ่านการเป็นตัวแทน',
  DL_CHANGED_MA: 'ถูกมอบหมายผู้จัดการคนใหม่',

  I_ACCEPTED_INTERVIEW: 'ระหว่างรอสัมภาษณ์',
  I_DECLINED_INTERVIEW: 'ปฏิเสธการนัดสัมภาษณ์',
  I_PASSED_INTERVIEW: 'รอกรอกฟอร์ม',
  I_FAILED_INTERVIEW: 'ไม่ผ่านการสัมภาษณ์',
  I_FAILED_OUT_OF_LIMIT: 'โดนปฎิเสธเกิน 2 ครั้ง ',

  I_TIME_OUT_OF_LIMIT: 'ไม่มีการดำเนินงานจากผู้จัดการ',

  DS_SAVED_FULL_FORM: 'เริ่มกรอกฟอร์มแบบเต็ม',
  DS_SUBMITTED_FULL_FORM: 'ส่งฟอร์มแบบเต็มสำเร็จ',
  DS_REVIEWED: 'ผู้จัดการตรวจสอบแล้ว',

  DCV_ACCEPTED_REVIEW: 'DC เริ่มตรวจสอบแล้ว',
  DCV_REQUESTED_MEMO: 'DC ต้องการข้อมูลเพิ่มเติ่ม',
  DS_SAVED_FULL_FORM_MEMO: 'เริ่มแก้ไขฟอร์มแบบเต็ม',
  DS_SUBMITTED_FULL_FORM_MEMO: 'ส่งฟอร์มแบบเต็มเพิ่มเติมสำเร็จ',

  DCV_PASSED_PROSPECT_HISTORY: 'DC ตรวจเอกสารผ่านแล้ว',
  DCV_REQUESTED_DESCISION: 'DC ต้องการตัดสินใจเพิ่มเติม',
  DCV_UPDATED_DESCISION: 'ได้รับผลการตัดสินใจแล้ว',
  DCV_APPROVED_TO_BE_AGENT: 'DC อนุมัติการเป็นตัวแทน',
  DCV_DROPPED: 'DC ไม่อนุมัติการเป็นตัวแทน',
  DCV_UPDATED_E_LICENCING: 'DC อัปเดทผล OIC แล้ว',
  DCV_UPDATED_AGENT_CODE: 'เป็นตัวแทนเรียบร้อย',
}

export type Keys =
  | 'DL_SUBMITTED_SHORT_FORM'
  | 'DL_SUCCESSFULLY_SENT_ASSIGN_RULE'
  | 'DL_ASSIGNED_MA'
  | 'DL_DROPPED'
  | 'DL_CHANGED_MA'
  | 'I_ACCEPTED_INTERVIEW'
  | 'I_DECLINED_INTERVIEW'
  | 'I_PASSED_INTERVIEW'
  | 'I_FAILED_INTERVIEW'
  | 'I_FAILED_OUT_OF_LIMIT'
  | 'I_TIME_OUT_OF_LIMIT'
  | 'DCV_UPDATED_AGENT_CODE'

export const getStatusDisplayText = (statusKey: Keys): string => {
  return STATUS_DISPLAY_TEXT[statusKey] || 'สถานะไม่ทราบ'
}

export const ERRORS_STATUS = [401, 403, 404, 500, 503]
export const ERROR_NETWORK = 'ERR_NETWORK'
