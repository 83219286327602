import { CustomDateInput } from '../../../../../../../components/CRUD/components/CustomDateInput'
import { Box } from '@mui/material'

export const InputDateGroup = () => {
  return (
    <>
      <Box display="flex" gap={3} alignItems="start">
        <CustomDateInput
          fieldName="startDate"
          labelName="วันที่เริ่มต้น"
          placeholder="เลือกวันที่"
          required={true}
        />
        <CustomDateInput
          fieldName="expiryDate"
          labelName="วันที่สิ้นสุด"
          placeholder="เลือกวันที่"
          required={true}
        />
      </Box>
    </>
  )
}
