import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import _ from 'lodash'

import TextField from '@mui/material/TextField'
import Autocomplete, {
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete'
import Table from '../../../components/Table2'
import TextInput from '../../../components/Input/TextInput'
import ViewImage from '../../../components/Upload/BasicUpload2'

import {
  convertData,
  handleInputChange,
  handleSetSelectedProps,
  onAddMemberClick,
  onDelete,
  onView,
} from './events'
import { themeColor } from '../../../styles/themes/themeData'
import { convertFormatDateTime } from '../../../utils/convertFormatDateTime'
import { getOptionsKey } from '../../../utils/getOptionsKey'
import useHistory from '../../../utils/useHistory'
import { AppDispatch } from '../../../redux/store'

interface PermissionState {
  data: any
  staffList: any[]
  permissionList: any[]
  isLoading: boolean
  isPageLoading: boolean
  error: string | null
}

interface FormProps {
  isEditForm: boolean
  formik: any
}

interface Option {
  label: string
  value: string
  image?: string
}

const permissionHeadCells = [
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '70px',
  },
  {
    id: 'module',
    label: 'Module',
    width: '180px',
  },
  {
    id: 'permission',
    label: 'Permission',
    width: '240px',
  },
  {
    id: 'owner',
    label: 'Owner',
    width: '20px',
  },
  {
    id: 'remark',
    label: 'Remark',
  },
]

const staffsHeadCells = [
  {
    id: 'agentCode',
    disablePadding: false,
    label: 'รหัสพนักงาน',
    width: '150px',
    hideSortIcon: true,
  },
  {
    id: 'firstName',
    label: 'ชื่อพนักงาน',
    hideSortIcon: true,
  },
  {
    id: 'actionPermission',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
  },
]

const Form: React.FC<FormProps> = ({ isEditForm, formik }) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const { data, staffList, permissionList } = useSelector(
    (state: { permission: PermissionState }) => state.permission,
    shallowEqual
  )

  const staff = _.get(data, 'staff', []) as any
  const staffOptions = getOptionsKey({
    data: staffList,
    label: 'fullname',
    key: 'uuid',
  }) as any

  return (
    <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        {isEditForm && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mx: 3,
            }}
          >
            <TextInput
              labelText="ID"
              type="basic"
              disabled
              required
              value={_.get(data, 'id', 0)}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mx: 3,
            gap: 3,
          }}
        >
          <TextInput
            id="groupName"
            name="groupName"
            labelText="ชื่อกลุ่ม"
            type="basic"
            inputProps={{
              'data-testid': 'groupName',
            }}
            textError={formik?.errors?.groupName}
            required
            value={_.get(data, 'groupName', '')}
            onChange={(e: any) => dispatch(handleInputChange(e))}
          />
          <TextInput
            id="PermissionGroup"
            name="PermissionGroup"
            labelText="Permission"
            type="basic"
            disabled
            value={_.get(data, 'permissionGroup', '')}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mx: 3,
          }}
        >
          <Typography variant="body1b">สมาชิกกลุ่ม ({staff.length})</Typography>
          <Button
            data-testid="add-member"
            size="small"
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => dispatch(onAddMemberClick(staff, staffOptions))}
          >
            เพิ่มสมาชิก
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mx: 3,
          }}
        >
          <Table
            isCheckBox={false}
            data={convertData(staff)}
            headCells={staffsHeadCells}
            onView={(row: any) => onView(row)}
            onDelete={(row: any, index: any) => dispatch(onDelete(index))}
            page={1}
            allCount={staff.length}
            tablePage={0}
            isShowPagination={false}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mx: 3,
            mb: 2,
          }}
        >
          <Typography variant="body1b">
            เลือก Permission ที่ต้องการสำหรับการใช้สิทธิ์สำหรับพนักงานกลุ่มนี้
          </Typography>
          <Table
            data={_.sortBy(permissionList, 'id')}
            headCells={permissionHeadCells}
            isShowPagination={false}
            selectedProps={_.get(data, 'permission', [])}
            setSelectedProps={(selected: any) =>
              dispatch(handleSetSelectedProps(selected, permissionList))
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            mx: 3,
            p: 1,
            backgroundColor: themeColor.grey.grey200,
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ width: '10%' }} variant="body1b">
            CRUD
          </Typography>
          <Typography sx={{ width: '40%' }} variant="body1b">
            คือ Create, Read, Update, Delete
          </Typography>
          <Typography sx={{ width: '10%' }} variant="body1b">
            PIC
          </Typography>
          <Typography sx={{ width: '40%' }} variant="body1b">
            คือ เป็นผู้รับผิดชอบงาน
            มีสิทธิ์แก้ไขงาน/โซนที่ตัวเองรับผิดชอบเท่านั้น
          </Typography>
        </Box>
        <Box
          sx={{
            mb: 3,
            mx: 3,
            mt: 4.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Button
              data-testid="btn-save"
              variant="contained"
              name="submit"
              type="submit"
              size="medium"
              onClick={() => formik.setValues(data)}
            >
              บันทึก
            </Button>
            <Button
              data-testid="btn-cancel"
              variant="outlined"
              size="medium"
              sx={{
                ml: 4,
              }}
              onClick={() => history.goBack()}
            >
              ยกเลิก
            </Button>
          </Box>

          {isEditForm && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                [theme.breakpoints.down('md')]: { mt: 2 },
              }}
            >
              <Typography variant="caption" color="text.lightGray">
                แก้ไขล่าสุดโดย {data && data.updatedBy ? data.updatedBy : ''}
                <br />
                {data && data.updatedAt
                  ? convertFormatDateTime({
                      value: _.get(data, 'updatedAt', null),
                      type: 'dateTime',
                    })
                  : ''}
              </Typography>
            </Box>
          )}
        </Box>
      </form>
    </Card>
  )
}
export default Form

interface StaffAutocompleteInputProps {
  staffOptions: Option[]
  staff: any[]
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: Option | null,
    reason: AutocompleteChangeReason
  ) => void
}

export const StaffAutocompleteInput: React.FC<StaffAutocompleteInputProps> = ({
  staffOptions,
  staff,
  onChange,
}: any) => {
  return (
    <Box sx={{ mx: 3, mb: 2 }}>
      <Autocomplete
        options={_.sortBy(staffOptions, 'label')}
        getOptionLabel={(option) => option.label}
        sx={{ width: '100%' }}
        renderOption={(props: any, option: any) => {
          if (staff.some((item: any) => item.uuid === option.value)) return null
          return (
            <Box {...props} key={option.value} id={option.value}>
              {!_.isNil(option.image) ? (
                <ViewImage
                  value={option.image}
                  isShowDescription={false}
                  isPreview={true}
                  isShowTextError={false}
                  isStopPropagation={true}
                  style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '8px',
                    borderRadius: '50%',
                  }}
                />
              ) : (
                <AccountCircleRoundedIcon
                  sx={{ width: 24, height: 24, mr: 1 }}
                />
              )}
              <Typography variant="body1" id={option.value}>
                {option.label}
              </Typography>
            </Box>
          )
        }}
        renderInput={(params) => (
          <TextField placeholder={'กรุณาเลือก'} {...params} />
        )}
        onChange={onChange}
      />
    </Box>
  )
}
