export const headerCells: any = [
  {
    id: 'importCount',
    label: 'รอบนำเข้า',
  },
  {
    id: 'firstName',
    label: 'ชื่อ',
    custom: true,
    customWidth: '150px',
  },
  {
    id: 'lastName',
    label: 'นามสกุล',
    custom: true,
    customWidth: '150px',
  },
  {
    id: 'email',
    label: 'อีเมล',
    custom: true,
    customWidth: '200px',
  },
  {
    id: 'phone',
    label: 'หมายเลขโทรศัพท์มือถือ',
    custom: true,
    customWidth: '150px',
  },
  {
    id: 'agentCode',
    label: 'เลขที่ตัวแทนผู้จัดการ',
  },
]
