export const headerCells = [
  {
    id: 'name',
    disablePadding: false,
    label: 'ชื่อผู้สมัคร',
    width: '200px',
  },
  {
    id: 'channel',
    label: 'ช่องทาง',
    width: '100px',
  },
  {
    id: 'managerCode',
    label: 'รหัสผู้จัดการ',
    width: '130px',
  },
  {
    id: 'managerName',
    label: 'ชื่อผู้จัดการ',
    width: '220px',
  },
  {
    id: 'managerEmail',
    label: 'อีเมลผู้จัดการ',
    width: '230px',
  },
  {
    id: 'managerTel',
    label: 'โทรศัพท์ผู้จัดการ',
    width: '200px;',
  },
  {
    id: 'createdAt',
    label: 'วันที่สมัคร',
    width: '180px',
  },
  {
    id: 'prospectStatus',
    label: 'สถานะการสมัคร',
    width: '200px',
  },
]
