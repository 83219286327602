import React, { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import useTheme from '@mui/system/useTheme'
import { Link } from 'react-router-dom'
import { DialogContext } from '../../context/dialog-context'
import Table from '../../components/Table2'
import FilterDrawer from './FilterDrawer'
import AlertDialog from '../../components/AlertDialog'
import Breadcrumbs from '../../components/Breadcrumbs'
import {
  filterPermissionGroup,
  permissionGroup,
  downloadPermissionGroup,
} from '../../utils/apiPath'
import useHistory from '../../utils/useHistory'
import { callAPI } from '../../utils/callAPI'
import { initialState } from '../../components/Permission/model'
import { setInitialForm } from '../../components/Permission/slices'
import { handleFilterTotal } from '../../components/Table/redux/handleFilterTotal'
import { handleDownload } from '../../utils'

const breadcrumbList = [
  { title: 'Manage', link: '/', pointer: false },
  { title: 'สิทธิ์การใช้งาน', link: '/', pointer: false },
]

const StatusList: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<any>(true)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState(['ACTIVE', 'INACTIVE', 'DELETED'])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [filterTotal, setFilterTotal] = useState(0)
  const { context, setContext: setDialog } = useContext<any>(DialogContext)
  const dialog = context.dialog

  const getPermissionPermissionView = true
  const getPermissionPermissionCreate = true
  const getPermissionPermissionDelete = true

  const headCells = [
    {
      id: 'id',
      disablePadding: false,
      label: 'ID',
      width: '80px',
    },
    {
      id: 'groupName',
      label: 'ชื่อกลุ่ม',
    },
    {
      id: 'permissionNameGroup',
      label: 'Permission',
      hideSortIcon: true,
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      hideView: getPermissionPermissionView ? false : true,
      hideEdit: getPermissionPermissionCreate ? false : true,
      hideDelete: getPermissionPermissionDelete ? false : true,
    },
  ]

  useEffect(() => {
    fetchDataList('fetch', status, limit, order, page, sort)
  }, [limit, order, sort, page])

  const handleClose = () => {
    setDialog({ ...dialog, open: false })
  }

  const handleQuickSearch = async (text: any) => {
    setTablePage(0)
    const statusList = ['ACTIVE', 'INACTIVE', 'DELETED']
    fetchDataList('search', statusList, limit, order, 1, sort, text)
  }

  const fetchDataList = async (
    method: any,
    statusList: any,
    limit: any,
    order: any,
    page: any,
    sort: any,
    search?: any
  ) => {
    const sortBy = sort === '' ? 'updatedAt' : sort
    const realPage = page <= 0 ? 1 : +page
    setIsLoading(true)
    const body = {
      groupName: search ? search : '',
      permissionUuid: [''],
      status: statusList,
      limit: limit,
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
    }
    callAPI({
      method: 'post',
      url: filterPermissionGroup,
      body: body,
      onSuccess: (data) => {
        let resultValue = []
        if (getPermissionPermissionView) {
          if (data.result.length) {
            resultValue = data.result.map((item: any) => {
              const permissionGroup: any = []
              item.permission.map((itemPermission: any) => {
                permissionGroup.push(itemPermission.id)
              })
              permissionGroup.sort(function (a: any, b: any) {
                return a - b
              })
              item.permissionNameGroup = permissionGroup.join(',')
              return { ...item }
            })
          }
        }
        setDataList(resultValue)
        setAllCount(data.totalCount)
      },
      onFinally: () => {
        setIsLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box sx={isLoading && { opacity: 0.4, pointerEvents: 'none' }}>
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">สิทธิ์การใช้งาน</Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Link
          data-testid={`btn-add-level`}
          to="/manage/permission/form"
          onClick={() => dispatch(setInitialForm(initialState.data))}
        >
          {getPermissionPermissionCreate && (
            <Button
              sx={{
                ml: 2,
                [theme.breakpoints.up('md')]: {
                  position: 'absolute',
                  mt: -10,
                  mr: 3,
                },
                right: 0,
              }}
              startIcon={<AddIcon />}
              variant="contained"
            >
              เพิ่มกลุ่ม
            </Button>
          )}
        </Link>
        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          <Table
            data={dataList}
            headCells={headCells}
            onView={(row: any) => onView(row, history)}
            onEdit={(row: any) => onEdit(row, history)}
            onDelete={(row: any) =>
              onDelete(
                row,
                allCount,
                dataList,
                setIsLoading,
                setAllCount,
                setDataList,
                setDialog
              )
            }
            placeholder="ค้นหาชื่อกลุ่ม"
            searchKey={'title'}
            setLimit={setLimit}
            order={order}
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            allCount={allCount}
            handleSearch={handleQuickSearch}
            onDownload={(selected: any) => onDownload(selected, setIsLoading)}
            setIsFilterDrawer={setIsFilterDrawer}
            searchText={searchText}
            setSearchText={setSearchText}
            tablePage={tablePage}
            setTablePage={setTablePage}
            text={text}
            setText={setText}
            filterTotal={filterTotal}
          />
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter: any) =>
          onFilterClick(
            filter,
            limit,
            order,
            sort,
            setText,
            setStatus,
            setTablePage,
            setSearchText,
            fetchDataList,
            setIsFilterDrawer,
            setFilterTotal
          )
        }
      />
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={handleClose}
        title={dialog.title}
        content={dialog.content}
        variant={dialog.variant}
        onConfirmClick={dialog.onConfirmClick}
        onCancelClick={dialog.onCancelClick}
        isLoading={isLoading}
      />
    </Box>
  )
}
export default StatusList

export const onView = (row: any, history: any) => {
  history.push('/manage/permission/' + row.uuid)
}
export const onEdit = (row: any, history: any) => {
  history.push('/manage/permission/edit/' + row.uuid)
}
export const handleDelete = async (
  row: any,
  setDialog: any,
  setIsLoading: any
) => {
  const path = permissionGroup + `/${row.uuid}`
  setIsLoading(true)
  callAPI({
    url: path,
    method: 'delete',
    onSuccess: () => {
      setDialog({
        variant: 'success',
        title: 'สำเร็จ',
        content: 'ถูกลบแล้ว',
        open: true,
        onConfirmClick: () => window.location.reload(),
      })
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}
export const onDelete = async (
  row: any,
  allCount: any,
  dataList: any,
  setIsLoading: any,
  setAllCount: any,
  setDataList: any,
  setDialog: any
) => {
  setDialog({
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    variant: 'delete',
    open: true,
    onConfirmClick: () => handleDelete(row, setDialog, setIsLoading),
    onCancelClick: () =>
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      }),
  })
}
export const onDownload = async (selected: any, setIsLoading: any) => {
  const body = {
    groupName: '',
    order: 'ASC',
    sort: 'id',
    status: ['ACTIVE'],
    type: 'CUSTOM',
    list: selected,
  }
  await handleDownload({
    url: downloadPermissionGroup,
    body: body,
    fileName: `รายการสิทธิ์การใช้งาน.xlsx`,
  })
  setIsLoading(false)
}
export const onFilterClick = async (
  filter: any,
  limit: any,
  order: any,
  sort: any,
  setText: any,
  setStatus: any,
  setTablePage: any,
  setSearchText: any,
  fetchDataList: any,
  setIsFilterDrawer: any,
  setFilterTotal: any
) => {
  setSearchText('')
  setText('')
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inactiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  }
  setStatus(statusList)
  const permissionText = filter?.groupnameChecked ? filter.groupnameText : ''
  setSearchText(permissionText)
  setTablePage(0)
  fetchDataList('filter', statusList, limit, order, 1, sort, permissionText)
  setIsFilterDrawer(false)
  setFilterTotal(handleFilterTotal(filter))
}
