import { WebStorageStateStore } from 'oidc-client-ts'
import { type AuthProviderProps } from 'react-oidc-context'

const OneAccount: AuthProviderProps = {
  authority: `${window.__env__.ONE_URL}`,
  client_id: window.__env__.ONE_CLIENT_ID,
  client_secret: window.__env__.ONE_CLIENT_SECRET,
  redirect_uri: `${window.location.origin}/`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  automaticSilentRenew: false,
  scope: 'openid email',
}
export default OneAccount
