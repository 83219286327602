import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
} from '@reduxjs/toolkit'
import * as XLSX from 'xlsx'

import { UploadState } from './model'
import { dispatch, store } from '../../../redux/store'
import { toCamelCase } from '../../../utils/json'
import { UPLOAD_SUCCESS } from '../../../constants/ma'
import { UPLOAD_ICON } from '../../../constants/master-data'

const initialState: UploadState = {
  isLoading: false,
  uploadProgress: 0,
  error: null,
  data: [],
  validatedData: [],
}

const slice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // SET UPLOAD PROGRESS
    uploadProgress(state, action: PayloadAction<number>) {
      state.uploadProgress = action.payload
    },

    // GET UPLOAD DATA
    getUploadDataSuccess(state: UploadState, action) {
      state.isLoading = false
      state.uploadProgress = 100
      state.data = action.payload
    },

    // GET DATA
    getDataSuccess(state: UploadState, action) {
      state.isLoading = false
      state.validatedData = state.validatedData
        ? [...state.validatedData, action.payload]
        : action.payload
    },

    // CLEAR UPLOADED DATA
    clearUploadedData(state: UploadState) {
      state.data = []
      state.validatedData = []
      state.uploadProgress = 0
    },
  },
})

// Reducer
export default slice.reducer

export const {
  hasError,
  uploadProgress,
  startLoading,
  getDataSuccess,
  getUploadDataSuccess,
  clearUploadedData,
} = slice.actions

export function getUploadExcelData<T = Record<string, unknown>>(file: File) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const render = new FileReader()

      render.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentCompleted = Math.round((e.loaded * 100) / e.total)

          dispatch(slice.actions.uploadProgress(percentCompleted))
        }
      }

      render.onload = (e) => {
        const arrayBuffer = e.target?.result

        if (arrayBuffer && typeof arrayBuffer !== 'string') {
          const workbook = XLSX.read(new Uint8Array(arrayBuffer), {
            type: 'array',
          })
          const sheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[sheetName]
          const jsonData = XLSX.utils.sheet_to_json<T>(sheet)

          // Convert keys to camel-case
          const camelCasedData = jsonData.map((item) =>
            toCamelCase(item as Record<string, unknown>)
          )

          if (!camelCasedData.length) {
            dispatch(slice.actions.hasError('ไม่พบข้อมูล')) // * Set error if the data doesn't exist
            dispatch(slice.actions.uploadProgress(0)) // * Reset progress in case of error

            return
          }

          // * Set json data to data object and progress to 100% after reading is complete
          dispatch(
            slice.actions.getUploadDataSuccess({
              uploadData: camelCasedData,
              fileName: file.name,
            })
          )
        }
      }

      render.readAsArrayBuffer(file)
    } catch (error) {
      console.error('Failed to getUploadExcelData: ', error)

      dispatch(slice.actions.hasError(error)) // * Set error
      dispatch(slice.actions.uploadProgress(0)) // * Reset progress in case of error
    }
  }
}

export const getUploadSingleFile = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  File,
  // Thunk API type
  { rejectValue: string } // This specifies that the error payload is a string
>('upload/getUploadSingleFile', async (file, { signal, rejectWithValue }) => {
  dispatch(slice.actions.startLoading())

  const { uploadProgress } = store.getState().upload

  try {
    const reader = new FileReader()

    reader.onprogress = (e) => {
      if (uploadProgress < UPLOAD_SUCCESS && signal.aborted) {
        throw new Error('Stop the work, tis has been aborted!')
      }

      if (e.lengthComputable) {
        const percentCompleted = Math.round((e.loaded * 100) / e.total)

        dispatch(slice.actions.uploadProgress(percentCompleted))
      }
    }

    reader.onerror = (error) => {
      console.error('Error reading file: ', error)

      throw new Error('Error reading file')
    }

    reader.onload = () => {
      const base64 = reader.result

      localStorage.setItem(
        UPLOAD_ICON,
        JSON.stringify({ filename: file.name, data: base64 })
      )

      dispatch(
        slice.actions.getUploadDataSuccess({
          uploadData: base64,
        })
      )
    }

    reader.readAsDataURL(file)
  } catch (error) {
    dispatch(hasError(error))

    if (error instanceof Error) {
      return rejectWithValue(error.message)
    }

    return rejectWithValue(JSON.stringify(error))
  }
})
