export const filterItems = [
  {
    label: 'Email',
    nameCheckBox: 'emailChecked',
    nameInput: 'email',
    type: 'text',
  },
  {
    label: 'First Name',
    nameCheckBox: 'firstNameChecked',
    nameInput: 'firstName',
    type: 'text',
  },
  {
    label: 'Last Name',
    nameCheckBox: 'lastNameChecked',
    nameInput: 'lastName',
    type: 'text',
  },
]
