import { ApiStatus } from '../../../../constants/status'
import {
  callManagerList,
  callProvinceList,
} from '../../../../modules/Prospect/detail/features/Form/Assign/service/thunk'
import { AppDispatch } from '../../../../redux/store'
import { FormData } from '../../../CRUD/types/CrudState'
import { setIsLoading, setManagerGrid } from '../slices'
import { setProvinceList } from '../slices/provinceSlice'
import _ from 'lodash'

export const handleSearchClick =
  (params: FormData) => async (dispatch: AppDispatch) => {
    await dispatch(getManagerList(params))
  }

export const getProvinceList = () => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  const { payload } = await dispatch(callProvinceList())
  if (_.size(payload.result) > 0) {
    dispatch(setProvinceList(payload.result))
  }
  dispatch(setIsLoading(false))
}

export const getManagerList =
  (params: FormData) => async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true))
    const { payload } = await dispatch(callManagerList(params))
    if (payload.status === ApiStatus.SUCCESS) {
      dispatch(setManagerGrid(payload.result))
    }
    dispatch(setIsLoading(false))
  }
