import _ from 'lodash'

export const getBodyMutation = (data: any = {}) => {
  return {
    id: _.get(data, 'id', ''),
    uuid: _.get(data, 'uuid', undefined),
    email: _.get(data, 'email', ''),
    firstName: _.get(data, 'firstName', ''),
    lastName: _.get(data, 'lastName', ''),
    createdBy: _.get(data, 'createdBy', ''),
    updatedBy: _.get(data, 'updatedBy', ''),
    createdAt: _.get(data, 'createdAt', ''),
    updatedAt: _.get(data, 'updatedAt', ''),
  }
}
