export const headerCells: any = [
  {
    id: 'status',
    label: 'status',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '90px',
  },
  {
    id: 'name',
    label: 'เหตุผล',
  },
  {
    id: 'dropFromSystem',
    label: 'สถานะการละทิ้งโดยระบบ',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    editPath: '/reasons-drop-prospect-by-dc/form',
    hideDelete: false,
  },
]
