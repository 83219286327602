import { ManagerListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const pageConfig = {
  moduleId: 'MANAGER_QUEUE',
  moduleSlug: 'manager-queue',
  moduleTitleFull: 'Manager Queue',
  moduleTitleSecond: 'Manager Queue',
  modulesTitleShort: 'Manager Queue',
  modulePath: '/manager-queue',
  filterPath: ManagerListFilterPath,
  headerCells: headerCells,
}
