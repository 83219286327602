import _ from 'lodash'
import { SliceModel } from '../../../redux/models'
import { shallowEqual, useSelector } from 'react-redux'

export const useInputField = (FIELD_NAME: string) => {
  const { value, error, formData } = useSelector(
    (state: SliceModel) => ({
      value: state.crud.formData[FIELD_NAME],
      formData: state.crud.formData,
      error: _.find(state.crud.formErrors, { path: FIELD_NAME }),
    }),
    shallowEqual
  )

  let thisValue = null
  if (!value) {
    thisValue = _.get(formData, FIELD_NAME, null)
  }

  const isError = !_.isEmpty(error)
  return {
    value: thisValue ? thisValue : value,
    message: isError && error.message,
    isError,
  }
}
export const getCrudBy = (path: string) => {
  const { data } = useSelector(
    (state: SliceModel) => ({
      data: _.get(state.crud, path, null),
    }),
    shallowEqual
  )

  return data
}
export const getFormIsLoading = () => {
  const isloading = getCrudBy('isLoading')
  return !isloading || isloading === null ? false : true
}
