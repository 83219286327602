import { crudConfig } from '../../../crudConfig'
import { getModuleTitle } from '../../../utils/getModuleTitle'

export const breadcrumb = (uuid: string) => [
  { title: 'Manage', link: '/', pointer: false },
  {
    title: crudConfig.moduleTitleSecond,
    link: crudConfig.modulePath,
    pointer: true,
  },
  {
    title: getModuleTitle(uuid, crudConfig.modulesTitleShort),
    link: `${crudConfig.modulePath}/form`,
    pointer: false,
  },
]
