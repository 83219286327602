export enum DateFormat {
  DDMMYYYY = 'DD/MM/YYYY',
  DDMM = 'DD/MM',
  YYYYMMDD = 'YYYY-MM-DD',
  YYYYMM = 'YYYY-MM',
  MMYYYY = 'MM/YYYY',
  MMYY = 'MM/YY',
  YYYY = 'YYYY',
  MM = 'MM',
  _YYYYMMDD = 'YYYYMMDD',
  HHmm = 'HH:mm',
  DDMMYYYYHHmm = 'DD/MM/YYYY HH:mm',
  DDMMYYYYHHmmss = 'DD/MM/YYYY HH:mm:ss',
  HHmmss = 'HH:mm:ss',
  YYYYMMDDHHMMSSms = 'YYYY-MM-DD HH:mm:ss.ms',
  YYYYMMDDHHMMSSSSS = 'YYYY-MM-DD HH:mm:ss.SSS',
  _DDMMYYYY = 'DDMMYYYY',
}
