import { LoadingCircular, StyledLoadingBg } from '../../Styled'

const LoadingPageCircular: React.FC<{ isLoading: boolean }> = ({
  isLoading,
}) => {
  return (
    <StyledLoadingBg isLoading={isLoading}>
      <LoadingCircular isLoading />
    </StyledLoadingBg>
  )
}

export default LoadingPageCircular
