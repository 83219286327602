import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'STAFF_PROFILE',
  moduleSlug: 'staff-profile',
  moduleTitleFull: 'ข้อมูลพนักงาน (Staff Profile)',
  moduleTitleSecond: 'ข้อมูลพนักงาน',
  modulesTitleShort: 'ข้อมูลพนักงาน',
  modulePath: '/manage/staff-profile',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
