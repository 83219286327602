export function base64toFile(base64: string, filename: string): File | null {
  if (!base64) {
    return null
  }

  const [metadata, data] = base64.split(',')
  const mimeString = metadata.split(':')[1].split(';')[0]
  const byteString = atob(data)
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const unit8Array = new Uint8Array(arrayBuffer)

  for (let i = 0; i < byteString.length; i++) {
    unit8Array[i] = byteString.charCodeAt(i)
  }

  return new File([arrayBuffer], filename, { type: mimeString })
}
