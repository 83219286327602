import { Box, Skeleton, Typography } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import CustomButton from '../../../../../../components/Input/CustomButton'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { META } from '../../../../../../constants/webMeta'
import { SliceModel } from '../../../../../../redux/models'
import EastIcon from '@mui/icons-material/East'

export function FullForm() {
  const { isLoading } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )
  return (
    <CustomCard
      isLoading={isLoading}
      skeleton={<BoxSkeleton />}
      sx={{ height: '145px' }}
    >
      <Box display={'flex'} mb={1}>
        <Box
          width={'30px'}
          height={'30px'}
          bgcolor={'primary.main'}
          borderRadius={'50%'}
          position={'relative'}
          mr={1}
        >
          <img
            alt={META.ALT}
            src={`${process.env.PUBLIC_URL}/images/home/step/2/done.svg`}
            style={{
              position: 'absolute',
              display: 'block',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '100%',
              padding: '15%',
            }}
          />
        </Box>
        <Typography variant="body1b">สถานะการกรอกใบสมัคร</Typography>
      </Box>
      <Typography mb={1} variant="body2">
        คุณสามารถเข้าไปดูเอกสารการสมัครได้
      </Typography>
      <CustomButton sx={{ mt: 1 }} size={'small'} variant={'outlined'}>
        เปิดดูใบสมัคร <EastIcon />
      </CustomButton>
    </CustomCard>
  )
}
const BoxSkeleton = () => {
  return (
    <>
      <Box display={'flex'} mb={1}>
        <Box
          width={'30px'}
          height={'30px'}
          bgcolor={'#f1f1f1'}
          borderRadius={'50%'}
          position={'relative'}
          mr={1}
        ></Box>
        <Typography variant="body1b">
          <Skeleton width={'150px'} />
        </Typography>
      </Box>
      <Typography mb={1} variant="body2">
        <Skeleton width={'200px'} />
      </Typography>
      <Box>
        <Skeleton
          width={'120px'}
          sx={{ height: '55px', borderRadius: '15px' }}
        />
      </Box>
    </>
  )
}
