import React, { Suspense, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import RoutePage from './routes/Route'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './styles/themes/theme'
import { Provider as ReduxProvider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Box, CircularProgress, Typography } from '@mui/material'
import CustomerAlertDialog from './components/CustomerAlertDialog'
import Keycloak from './keyCloak'
import OneAccount from './OneAccount'
import {
  AuthProvider,
  useAuth,
  type AuthProviderProps,
} from 'react-oidc-context'
import { useAuthGuard } from './hooks/useAuthGuard'
import { DialogContextProvider } from './context/dialog-context'
import PopUpDialog from './components/PopUpDialog'
// import { callAPI } from './utils/callAPI'
import _ from 'lodash'

type CustomUserType = {
  access_token: string
  refresh_token?: string
  token_type: string
}

const App: React.FC = () => {
  const auth = useAuth()

  useAuthGuard()

  if (auth.isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: '#FFFFFF',
          zIndex: '99999',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ mt: 2 }}>กรุณารอสักครู่</Typography>
      </Box>
    )
  }

  return <RoutePage />
}

const DialogProviders = () => {
  const auth = useAuth()
  const user = JSON.parse(localStorage.getItem('user')!)

  useEffect(() => {
    return auth.events.addUserLoaded(async (authorization: CustomUserType) => {
      if (!_.isEmpty(authorization?.access_token) && _.isEmpty(user)) {
        const headers = {
          'Content-Type': 'application/json',
          Pragma: 'no-cache',
          Authorization: `${authorization.token_type} ${authorization.access_token}`,
        }

        localStorage.setItem('refresh_token', authorization?.refresh_token || '')
        localStorage.setItem(
          'token',
          `${authorization.token_type} ${authorization?.access_token}`
        )

        // alert('1')
        // callAPI({
        //   url: 'staffprofile',
        //   method: 'get',
        //   onSuccess: (res) => {
        //     setUser(res)
        //     setLoadingProfile(false)
        //   },
        //   onError: () => {
        //     setDialog({
        //       open: true,
        //       title: dialog.title,
        //       content: dialog.content,
        //       variant: dialog.variant,
        //       onConfirmClick: dialog.onConfirmClick,
        //     })
        //   },
        // })
    
      }
    })
  }, [auth.events, user])

  
  return (
    <Suspense fallback={<CircularProgress size={30} />}>
      <Router>
        <App />
      </Router>
      <CustomerAlertDialog />
      <PopUpDialog />
    </Suspense>
  )
}

const DialogContextProviders = () => {
  return <DialogProviders />
}

const getKey = (): AuthProviderProps => {
  if (window.__env__.ENV === 'DEV') {
    return Keycloak
  }
  return OneAccount
}

export const Root = () => {
  return (
    <AuthProvider {...getKey()}>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <PersistGate loading={null} persistor={persistor}>
            <DialogContextProvider>
              <DialogContextProviders />
            </DialogContextProvider>
          </PersistGate>
        </ThemeProvider>
      </ReduxProvider>
    </AuthProvider>
  )
}
export default App
