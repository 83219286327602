import camelCase from 'lodash/camelCase'

// * Function to convert keys to camel-case
export function toCamelCase(obj: Record<string, unknown>) {
  const newObj: Record<string, unknown> = {}

  Object.keys(obj).forEach((key) => {
    const newKey = camelCase(key)

    newObj[newKey] = obj[key]
  })

  return newObj
}
