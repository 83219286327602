import { Box, CircularProgress, Typography } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'
import { themeColor } from '../../../../../../styles/themes/themeData'
import UpdateIcon from '@mui/icons-material/Update'

export function TimerReject() {
  const { isLoading } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )
  return (
    <CustomCard isLoading={isLoading} sx={{ height: '145px' }}>
      <Box mb={1}>
        <Typography variant="body1b">ระยะเวลาในการดำเนินการ</Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Box
          sx={{
            width: 'auto',
            p: 1,
            pb: 0,
            borderRadius: 2,
            backgroundColor: themeColor.grey.grey100,
            position: 'relative',
          }}
          alignItems={'center'}
          textAlign={'center'}
        >
          <CircularProgress variant="determinate" value={0} />
          <UpdateIcon
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          />
        </Box>
        <Box>
          <Typography sx={{ mb: 1, color: 'text.gray' }} variant="body2">
            เวลาที่เหลือ
          </Typography>

          <Typography variant="h6">-</Typography>
        </Box>
      </Box>
    </CustomCard>
  )
}
