import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import Divider from '@mui/material/Divider'
import { InputDateGroup } from './InputDateGroup'

export const BasicInfo = () => {
  return (
    <>
      <CustomInput
        required
        labelName="ชื่ออีเว้นท์"
        fieldName="name"
        placeholder="ระบุชื่ออีเว้นท์"
      />
      <CustomInput
        required
        labelName="รายละเอียดของอีเว้นท์"
        fieldName="description"
        placeholder="ระบุรายละเอียดของอีเว้นท์"
      />
      <CustomInput
        required
        labelName="สถานที่ของอีเว้นท์"
        fieldName="location"
        placeholder="ระบุรายสถานที่ของอีเว้นท์"
      />
      <InputDateGroup />
      <CustomSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
    </>
  )
}
