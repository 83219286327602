import _ from 'lodash'
import BasicInfo from './components/basicInfo'
import CHistoryDrawer from '../../../../../components/CRUD/components/History'
import Header from './HeaderForm'
import { CPageContainer } from '../../../../../components/CRUD/components/pageContainer'
import { crudConfig as initialCrudConfig } from '../../crudConfig'
import { fetchDetail } from './handler/fetchDetail'
import { getBodyMutation } from './mutation/get'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'
import { setInitialValues } from './handler/setInitialValues'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from '../../../../../redux/store'
import { selectCrudConfig } from '../../../../../redux/selector'

export default function SocialSubSourceForm() {
  const { uuid } = useParams()
  const crudConfig = useSelector(selectCrudConfig)

  const initializeCrud = () => {
    initialConfig(initialCrudConfig)
    if (uuid && !_.isUndefined(crudConfig)) {
      fetchDetail(uuid, getBodyMutation)
    } else {
      setInitialValues()
    }
  }

  useEffect(initializeCrud, [crudConfig, uuid])

  if (_.isUndefined(crudConfig)) {
    return <></>
  }

  return (
    <>
      <CPageContainer
        gap={3}
        header={<Header uuid={uuid} crudConfig={crudConfig} />}
        content={<BasicInfo />}
      />
      <CHistoryDrawer />
    </>
  )
}
