import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import { breadcrumbs } from './model/breadcrumbs'

export default function Header() {
  return (
    <Box width={1}>
      <Typography variant="h4">Manager Queue</Typography>
      <Breadcrumbs menuList={breadcrumbs()} />
    </Box>
  )
}
