import { openDialog } from '../../../components/dialog/slices'
import { store } from '../../../redux/store'
import { handleProcessDelete } from './handleProcessDelete'

const DIALOG_TITLE = 'ยืนยันลบข้อมูล'
const DIALOG_MESSAGE = 'คุณต้องการลบรายการนี้ใช่หรือไม่'

function confirmDialog(uuid: string, mutatePropToBody: any): void {
  store.dispatch(
    openDialog({
      type: 'delete',
      title: DIALOG_TITLE,
      message: DIALOG_MESSAGE,
      handleConfirm: () => {
        handleProcessDelete(uuid, mutatePropToBody)
      },
      isCloseDialog: false,
    })
  )
}

export const handleDeleteClick = async (
  uuid: string,
  mutatePropToBody: any
): Promise<void> => {
  confirmDialog(uuid, mutatePropToBody)
}
