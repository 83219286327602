import { useState, type SyntheticEvent, useMemo } from 'react'
import Stack, { type StackProps } from '@mui/material/Stack'
import styled from '@mui/material/styles/styled'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell, { type TableCellProps } from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import useTheme from '@mui/material/styles/useTheme'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { useSelector } from '../../../redux/store'
import { tableResultHeader, TABLE_COL_ERR_INDEX } from './model/table-result'
import { STATUS_TEXT } from '../../../constants/ma'

const BoxStatusStyle = styled(Stack, {
  shouldForwardProp: (props) => props !== 'isError',
})<StackProps & { isError?: boolean }>(({ theme, isError }) => ({
  width: '100%',
  height: 72,
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: `${theme.palette.text.success}14`,
  padding: theme.spacing(2),

  ...(isError && {
    backgroundColor: `${theme.palette.text.error}14`,
    borderRadius: '8px',
  }),
}))

const TableCellStyle = styled(TableCell, {
  shouldForwardProp: (props) =>
    props !== 'isLastElem' && props !== 'successColor',
})<TableCellProps & { isLastElem?: boolean; successColor?: string }>(
  ({ theme, isLastElem, successColor }) => ({
    verticalAlign: 'middle !important',
    borderRight: !isLastElem
      ? `1px solid ${theme.palette.blue.blueLine}`
      : 'none',

    ...(successColor && {
      backgroundColor: `${successColor}14`,
    }),
  })
)

const TooltipStyle = styled(Tooltip)({
  '& .MuiTooltip-tooltipPlacementBottom': {
    fontSize: 24,
    whiteSpace: 'pre-line',
  },
})

export default function CheckInfoResult() {
  const { validatedData, data } = useSelector((state) => state.upload)

  const handleCountError = useMemo(() => {
    const countError = (
      validatedData as TableResultProps['validatedData']
    )?.reduce(
      (acc, curr) => (curr.error?.find((item) => !!item) ? acc + 1 : acc),
      0
    )

    return countError
  }, [validatedData])

  if (!validatedData?.length) {
    return null
  }

  return (
    <Stack width={1} gap={2}>
      <BoxStatusStyle isError={!!handleCountError}>
        {!handleCountError ? (
          <>
            <CheckCircleIcon color="success" />
            <Typography variant="body1">
              ไฟล์ของคุณพร้อมสำหรับขั้นตอนนำเข้าแล้ว
            </Typography>
          </>
        ) : (
          <>
            <ErrorIcon color="error" />
            <Typography variant="body1">
              พบข้อมูลมีปัญหา
              <Typography variant="body1b" mx={0.5}>
                {handleCountError}
              </Typography>
              รายการ
            </Typography>
          </>
        )}
      </BoxStatusStyle>

      {/* Table result */}
      {data.uploadData.length === validatedData.length && ( // * Show the table result when the upload data is validated as complete
        <TableResult validatedData={validatedData} />
      )}
    </Stack>
  )
}

export type TableResultProps = {
  validatedData: {
    seqNo: number
    agentCode: string
    managerName: string
    workingArea: string
    areaQueue: number
    province: string
    provinceQueue: number
    error: string[]
    status: string
  }[]
}

function TableResult({ validatedData }: Readonly<TableResultProps>) {
  const theme = useTheme()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [filteredData, setFilteredData] = useState(validatedData)

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeFilter = (_event: SyntheticEvent, checked: boolean) => {
    const filterErrors = (data: typeof validatedData) =>
      data.filter((item) => item.error?.some(Boolean))

    setFilteredData(checked ? filterErrors : validatedData)
  }

  return (
    <>
      {/* SOME ITEM FROM THE IMPORT DATA IS AN ERROR */}
      {validatedData.some((item) => item.error?.find((err) => !!err)) && (
        <FormControlLabel
          control={<Switch />}
          label="แสดงเฉพาะรายการที่ไม่สำเร็จ"
          onChange={handleChangeFilter}
        />
      )}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="import-manager-result">
          <TableHead>
            <TableRow>
              {tableResultHeader.map((label, index) => (
                <TableCellStyle
                  key={label}
                  isLastElem={index === tableResultHeader.length - 1}
                  align={label === STATUS_TEXT ? 'center' : 'left'}
                >
                  {label}
                </TableCellStyle>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? filteredData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredData
            ).map((item) => (
              <TableRow
                key={`${item.seqNo}-${item.agentCode}`}
                sx={{
                  backgroundColor: item.error?.find((err) => !!err)
                    ? `${theme.palette.text.error}14`
                    : 'none',
                }}
              >
                <TableCellStyle
                  component="th"
                  scope="row"
                  align="center"
                  successColor={getSuccessColor(
                    item.error,
                    theme.palette.text.success
                  )}
                  sx={{ verticalAlign: 'bottom' }}
                >
                  {item.error?.find((err) => !!err) ? (
                    <ErrorIcon color="error" fontSize="small" />
                  ) : (
                    <CheckCircleIcon color="primary" fontSize="small" />
                  )}
                </TableCellStyle>

                <TableCellStyle>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    whiteSpace="pre-line"
                  >
                    {item.seqNo}

                    {renderErrorField(item.error, TABLE_COL_ERR_INDEX.seqNo)}
                  </Stack>
                </TableCellStyle>
                <TableCellStyle>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    whiteSpace="pre-line"
                  >
                    {item.agentCode}

                    {renderErrorField(
                      item.error,
                      TABLE_COL_ERR_INDEX.agentCode
                    )}
                  </Stack>
                </TableCellStyle>
                <TableCellStyle>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {item.managerName}

                    {renderErrorField(
                      item.error,
                      TABLE_COL_ERR_INDEX.managerName
                    )}
                  </Stack>
                </TableCellStyle>
                <TableCellStyle
                  successColor={getSuccessColor(
                    item?.error,
                    theme.palette.secondary.main
                  )}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {item.workingArea}

                    {renderErrorField(
                      item.error,
                      TABLE_COL_ERR_INDEX.workingArea
                    )}
                  </Stack>
                </TableCellStyle>
                <TableCellStyle
                  successColor={getSuccessColor(
                    item?.error,
                    theme.palette.success.main
                  )}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {item.areaQueue}

                    {renderErrorField(
                      item.error,
                      TABLE_COL_ERR_INDEX.areaQueue
                    )}
                  </Stack>
                </TableCellStyle>
                <TableCellStyle
                  successColor={getSuccessColor(
                    item?.error,
                    theme.palette.blue.oceanBlue
                  )}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {item.province}

                    {renderErrorField(item.error, TABLE_COL_ERR_INDEX.province)}
                  </Stack>
                </TableCellStyle>
                <TableCellStyle
                  isLastElem
                  successColor={getSuccessColor(
                    item?.error,
                    theme.palette.blue.oceanBlue
                  )}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {item.provinceQueue}

                    {renderErrorField(
                      item.error,
                      TABLE_COL_ERR_INDEX.provinceQueue
                    )}
                  </Stack>
                </TableCellStyle>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* TABLE PAGINATION */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        component="div"
        count={validatedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="แถวต่อหน้า:"
        slotProps={{
          select: {
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          },
        }}
        sx={{
          '&.MuiTablePagination-root .MuiTablePagination-selectIcon': {
            top: 5,
          },
        }}
      />
    </>
  )
}

function renderErrorField(errorItems: string[], colNumber: number) {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {errorItems?.[colNumber] && (
        <TooltipStyle
          title={
            <Typography variant="body2">{errorItems?.[colNumber]}</Typography>
          }
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'primary',
                color: 'common.white',

                '& .MuiTooltip-arrow': {
                  color: 'primary',
                },
              },
            },
          }}
        >
          <IconButton>
            <ErrorIcon color="error" fontSize="small" />
          </IconButton>
        </TooltipStyle>
      )}
    </Stack>
  )
}

function getSuccessColor(error: string[], color: string) {
  return error?.find((err) => !err) ? color : ''
}
