import React from 'react'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { AppDispatch, store } from '../../redux/store'
import Link from '@mui/material/Link'

import _ from 'lodash'

import Dot from './Dot'
import Truncate from './Truncate'
import Action from './Action'

import {
  setDefaultSort,
  setOrder,
  setChangeRowsPerPage,
  setChangePage,
  setSelected,
} from './redux'
import {
  setDefaultSort as setBasicDefaultSort,
  setOrder as setBasicOrder,
  setChangeRowsPerPage as setBasicChangeRowsPerPage,
  setChangePage as setBasicChangePage,
} from './redux/basicTable'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import { formatPhoneNo } from '../../utils/formatPhoneNo'
import { getFormatDate } from '../../utils/getFormatDate'
import { sendShowEnum } from './../CRUD/enum/sendEvent'
import type { TableState } from './redux/types/initialStateTable'
import { STATUS_DISPLAY_TEXT } from '../../constants/status'

export type HeadCell = {
  id: string
  custom?: boolean
  customWidth?: number
  minWidth?: number
  hideSortIcon?: boolean
}

export type RowType = {
  uuid: string
  [key: string]: any // Adjust according to the actual row structure
}

type TableType = {
  defaultSortId?: string
  allCount: number
  page: number
  rows: Omit<RowType, 'uuid'>[]
  rowsPerPage: { value: number }
  tablePage: number
  defaultSort?: { state: number; id?: string }
  sort?: string
  order?: string
}

export const checkConditionView = (
  headCells: HeadCell[],
  row: RowType,
  rowIndex: number,
  cellIndex: number
) => {
  const columnKey = headCells[cellIndex].id
  const colCodeId = columnKey === 'codeId' ? 130 : 90
  const minWidth = columnKey === 'name' ? 100 : colCodeId
  const sx = GetSxStyle(row, minWidth)

  const isCustom = _.get(headCells, `[${cellIndex}].custom`, false)
  const maxCustomWidth = _.get(headCells, `[${cellIndex}].customWidth`, 10)
  const minCustomWidth = _.get(headCells, 40)

  const getColorStyles = () => {
    if (columnKey === 'workingArea' || columnKey === 'areaQueue') {
      return { backgroundColor: 'rgba(240, 118, 98, 0.08)' }
    } else if (columnKey === 'province' || columnKey === 'provinceQueue') {
      return { backgroundColor: 'rgba(73, 118, 186, 0.08)' }
    }
    return {}
  }

  const style = { ...GetSxStyle(row, minWidth), ...getColorStyles() }

  if (columnKey === 'status') {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }} align="center">
        <Dot status={row[`${columnKey}`]} />
      </TableCell>
    )
  } else if (columnKey === 'send' || columnKey === 'dropFromSystem') {
    return (
      <TableCell key={columnKey} align="left">
        {sendShowEnum[row[`${columnKey}`] as keyof typeof sendShowEnum]}
      </TableCell>
    )
  } else if (columnKey === 'prospectStatus') {
    return (
      <TableCell key={columnKey} align="left">
        {STATUS_DISPLAY_TEXT[row.status as keyof typeof STATUS_DISPLAY_TEXT]}
      </TableCell>
    )
  } else if (columnKey === 'action') {
    return (
      <TableCell key={columnKey}>
        <Action
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'id') {
    return (
      <TableCell key={columnKey} sx={{ minWidth: 10 }}>
        {row[`${columnKey}`]}
      </TableCell>
    )
  } else if (columnKey === 'mobileNo') {
    return (
      <TableCell key={columnKey} sx={sx}>
        {formatPhoneNo(row[columnKey])}
      </TableCell>
    )
  } else if (
    columnKey === 'workingArea' ||
    columnKey === 'areaQueue' ||
    columnKey === 'province' ||
    columnKey === 'provinceQueue'
  ) {
    return (
      <TableCell key={columnKey} sx={style}>
        {row[columnKey]}
      </TableCell>
    )
  } else if (columnKey === 'startDate' || columnKey === 'expiryDate') {
    const dateFormat = window.__env__.REACT_APP_DATE_SHOW || 'DD/MM/YYYY'

    return (
      <TableCell key={columnKey} sx={sx}>
        {getFormatDate(row[columnKey], dateFormat)}
      </TableCell>
    )
  } else if (isCustom) {
    return (
      <TableCell
        key={columnKey}
        sx={{
          minWidth: minCustomWidth,
          maxWidth: maxCustomWidth,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {row[columnKey]}
      </TableCell>
    )
  } else if (columnKey === 'name') {
    const { href, text } = row[columnKey]
    return (
      <TableCell key={columnKey} sx={sx}>
        {href ? (
          <Link href={href} underline="none">
            {text}
          </Link>
        ) : (
          row[columnKey]
        )}
      </TableCell>
    )
  }

  return (
    <TableCell key={columnKey} sx={sx}>
      {_.get(row, `${columnKey}`, '') && (
        <Truncate>{'' + _.get(row, `${columnKey}`, '-')}</Truncate>
      )}
    </TableCell>
  )
}

export const GetSxStyle = (row: RowType, minWidth: number) => {
  const status = _.get(row, 'status', '')

  if (status === 'DELETED' || status === 'INACTIVE') {
    return { minWidth, color: 'text.gray' }
  }
  if (
    status === 'DELETED' ||
    status === 'CANCELED' ||
    status === 'STEAL' ||
    status === 'TERMINATED' ||
    status === 'INACTIVE'
  ) {
    return { minWidth, color: 'text.gray' }
  } else {
    return { minWidth }
  }
}

type MapResultProps = {
  columnKey: string
  sx: object
  row: RowType
}

export const MapResult: React.FC<MapResultProps> = ({ columnKey, sx, row }) => {
  const label = _.get(row, `${columnKey}`, '-')
  if (label === 'ผ่าน') {
    return (
      <TableCell key={columnKey} sx={sx}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Check color="success" sx={{ marginRight: 1 }} fontSize="small" />
          <Typography variant="body2">
            {'' + _.get(row, `${columnKey}`, '-')}
          </Typography>
        </Box>
      </TableCell>
    )
  } else {
    return (
      <TableCell key={columnKey} sx={sx}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Close color="error" sx={{ marginRight: 1 }} fontSize="small" />
          <Typography variant="body2">
            {'' + _.get(row, `${columnKey}`, '-')}
          </Typography>
        </Box>
      </TableCell>
    )
  }
}

export const handleClick =
  (selected: string[], name: string) => (dispatch: typeof store.dispatch) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: string[] = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    dispatch(setSelected(newSelected))
  }

export const isSelected = (selected: string[], name: string) =>
  selected.indexOf(name) !== -1

export const handleLabelDisplay = (table: TableType) => {
  const realPage = table.tablePage <= 0 ? 1 : table.tablePage + 1
  let minRowsShowed =
    table.page < 0 ? 1 : table.rowsPerPage.value * table.tablePage + 1
  let maxRowsShowed = 0

  if (table.rowsPerPage.value * realPage > table.allCount) {
    maxRowsShowed = table.allCount
  } else {
    if (realPage === 1) {
      maxRowsShowed = table.rows.length + realPage - 1
    } else {
      maxRowsShowed = table.rows.length * realPage
    }
  }

  if (maxRowsShowed === 0) {
    minRowsShowed = 0
  } else if (maxRowsShowed < 0) {
    maxRowsShowed = table.allCount
  }

  if (minRowsShowed > maxRowsShowed && realPage > 1) minRowsShowed = 1 // fix bug - rows label

  return (
    <Typography variant="caption" color="text.secondary">
      {minRowsShowed}-{maxRowsShowed} of {table.allCount}
    </Typography>
  )
}

export const handleChangePage =
  (newPage: number, isBasicTable: boolean): any =>
  (dispatch: AppDispatch) => {
    const tablePage = newPage < 0 ? 1 : +newPage
    const page = newPage + 1
    if (isBasicTable) {
      dispatch(setBasicChangePage({ page, tablePage }))
    } else dispatch(setChangePage({ page, tablePage }))
  }

export const handleChangeRowsPerPage =
  (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isBasicTable: boolean
  ): any =>
  (dispatch: AppDispatch) => {
    const value = parseInt(event.target.value, 10)
    const rowsPerPageChange = value > 0 ? value : -1
    const rowsPerPage: { label: string | number; value: number } = {
      label: rowsPerPageChange === -1 ? 'All' : `${rowsPerPageChange}`,
      value: rowsPerPageChange,
    }

    const limit = value

    if (isBasicTable) {
      dispatch(setBasicChangeRowsPerPage({ rowsPerPage, limit: `${limit}` }))
    } else {
      dispatch(setChangeRowsPerPage({ rowsPerPage, limit }))
    }
  }

export const handleSelectAllClick =
  (
    event: React.ChangeEvent<HTMLInputElement>,
    rows: Array<{ uuid: string }>
  ): any =>
  (dispatch: AppDispatch) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.uuid)
      dispatch(setSelected(newSelecteds))
    } else {
      dispatch(setSelected([]))
    }
  }

export function handleRequestSort(
  sortBy: string,
  table: TableState,
  headCell: { [key: string]: any },
  isBasicTable: boolean
): any {
  return (dispatch: AppDispatch) => {
    if (headCell.hideSortIcon) return

    const defaultSort = _.get(table, 'defaultSort', undefined)
    const defaultSortId = _.get(table, 'defaultSortId', 'updatedAt')
    const sort = _.get(table, 'sort', 'updatedAt')
    const order = _.get(table, 'order', 'desc')

    if (defaultSort) {
      const isAsc = sort === sortBy && order === 'asc'
      let stateSort = defaultSort.state + 1
      let activeSort = true

      if (defaultSort.state >= 2) {
        stateSort = 0
        activeSort = false
      }
      let valueSort = !defaultSort.id ? sortBy : defaultSort.id
      if (!!defaultSort.id && defaultSort.id !== sortBy) {
        stateSort = 1
        valueSort = sortBy
        activeSort = true
      }

      let orderBy: 'desc' | 'asc' = isAsc ? 'desc' : 'asc'
      if (!activeSort) {
        sortBy = defaultSortId
        orderBy = 'desc'
      }

      if (isBasicTable) {
        dispatch(
          setBasicDefaultSort({
            state: stateSort,
            id: valueSort,
            active: activeSort,
          })
        )

        dispatch(setBasicOrder({ order: orderBy, sort: sortBy }))
      } else {
        dispatch(
          setDefaultSort({
            state: stateSort,
            id: valueSort,
            active: activeSort,
          })
        )

        dispatch(setOrder({ order: orderBy, sort: sortBy }))
      }
    }
  }
}
