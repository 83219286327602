import { setHandleChange } from '../../Table/redux/index'
import { store } from '../../../redux/store'
import _ from 'lodash'

interface Filter {
  [key: string]: any
}

interface HandleChangeParams {
  key: string
  value: any
  filter: Filter
  listKey?: string
}

export const handleChange = ({
  key,
  value,
  filter,
  listKey,
}: HandleChangeParams): void => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  store.dispatch(setHandleChange({ key: 'filterState', value: objState }))
}
