import {
  LazyLoadImage,
  type Effect,
  type LazyLoadImageProps,
} from 'react-lazy-load-image-component'
import Box, { type BoxProps } from '@mui/material/Box'
import type { SxProps, Theme } from '@mui/material/styles'

interface IProps
  extends Omit<
    LazyLoadImageProps,
    'wrapperClassName' | 'effect' | 'placeholderSrc'
  > {
  disabledEffect?: boolean
  effect?: Effect
  sx?: SxProps<Theme>
}

type Props = Omit<BoxProps, 'color'> & IProps

export default function Image({
  disabledEffect = false,
  effect = 'blur',
  sx,
  ...other
}: Readonly<Props>) {
  return (
    <Box
      component="span"
      sx={{
        lineHeight: 1,
        display: 'block',
        overflow: 'hidden',

        '& .wrapper': {
          width: 1,
          height: 1,
          backgroundSize: 'cover !important',
        },
        ...sx,
      }}
    >
      <Box
        component={LazyLoadImage}
        wrapperClassName="wrapper"
        effect={disabledEffect ? undefined : effect}
        placeholderSrc="/images/placeholder.svg"
        sx={{ width: 1, height: 1, objectFit: 'cover' }}
        {...other}
      />
    </Box>
  )
}
