import _ from 'lodash'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'

export const postBodyMutation = (data: any) => {
  const status = _.get(data, 'status', enumStatus.INACTIVE)

  return {
    uuid: _.get(data, 'uuid', undefined),
    id: _.get(data, 'id', ''),
    name: _.get(data, 'name', ''),
    status: status && status !== '' ? status : enumStatus.INACTIVE,
  }
}
