import { Dispatch } from 'redux'
import { resetPage, setSearchText } from '../../Table/redux/index'
import { fetchDataList } from './fetchDataList'
import { store } from '../../../redux/store'
import _ from 'lodash'

const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    name: _.get(filterProp, 'name', ''),
    description: _.get(filterProp, 'description', ''),
    location: _.get(filterProp, 'location', ''),
    dateStartDate: _.get(filterProp, 'dateStartDate', ''),
    dateExprityDate: _.get(filterProp, 'dateExprityDate', ''),
    status: _.get(filterProp, 'status', []),
    send: _.get(filterProp, 'send', []),
    dropFromSystem: _.get(filterProp, 'dropFromSystem', []),
    agentCode: _.get(filterProp, 'agentCode', ''),
    managerName: _.get(filterProp, 'managerName', ''),
    province: _.get(filterProp, 'province', ''),
    workingArea: _.get(filterProp, 'workingArea', ''),
  }
}

interface Table {
  page: number
}

export const handleSearchTable =
  (table: Table, keyword: string) => async (dispatch: Dispatch) => {
    dispatch(setSearchText(keyword))
    if (table.page === 1) {
      await fetchDataList(mutateFilterPropToBody)
    } else {
      dispatch(resetPage())
    }
  }
