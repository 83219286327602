import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'KNOW_US_FROM_CHANNEL',
  moduleSlug: 'know-us-from-channel',
  moduleTitleFull: 'รู้จักเราจากช่องทาง (Know Us from Channel)',
  moduleTitleSecond: 'รู้จักเราจากช่องทาง',
  modulesTitleShort: 'รู้จักจากช่องทาง',
  modulePath: '/know-us-from-channel',
  filterPath: `${CRUDListFilterPath}`,
  headerCells,
}
