import { createRoot } from 'react-dom/client'
import { Root } from './App'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

const root = createRoot(document.getElementById('root') as HTMLElement) // Type assertion

root.render(<Root />)
