import { ProspectDetail } from '../../type/types'

export interface ProspectDetailState {
  isLoading: boolean
  data: ProspectDetail | null
}
export const initialState: ProspectDetailState = {
  isLoading: false,
  data: null,
}
