import Button, { ButtonProps } from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { useSelector } from '../../../../../redux/store'
import { selectCrudConfig } from '../../../../../redux/selector'

export function ListHeaderAction(props: ButtonProps) {
  const crudConfig = useSelector(selectCrudConfig)

  return (
    <Button startIcon={<AddIcon />} variant="contained" onClick={props.onClick}>
      สร้าง {crudConfig?.modulesTitleShort}
    </Button>
  )
}
