import AdminPanelSettingsTwoTone from '@mui/icons-material/AdminPanelSettingsTwoTone'
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone'
import TableViewIcon from '@mui/icons-material/TableView'
import PersonIcon from '@mui/icons-material/Person'
import ManageAccountsTwoTone from '@mui/icons-material/ManageAccountsTwoTone'

export const checkColor = (status: boolean) => {
  return status ? 'primary.light' : 'unset'
}

interface MenuItemType {
  name: string
  icon: (active: boolean) => JSX.Element
  active: boolean
  path: string
  list: {
    name: string
    active: boolean
    path: string
  }[]
}

export function leftMenuList(): MenuItemType[] {
  const menu = [
    {
      name: 'Dashboard',
      icon: (active: boolean) => (
        <AssignmentTwoToneIcon
          fontSize="small"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/home',
      list: [],
    },
    {
      name: 'ผู้สมัคร',
      icon: (active: boolean) => (
        <PersonIcon fontSize="small" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/prospect',
      list: [
        {
          name: 'รายการผู้สมัคร',
          active: false,
          path: '/prospect',
        },
        {
          name: 'รายการผู้มุ่งหวัง',
          active: false,
          path: '/candidate',
        },
      ],
    },
    {
      name: 'Assign Rule',
      icon: (active: boolean) => (
        <TableViewIcon fontSize="small" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/assign-rule',
      list: [
        {
          name: 'Manager Queue',
          active: false,
          path: '/manager-queue',
        },
        {
          name: 'Nearby Area',
          active: false,
          path: '/nearby-area',
        },
      ],
    },
    {
      name: 'Master Data',
      icon: (active: boolean) => (
        <AdminPanelSettingsTwoTone
          fontSize="small"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/master-data',
      list: [
        {
          name: 'ประเภทเหตุุผลการปฎิเสธนัดสัมภาษณ์',
          active: false,
          path: '/reject-appointment',
        },
        {
          name: 'ประเภทเหตุุผลการปฎิเสธหลังสัมภาษณ์',
          active: false,
          path: '/reject-after-interview',
        },
        {
          name: 'รู้จักเราจากช่องทาง',
          active: false,
          path: '/know-us-from-channel',
        },
        {
          name: 'แหล่งย่อยทางสังคม',
          active: false,
          path: '/social-sub-source',
        },
        {
          name: 'แหล่งย่อยออฟไลน์',
          active: false,
          path: '/offline-sub-source',
        },
        {
          name: 'แหล่งย่อยอีเว้นท์',
          active: false,
          path: '/event-sub-source',
        },
        {
          name: 'เหตุผลร้องขอบันทึก',
          active: false,
          path: '/reason-request-memo',
        },
        {
          name: 'เหตุผลการละเว้นผู้มุ่งหวังโดย DC',
          active: false,
          path: '/reasons-drop-prospect-by-dc',
        },
        {
          name: 'โปรไฟล์ RAM',
          active: false,
          path: '/ram-profile',
        },
        {
          name: 'โปรไฟล์ SRAM',
          active: false,
          path: '/sram-profile',
        },
        {
          name: 'โปรไฟล์ CADTO',
          active: false,
          path: '/cadto-profile',
        },
      ],
    },
    {
      name: 'Manage',
      icon: (active: boolean) => (
        <ManageAccountsTwoTone
          fontSize="small"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/manage',
      list: [
        {
          name: 'ข้อมูลพนักงาน',
          active: false,
          path: '/manage/staff-profile',
        },
        {
          name: 'สิทธิ์การใช้งาน',
          active: false,
          path: '/manage/permission',
        },
      ],
    },
  ]

  return menu
}
