import Box from '@mui/material/Box'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'

export const CustomCircularProgress = (props: CircularProgressProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={() => ({
          color: '#FFFFFF',
        })}
        size={50}
        thickness={5}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={() => ({
          position: 'absolute',
          left: 0,
        })}
        size={50}
        value={props.value}
        thickness={5}
      />
    </Box>
  )
}
