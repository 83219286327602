import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useSelector, useDispatch } from '../../redux/store'
import { selectDialog } from '../../redux/selector'
import { closeDialog } from '../../components/dialog/slices'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import LoadingButton from '@mui/lab/LoadingButton'

type DialogType = {
  name: string
  type?: string
  icon?: React.ReactNode
  title: string
  content?: string
  disagreeText?: string
  agreeText: string
  color:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
}

const typeDialog: DialogType[] = [
  {
    name: 'select',
    type: 'confirm',
    icon: '',
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'ตกลง',
    color: 'primary',
  },
  {
    name: 'save',
    type: 'save',
    icon: '',
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'บันทึก',
    color: 'primary',
  },
  {
    name: 'delete',
    type: 'delete',
    icon: '',
    title: 'ยืนยันลบข้อมูล',
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'ลบ',
    color: 'error',
  },
  {
    name: 'success',
    type: 'success',
    icon: <CheckCircleOutlinedIcon color="primary" />,
    title: 'สำเร็จ',
    content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
    disagreeText: '',
    agreeText: 'ตกลง',
    color: 'primary',
  },
  {
    name: 'fail',
    type: 'fail',
    icon: <ErrorOutlineOutlinedIcon color="error" />,
    title: 'ผิดพลาด',
    content: 'ระบบขัดข้อง',
    disagreeText: '',
    agreeText: 'ตกลง',
    color: 'error',
  },
  {
    name: 'timeFail',
    type: 'timeFail',
    icon: <ErrorOutlineOutlinedIcon color="error" />,
    title: 'ผิดพลาด',
    content: 'เวลาผิดพลาด',
    disagreeText: '',
    agreeText: 'ตกลง',
    color: 'error',
  },
  {
    name: 'reject',
    type: 'reject',
    icon: '',
    title: 'ไม่อนุมัติ',
    content: 'คุณต้องการไม่อนุมัติรายการนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'ยืนยัน',
    color: 'primary',
  },
  {
    name: 'approve',
    type: 'approve',
    icon: '',
    title: 'อนุมัติ',
    content: 'คุณต้องการอนุมัติรายการนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'ยืนยัน',
    color: 'primary',
  },
  {
    name: 'cancel',
    type: 'cancel',
    icon: '',
    title: 'ยืนยันยกเลิกคำขอ',
    content: 'คุณต้องการยกเลิกคำขอรายการนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'บันทึก',
    color: 'error',
  },
  {
    name: '',
    type: '',
    icon: '',
    title: '',
    content: '',
    disagreeText: 'ยกเลิก',
    agreeText: 'ยืนยัน',
    color: 'primary',
  },
]

type CustomerAlertDialogProps = {
  isOpen?: boolean
  title?: string
  content?: string
  variant?: string
  onConfirmClick?: () => void
}

const CustomDialog: React.FC<CustomerAlertDialogProps> = () => {
  const dispatch = useDispatch()
  const {
    isOpen,
    title,
    message,
    type,
    onCancelClick,
    handleConfirm,
    isLoading,
    sxActions,
  } = useSelector(selectDialog)

  const handleClose = () => {
    dispatch(closeDialog())
  }

  const dialogType = typeDialog.find((item) => item.type === type)

  if (!dialogType || (!handleConfirm && !onCancelClick)) {
    // * !handleConfirm && !onCancelClick: fixed dialog is not close
    return null // or handle the case where dialogType is not found
  }

  return (
    <Box>
      <Dialog
        data-testid="alert-dialog"
        fullWidth
        maxWidth={'xs'}
        open={isOpen}
        onClose={() => onCancelClick?.()}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mx: 2,
            mt: 2,
            mb: 1,
          }}
        >
          {dialogType.icon}
          <Typography sx={{ ml: 1 }} variant="h6">
            {title || dialogType.title}
          </Typography>
        </Box>

        {dialogType.name === 'confirm' ? (
          message
        ) : (
          <Typography sx={{ mx: 3 }} variant="body1" whiteSpace="pre-line">
            {message}
          </Typography>
        )}
        <DialogActions sx={sxActions}>
          {dialogType.disagreeText && (
            <Button
              sx={{ mx: 1, mb: 1, minWidth: 100 }}
              variant="outlined"
              onClick={handleClose}
              color="primary"
            >
              {dialogType.disagreeText}
            </Button>
          )}
          <LoadingButton
            data-testid="btn-confirm-dialog"
            loading={isLoading}
            sx={{ mx: 1, mb: 1, minWidth: 100 }}
            variant="contained"
            onClick={() => handleConfirm?.()}
            loadingPosition="end"
            endIcon={<></>}
          >
            {dialogType.agreeText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default CustomDialog
