import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector, store } from '../../../redux/store'
import { selectTableSearch } from '../../../redux/selector'
import { setSearch } from '../redux/'
import { BoxSearch, SearchInput, StyledSearchIcon } from './styled'
import _ from 'lodash'
import { handleSearchTable } from '../../CRUD/handler/handleSeaerchTable'

const SearchCustom: React.FC = () => {
  const { table } = store.getState().table
  const dispatch = useDispatch()
  const { search } = useSelector(selectTableSearch)
  const [searchTable, setSearchTable] = useState(search.searchText || '')

  const debouncedDispatch = useMemo(
    () =>
      _.debounce((value: string) => {
        dispatch(setSearch(value))
        handleSearchTable(table, value)(dispatch)
      }, 300),
    [dispatch, table]
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchTable(value)
    debouncedDispatch(value)
  }

  return (
    <BoxSearch>
      <SearchInput
        data-testid="search-input"
        placeholder={search.placeholder}
        onChange={handleChange}
        value={searchTable}
        inputProps={{
          maxLength: search.maxLength,
        }}
      />
      <StyledSearchIcon data-testid="search-icon" color="primary" />
    </BoxSearch>
  )
}

export default SearchCustom
