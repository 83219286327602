import Box from '@mui/material/Box'
import Card, { CardProps } from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

interface CustomCardProps extends CardProps {
  isLoading?: boolean
  skeleton?: React.ReactNode
}

export const CustomCard: React.FC<CustomCardProps> = (props) => {
  const { isLoading, skeleton, children, sx, ...rest } = props

  return (
    <Card
      sx={{
        p: { xs: 2, sm: 3 },
        position: 'relative',
        ...sx,
      }}
      {...rest}
    >
      {isLoading ? (
        skeleton || (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress />
          </Box>
        )
      ) : (
        <Box>{children}</Box>
      )}
    </Card>
  )
}
