import { store } from '../../../../../../redux/store'
import { setFieldValue } from '../../../../../../components/CRUD/slices'
import { getBodyMutation } from '../mutation/get'

export const setInitalValues = async () => {
  store.dispatch(setFieldValue({ key: 'formData', value: getBodyMutation() }))
  store.dispatch(
    setFieldValue({
      key: 'masterData',
      value: {},
    })
  )
}
