import styled from '@mui/material/styles/styled'
import Stack, { type StackProps } from '@mui/material/Stack'

export const WrapperInProgressStyle = styled(Stack, {
  shouldForwardProp: (props) => props !== 'isError',
})<StackProps & { isError: boolean }>(({ theme, isError }) => ({
  width: '100%',
  height: 224,
  borderRadius: '8px',
  backgroundColor: !isError
    ? `${theme.palette.info.main}14`
    : `${theme.palette.text.error}14`,
  border: `1px solid ${theme.palette.blue.blueLine}99`,
}))
