import { store } from '../../../../../../redux/store'
import get from 'lodash/get'

export function mutateFilterPropsToBody() {
  const { filterProp } = store.getState().table.filter
  const { sectionID } = store.getState().table.filterState

  return {
    name: get(filterProp, 'name', ''),
    text: get(filterProp, 'text', ''),
    sectionID,
    status: get(filterProp, 'status', []),
  }
}
