import * as yup from 'yup'
import { TABLE_COL_ERR_INDEX } from './table-result'

// import { agentCodeLen } from '../../../../constants/prospect'

export const importTemplateCol = [
  'seqNo',
  'firstName',
  'lastName',
  'email',
  'phone',
  'agentCode',
]

export const steps = ['อัปโหลดไฟล์', 'ตรวจสอบข้อมูล', 'นำเข้าข้อมูล']

// Regular expression for validating names
const nameRegex = /^[\u0E00-\u0E7F\p{L}\s]+$/u
export const importProspectSchema = yup.array().of(
  yup.object().shape({
    seqNo: yup
      .number()
      .positive('ลำดับต้องเป็นจำนานเต็มบวก')
      .min(1, 'กรุณาระบุ Seq No. ตั้งแต่ 1 ขึ้นไป')
      .required('กรุณาระบุ Seq No'),
    firstName: yup
      .string()
      .max(100)
      .matches(nameRegex, 'กรุณาระบุชื่อของผู้สมัครให้ถูกต้อง')
      .required('กรุณาระบุชื่อของผู้สมัคร'),
    lastName: yup
      .string()
      .max(100)
      .matches(nameRegex, 'กรุณาระบุนามสกุลของผู้สมัครให้ถูกต้อง')
      .required('กรุณาระบุนามสกุลของผู้สมัคร'),
    phone: yup
      .string()
      .required('กรุณาระบุหมายเลขโทรศัพท์')
      .test('checkPhone', 'กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง', (value) => {
        if (!value) return true

        return /^(0[689])(\d{8})$/.test(value)
      }),
  })
)

export const extractNumberFromPath = /\[(\d+)\]/

export type IValidationError = {
  seqNo: number
  message: string
  col: TABLE_COL_ERR_INDEX
}
