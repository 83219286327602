import { useDispatch, useSelector } from '../../../redux/store'
import { selectCrudConfig, selectFormDataStatus } from '../../../redux/selector'
import { memo, useMemo, useCallback, useEffect, useState } from 'react'
import TextInput from '../../Input/TextInput'
import { useInputField } from '../hooks/useInputField'
import { createDebouncedSetField } from '../handler/createDebouncedSetField'
import type { SxProps, Theme } from '@mui/material/styles'

interface CustomInputProps {
  required?: boolean
  disabled?: boolean
  fieldName: string
  labelName: string
  hideLabel?: boolean
  textInfo?: string
  placeholder?: string
  boxSx?: SxProps<Theme>
  inputProps?: object
}

const CustomInput: React.FC<CustomInputProps> = memo(
  ({
    required = false,
    disabled,
    fieldName,
    labelName,
    hideLabel = false,
    textInfo,
    placeholder,
    boxSx,
    inputProps,
  }) => {
    const dispatch = useDispatch()
    const { value, isError, message } = useInputField(fieldName)
    const crudConfig = useSelector(selectCrudConfig)

    const [inputValue, setInputValue] = useState(value)
    const formDataStatus = useSelector(selectFormDataStatus)

    const newPlaceholder = useMemo(() => {
      if (placeholder) {
        return placeholder
      }

      return `${required ? 'กรุณากรอก ' : ''}${labelName}`
    }, [labelName, placeholder, required])

    const debouncedSetField = useCallback(
      createDebouncedSetField(dispatch, fieldName),
      [dispatch, fieldName]
    )

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      setInputValue(newValue)
      debouncedSetField(newValue)
    }

    useEffect(() => {
      setInputValue(value)
    }, [value])

    const isDisabled = formDataStatus === 'DELETED' || disabled

    return (
      <TextInput
        disabled={isDisabled}
        required={required}
        name={fieldName}
        type="basic"
        labelText={hideLabel ? '' : labelName}
        placeholder={newPlaceholder}
        id={`${crudConfig?.moduleSlug}-${fieldName}`}
        value={inputValue ?? ''}
        textError={message}
        textInfo={textInfo}
        isShowTextError={isError}
        onChange={handleChange}
        boxSx={boxSx}
        inputProps={inputProps}
      />
    )
  }
)
CustomInput.displayName = 'InputName'
export { CustomInput }
