import { setProspectDetailIsLoading, setProspectDetail } from '../slices'
import { AppDispatch } from '../../../../redux/store'
import { callProspectDetail } from '../services/thunk'
import { ApiStatus } from '../../../../constants/status'

export const initProspectDetail =
  (uuid: string) => async (dispatch: AppDispatch) => {
    dispatch(setProspectDetailIsLoading(true))
    const { payload } = await dispatch(
      callProspectDetail({ prospectUuid: uuid })
    )
    if (payload?.status === ApiStatus.SUCCESS) {
      dispatch(setProspectDetail(payload.result))
    }
    dispatch(setProspectDetailIsLoading(false))
  }
