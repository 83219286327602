import { candidateFilterList } from '../../../../utils/apiPath'
import { headerCells } from './model/headerCellsList'

export const pageConfig = {
  moduleId: 'IMPORT_CANDIDATE',
  moduleSlug: 'candidate',
  moduleTitleFull: 'รายการผู้มุ่งหวัง',
  moduleTitleSecond: 'รายการผู้มุ่งหวัง',
  modulesTitleShort: 'รายการผู้มุ่งหวัง',
  modulePath: '/candidate/import',
  filterPath: candidateFilterList,
  headerCells: headerCells,
}
