import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

type InProgressProps = {
  icon: React.ReactNode
  title: string
  desc: string
}

export default function InProgress({
  icon,
  title,
  desc,
}: Readonly<InProgressProps>) {
  return (
    <Stack height={1} alignItems="center" justifyContent="center" gap={4}>
      {icon}
      <Box textAlign="center">
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{desc}</Typography>
      </Box>
    </Stack>
  )
}
