import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useGridColumns } from './model'
import { setIsModalOpen } from './slices/index'
import { boxInModalStyle } from '../../../constants/styles.constant'
import { ManagerGrid } from './slices/model'
import { SliceModel } from '../../../redux/models'
import { AppDispatch } from '../../../redux/store'
import useTheme from '@mui/material/styles/useTheme'
import CustomDropdown, {
  convertAnyItemToOption,
} from '../../Input/CustomDropdown'
import CustomTextfield from '../../Input/CustomTextfield'
import { handleSearchClick } from './service'
import { setClearForm, setFieldValue } from '../../CRUD/slices'
import DataGridSkeleton from './dataGridSkelaton'

type Modal = {
  onConfirmClick: (row: ManagerGrid | undefined) => void
}

const Content = (props: Modal) => {
  const { onConfirmClick } = props
  const dispatch: AppDispatch = useDispatch()
  const theme = useTheme()

  const [selectedRow, setSelectedRow] = useState<ManagerGrid | undefined>(
    undefined
  )
  const [isSearchClicked, setIsSearchClicked] = useState(false)

  // ส่ง selectedRow และ setSelectedRow ไปที่ useGridColumns
  const columns = useGridColumns(selectedRow)

  const gridStyles = {
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
  }

  const { form, prospect, isModalOpen, grid, isLoading, provinces } =
    useSelector(
      (state: SliceModel) => ({
        form: state.crud.formData,
        prospect: state.prospect.detail.data,
        isModalOpen: state.managerLookup.isModalOpen,
        grid: state.managerLookup.grid,
        isLoading: state.managerLookup.isLoading,
        provinces: state.province.list,
      }),
      shallowEqual
    )

  const handleSearch = () => {
    setIsSearchClicked(true)
    const param = {
      ...form,
      prospectUuid: prospect?.uuid,
    }
    dispatch(
      setFieldValue({ key: `formData.prospectUuid`, value: prospect?.uuid })
    )
    dispatch(handleSearchClick(param))
  }

  return (
    <Fade in={isModalOpen}>
      <Box
        sx={{
          ...boxInModalStyle(theme),
          borderRadius: 2,
          border: '1px solid rgba(0, 0, 0, 0.12)',
          boxShadow: theme.shadows[1],
        }}
      >
        <Typography variant="h5" sx={{ mb: 4 }}>
          ยืนยันการดำเนินการ
        </Typography>
        <Box
          sx={{
            opacity: isLoading ? 0.4 : 'unset',
            pointerEvents: isLoading ? 'none' : 'unset',
          }}
        >
          {/* Input Fields and Search Button */}
          <Box display="flex" gap={2} mb={3} alignItems="flex-end" width="100%">
            <Box flex={1}>
              <CustomDropdown
                label={'จังหวัด'}
                crud={'provinceUuid'}
                isValue
                options={convertAnyItemToOption(provinces, {
                  label: 'provinceName',
                  value: 'uuid',
                })}
                boxSx={{ mb: 1 }}
              />
            </Box>
            <Box flex={1}>
              <CustomTextfield
                crud={'agentCode'}
                fullWidth
                label={'รหัสผู้จัดการ'}
                boxSx={{ mb: 1 }}
              />
            </Box>
            <Box flex={1}>
              <CustomTextfield
                crud={'managerName'}
                fullWidth
                label={'ชื่อผู้จัดการ'}
                boxSx={{ mb: 1 }}
              />
            </Box>
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{ height: 'fit-content', mb: 1 }}
            >
              ค้นหา
            </Button>
          </Box>

          {/* Data Grid */}
          {isSearchClicked && (
            <Box
              sx={{
                height: theme.spacing(40), // 40 * 8px = 320px
                mb: 4,
              }}
            >
              {isLoading ? (
                <DataGridSkeleton />
              ) : (
                <DataGrid
                  onRowClick={(e) => {
                    setSelectedRow(e.row)
                  }}
                  rows={grid}
                  columns={columns}
                  sx={gridStyles}
                  disableColumnFilter={true}
                  disableColumnSelector={true}
                  disableDensitySelector={true}
                  hideFooter
                />
              )}
            </Box>
          )}

          {/* Action Buttons */}
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={2}
            mt={3}
            sx={{
              borderTop: '1px solid #e0e0e0',
              pt: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(setClearForm())
                dispatch(setIsModalOpen(false))
              }}
              sx={{ minWidth: 90 }}
            >
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onConfirmClick(selectedRow)
                dispatch(setIsModalOpen(false))
              }}
              sx={{ minWidth: 90 }}
              disabled={!selectedRow}
            >
              ยืนยัน
            </Button>
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}

export default Content
