import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'REASONS_DROP_PROSPECT_BY_DC',
  moduleSlug: 'reasons-drop-prospect-by-dc',
  moduleTitleFull: 'รายการเหตุผลในการละทิ้งผู้มีโอกาสเป็นผู้มุ่งหวัง',
  moduleTitleSecond: 'รายการเหตุผลในการละทิ้งผู้มีโอกาสเป็นผู้มุ่งหวัง',
  modulesTitleShort: 'รายการเหตุผลในการละทิ้งผู้มีโอกาสเป็นผู้มุ่งหวัง',
  modulePath: '/reasons-drop-prospect-by-dc',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
