import _ from 'lodash'
import { FormData } from '../components/CRUD/types/CrudState'

export const ShortFormText = {
  agent_recruitment_form_title_th: 'สมัครเป็นตัวแทน กรุงไทย-แอกซ่า ประกันชีวิต',
  agent_recruitment_form_title_en:
    'สมัครเป็นตัวแทน กรุงไทย-แอกซ่า ประกันชีวิต (EN)',
  agent_recruitment_form_subtitle1_th:
    'กรุณากรอกข้อมูลส่วนตัวของท่านให้ครบถ้วน',
  agent_recruitment_form_subtitle1_en:
    'กรุณากรอกข้อมูลส่วนตัวของท่านให้ครบถ้วน (EN)',
  agent_recruitment_form_subtitle2_th:
    'กรุณากรอกข้อมูลการติดต่อและความสนใจของท่าน',
  agent_recruitment_form_subtitle2_en:
    'กรุณากรอกข้อมูลการติดต่อและความสนใจของท่าน (EN)',
  agent_recruitment_form_bt_next_th: 'ถัดไป',
  agent_recruitment_form_bt_next_en: 'Next',
  agent_recruitment_form_bt_accept_th: 'ยืนยัน',
  agent_recruitment_form_bt_accept_en: 'Accept',
  agent_recruitment_form_bt_return_th: 'แก้ไข',
  agent_recruitment_form_bt_return_en: 'Return',
  agent_recruitment_form_bt_confirm_th: 'ตกลง',
  agent_recruitment_form_bt_confirm_en: 'Confirm',
  agent_recruitment_form_confirm_title_th:
    'กรุณายืนยันความถูกต้องของอีเมลและเบอร์โทรศัพท์ของท่านอีกครั้ง',
  agent_recruitment_form_confirm_title_en:
    'กรุณายืนยันความถูกต้องของอีเมลและเบอร์โทรศัพท์ของท่านอีกครั้ง (EN)',
  agent_recruitment_form_confirm_detail_email_th: 'อีเมล: ',
  agent_recruitment_form_confirm_detail_email_en: 'Email: ',
  agent_recruitment_form_confirm_detail_phoneNumber_th:
    'หมายเลขโทรศัพท์มือถือ: ',
  agent_recruitment_form_confirm_detail_phoneNumber_en: 'Phonenumber: ',
  agent_recruitment_form_confirm_email_th:
    'อาจใช้เวลา 1-2 นาทีในการส่งอีเมลยืนยันตัวตน\nไปยังกล่องจดหมายของท่าน\nหากไม่ได้รับอีเมล กรุณาตรวจอีเมลในโฟลเดอร์อีกเมลขยะของท่าน',
  agent_recruitment_form_confirm_email_en:
    'อาจใช้เวลา 1-2 นาทีในการส่งอีเมลยืนยันตัวตน\nไปยังกล่องจดหมายของท่าน\nหากไม่ได้รับอีเมล กรุณาตรวจอีเมลในโฟลเดอร์อีกเมลขยะของท่าน (EN)',
  agent_recruitment_form_group_th: [
    {
      agent_recruitment_form_key_th: 'firstName',
      agent_recruitment_form_name_th: '*ชื่อ',
      agent_recruitment_form_name_placeholder_th: 'ตัวอย่าง: กรุงไทย',
      agent_recruitment_form_type_th: 'text',
      agent_recruitment_form_validate_status_th: true,
    },
    {
      agent_recruitment_form_key_th: 'lastName',
      agent_recruitment_form_name_th: '*นามสกุล',
      agent_recruitment_form_name_placeholder_th: 'ตัวอย่าง: แอกซ่า',
      agent_recruitment_form_type_th: 'text',
      agent_recruitment_form_validate_status_th: true,
    },
    {
      agent_recruitment_form_key_th: 'dateOfBirth',
      agent_recruitment_form_name_th: '*วัน/เดือน/ปีเกิด (18 ปีขึ้นไป)',
      agent_recruitment_form_name_placeholder_th: 'กรุณาเลือก วัน/เดือน/ปีเกิด',
      agent_recruitment_form_type_th: 'date',
      agent_recruitment_form_validate_status_th: true,
    },
    {
      agent_recruitment_form_key_th: 'mobileNo',
      agent_recruitment_form_name_th: '*หมายเลขโทรศัพท์มือถือ',
      agent_recruitment_form_name_placeholder_th:
        'กรุณากรอกหมายเลขโทรศัพท์มือถือ',
      agent_recruitment_form_type_th: 'textMobile',
      agent_recruitment_form_validate_status_th: true,
    },
    {
      agent_recruitment_form_key_th: 'email',
      agent_recruitment_form_name_th: '*อีเมล',
      agent_recruitment_form_name_placeholder_th: 'ตัวอย่าง: ktaxa@gmail.com',
      agent_recruitment_form_type_th: 'textMail',
      agent_recruitment_form_validate_status_th: true,
    },
    {
      agent_recruitment_form_key_th: 'provinceUuid',
      agent_recruitment_form_name_th: '*จังหวัดที่สะดวกปฏิบัติงาน',
      agent_recruitment_form_name_placeholder_th: 'กรุณาเลือกจังหวัด',
      agent_recruitment_form_type_th: 'dropdownProvince',
      agent_recruitment_form_validate_status_th: true,
    },
    {
      agent_recruitment_form_key_th: 'knowUsFromChannelUuid',
      agent_recruitment_form_name_th:
        '*ท่านเห็นโฆษณาตัวแทนกรุงไทย-แอกซ่า จากช่องทางใด',
      agent_recruitment_form_name_placeholder_th: 'กรุณาเลือกช่องทางสื่อ',
      agent_recruitment_form_type_th: 'knowUsFromChannelDropdown',
      agent_recruitment_form_validate_status_th: true,
    },
    {
      agent_recruitment_form_key_th: 'spareTime',
      agent_recruitment_form_name_th: '*เวลาที่สะดวกเพื่อให้บริษัทฯ ติดต่อกลับ',
      agent_recruitment_form_name_placeholder_th: 'กรุณาเลือกเวลาที่สะดวก',
      agent_recruitment_form_type_th: 'timeSelect',
      agent_recruitment_form_validate_status_th: true,
    },
    {
      agent_recruitment_form_key_th: 'workingStyle',
      agent_recruitment_form_name_th: '*สนใจทำธุรกิจผ่านช่องทางใด',
      agent_recruitment_form_name_placeholder_th: 'กรุณาเลือกช่องทางทำธุรกิจ',
      agent_recruitment_form_type_th: 'businessSocialMediaChannelDropdown',
      agent_recruitment_form_validate_status_th: true,
    },
  ],
  agent_recruitment_form_group_en: [
    {
      agent_recruitment_form_key_en: 'firstName',
      agent_recruitment_form_name_en: '*ชื่อ',
      agent_recruitment_form_name_placeholder_en: 'ตัวอย่าง: กรุงไทย',
      agent_recruitment_form_type_en: 'text',
      agent_recruitment_form_validate_status_en: true,
    },
    {
      agent_recruitment_form_key_en: 'lastName',
      agent_recruitment_form_name_en: '*นามสกุล',
      agent_recruitment_form_name_placeholder_en: 'ตัวอย่าง: แอกซ่า',
      agent_recruitment_form_type_en: 'text',
      agent_recruitment_form_validate_status_en: true,
    },
    {
      agent_recruitment_form_key_en: 'dateOfBirth',
      agent_recruitment_form_name_en: '*วัน/เดือน/ปีเกิด (18 ปีขึ้นไป)',
      agent_recruitment_form_name_placeholder_en: 'กรุณาเลือก วัน/เดือน/ปีเกิด',
      agent_recruitment_form_type_en: 'date',
      agent_recruitment_form_validate_status_en: true,
    },
    {
      agent_recruitment_form_key_en: 'mobileNo',
      agent_recruitment_form_name_en: '*หมายเลขโทรศัพท์มือถือ',
      agent_recruitment_form_name_placeholder_en:
        'กรุณากรอกหมายเลขโทรศัพท์มือถือ',
      agent_recruitment_form_type_en: 'textMobile',
      agent_recruitment_form_validate_status_en: true,
    },
    {
      agent_recruitment_form_key_en: 'email',
      agent_recruitment_form_name_en: 'อีเมล',
      agent_recruitment_form_name_placeholder_en: 'ตัวอย่าง: ktaxa@gmail.com',
      agent_recruitment_form_type_en: 'textMail',
      agent_recruitment_form_validate_status_en: false,
    },
    {
      agent_recruitment_form_key_en: 'provinceUuid',
      agent_recruitment_form_name_en: '*จังหวัดที่สะดวกปฏิบัติงาน',
      agent_recruitment_form_name_placeholder_en: 'กรุณาเลือกจังหวัด',
      agent_recruitment_form_type_en: 'dropdownProvince',
      agent_recruitment_form_validate_status_en: true,
    },
    {
      agent_recruitment_form_key_en: 'knowUsFromChannelUuid',
      agent_recruitment_form_name_en:
        '*ท่านเห็นโฆษณาตัวแทนกรุงไทย-แอกซ่า จากช่องทางใด',
      agent_recruitment_form_name_placeholder_en: 'กรุณาเลือกช่องทางสื่อ',
      agent_recruitment_form_type_en: 'knowUsFromChannelDropdown',
      agent_recruitment_form_validate_status_en: true,
    },
    {
      agent_recruitment_form_key_en: 'spareTime',
      agent_recruitment_form_name_en: '*เวลาที่สะดวกเพื่อให้บริษัทฯ ติดต่อกลับ',
      agent_recruitment_form_name_placeholder_en: 'กรุณาเลือกเวลาที่สะดวก',
      agent_recruitment_form_type_en: 'timeSelect',
      agent_recruitment_form_validate_status_en: true,
    },
    {
      agent_recruitment_form_key_en: 'workingStyle',
      agent_recruitment_form_name_en: '*สนใจทำธุรกิจผ่านช่องทางใด',
      agent_recruitment_form_name_placeholder_en: 'กรุณาเลือกช่องทางทำธุรกิจ',
      agent_recruitment_form_type_en: 'businessSocialMediaChannelDropdown',
      agent_recruitment_form_validate_status_en: true,
    },
  ],
  spare_time_th: [
    {
      best_time_contact_name: '09:00 - 12:00 น.',
      best_time_contact_value: '09:00 - 12:00',
      best_time_contact_index: 1,
    },
    {
      best_time_contact_name: '13:00 - 17:00 น.',
      best_time_contact_value: '13:00 - 17:00',
      best_time_contact_index: 2,
    },
    {
      best_time_contact_name: '18:00 - 21:00 น.',
      best_time_contact_value: '18:00 - 21:00',
      best_time_contact_index: 3,
    },
  ],
  spare_time_en: [
    {
      best_time_contact_name: '09:00 - 12:00',
      best_time_contact_value: '09:00 - 12:00',
      best_time_contact_index: 1,
    },
    {
      best_time_contact_name: '13:00 - 17:00',
      best_time_contact_value: '13:00 - 17:00',
      best_time_contact_index: 2,
    },
    {
      best_time_contact_name: '18:00 - 21:00',
      best_time_contact_value: '18:00 - 21:00',
      best_time_contact_index: 3,
    },
  ],
  business_social_media_channel_th: [
    {
      value: 'Online',
      label: 'ออนไลน์ เช่น เฟซบุ๊ก, อินสตาร์แกรม, ติ๊กต็อก เป็นต้น',
    },
    {
      value: 'Customer',
      label: 'เข้าพบลูกค้า, ขายต่อหน้า',
    },
    {
      value: 'Event',
      label: 'ออกบูธ, จ๊อบแฟร์, ตลาดนัด',
    },
  ],
  business_social_media_channel_en: [
    {
      value: 'Online',
      label: 'Online e.g. Facebook, Instagram, TikTok เป็นต้น',
    },
    {
      value: 'Customer',
      label: 'เข้าพบลูกค้า, ขายต่อหน้า',
    },
    {
      value: 'Event',
      label: 'Booth, JobFair, Market',
    },
  ],
}
export const getLabelShortFormArrText = (
  path: FormData,
  value: string | undefined
) => {
  const find = _.find(path, { value: value })
  return _.get(find, 'label', '')
}
