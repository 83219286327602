import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../utils/axios'
import { API_URL } from '../../constants/envConfig'

interface CallAPIParams {
  url: string
  body?: Record<string, any>
  fileName?: string
  headers?: Record<string, string>
}

export const downloadFile = createAsyncThunk(
  'downloadFile',
  async (
    { url, body, fileName, headers }: CallAPIParams,
    { rejectWithValue }: any
  ) => {
    try {
      await axios
        .post(url, body, { responseType: 'blob', headers, baseURL: API_URL })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName ?? 'downloadedFile')
          document.body.appendChild(link)
          link.click()
        })

      return []
    } catch (err: any) {
      console.error('Download file error:', err)
      return rejectWithValue([], err)
    }
  }
)

export const downloadFileManager = createAsyncThunk(
  'downloadFileManager',
  async (
    { url, fileName, headers }: CallAPIParams,
    { rejectWithValue }: any
  ) => {
    try {
      await axios
        .get(url, { responseType: 'blob', headers, baseURL: API_URL }) // เปลี่ยนจาก post เป็น get และลบ body
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName ?? 'downloadedFile')
          document.body.appendChild(link)
          link.click()
        })

      return []
    } catch (err: any) {
      console.error('Download file error:', err)
      return rejectWithValue([], err)
    }
  }
)

export const downloadFileCandidate = createAsyncThunk(
  'downloadFileCandidate',
  async (
    { url, fileName, headers }: CallAPIParams,
    { rejectWithValue }: any
  ) => {
    try {
      await axios
        .get(url, { responseType: 'blob', headers, baseURL: API_URL }) // เปลี่ยนจาก post เป็น get และลบ body
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName ?? 'downloadedFile')
          document.body.appendChild(link)
          link.click()
        })

      return []
    } catch (err: any) {
      console.error('Download file error:', err)
      return rejectWithValue([], err)
    }
  }
)
