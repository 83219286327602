import { Grid } from '@mui/material'

import { shallowEqual, useSelector } from 'react-redux'
import { SliceModel } from '../../../../../redux/models'
import { ProcessResultApprovalStatus } from '../../../../../constants/status'
import { TimerAppointment } from './CardTimer/TimerAppointment'
import { TimerInterview } from './CardTimer/TimerInterview'
import { TimerReject } from './CardTimer/TimerReject'
import AssignInfo from './CardAssign/AssignInfo'
import { getValue } from '../../../../../utils/getValue'
import ProfileInfo from './CardInfo/ProfileInfo'
import { FullFormStat } from './CardFullForm/FullFormStat'
import { AssignProcess } from './CardAssign/AssignProcess'
import { AssignReject } from './CardAssign/AssignReject'
import { CardStepMobile } from '../Step'
import { FullForm } from './CardFullForm/FullForm'

export default function CardProfile() {
  const { data } = useSelector(
    (state: SliceModel) => ({
      data: state.prospect.detail.data,
    }),
    shallowEqual
  )

  const getRanderCenterBox = () => {
    const status = getValue(data, 'status')

    if (status === ProcessResultApprovalStatus.DL_ASSIGNED_MA) {
      return <TimerAppointment />
    } else if (status === ProcessResultApprovalStatus.I_ACCEPTED_INTERVIEW) {
      return <TimerInterview />
    } else if (
      [
        ProcessResultApprovalStatus.I_DECLINED_INTERVIEW,
        ProcessResultApprovalStatus.I_FAILED_INTERVIEW,
      ].includes(status)
    ) {
      return <TimerReject />
    } else {
      return <FullFormStat />
    }
  }
  const getRanderEndBox = () => {
    const status = getValue(data, 'status')
    if (status) {
      return <AssignInfo />
    } else {
      return <FullForm />
    }
  }

  const rederGrid = () => {
    const status = getValue(data, 'status')
    if (
      status === ProcessResultApprovalStatus.I_DECLINED_INTERVIEW ||
      status === ProcessResultApprovalStatus.I_FAILED_INTERVIEW ||
      status === ProcessResultApprovalStatus.DL_SUBMITTED_SHORT_FORM ||
      status === ProcessResultApprovalStatus.DL_SUCCESSFULLY_SENT_ASSIGN_RULE
    ) {
      return (
        <>
          <Grid item xs={12} md={6}>
            <ProfileInfo />
          </Grid>
          <Grid item xs={12} md={6}>
            <AssignProcess />
          </Grid>
        </>
      )
    } else if (
      status === ProcessResultApprovalStatus.DL_DROPPED ||
      status === ProcessResultApprovalStatus.DCV_DROPPED
    ) {
      return (
        <>
          <Grid item xs={12} md={6}>
            <ProfileInfo />
          </Grid>
          <Grid item xs={12} md={6}>
            <AssignReject />
          </Grid>
        </>
      )
    } else {
      return (
        <>
          <Grid item xs={12} md={4}>
            <ProfileInfo />
            <CardStepMobile />
          </Grid>
          <Grid item xs={12} md={4}>
            {getRanderCenterBox()}
          </Grid>
          <Grid item xs={12} md={4}>
            {getRanderEndBox()}
          </Grid>
        </>
      )
    }
  }
  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {rederGrid()}
      </Grid>
    </>
  )
}
