import { Box, Typography } from '@mui/material'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'
import { themeColor } from '../../../../../../styles/themes/themeData'
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp'
import { META } from '../../../../../../constants/webMeta'
import CustomButton from '../../../../../../components/Input/CustomButton'
import { AppDispatch } from '../../../../../../redux/store'
import { setIsModalOpen } from '../../../../../../components/Lookup/ManagerLookup/slices'
import ManagerLookup from '../../../../../../components/Lookup/ManagerLookup'
import { ManagerGrid } from '../../../../../../components/Lookup/ManagerLookup/slices/model'
import { handleConfirmAssign } from '../../Form/Assign/handler/confirmAssign'

export function AssignProcess() {
  const dispatch: AppDispatch = useDispatch()
  const { data, isLoading, form } = useSelector(
    (state: SliceModel) => ({
      data: state.prospect.detail.data,
      isLoading: state.prospect.detail.isLoading,
      form: state.crud.formData,
    }),
    shallowEqual
  )

  return (
    <CustomCard
      isLoading={isLoading}
      sx={{ height: '145px', alignContent: 'center' }}
    >
      <Box display={'flex'} gap={2}>
        <Box
          sx={{
            p: 1,
            pb: 0,
            borderRadius: 2,
            backgroundColor: themeColor.grey.grey100,
            position: 'relative',
          }}
          width={'62px'}
          height={'62px'}
          alignItems={'center'}
          textAlign={'center'}
        >
          <img
            alt={META.ALT}
            src={`${process.env.PUBLIC_URL}/icons/icons-plus.png`}
            style={{
              position: 'absolute',
              display: 'block',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
        <Box>
          <Typography variant="body1b">รอมอบหมายให้ผู้จัดการ</Typography>
          <Typography mb={1} variant="body2" sx={{ color: 'text.gray' }}>
            คลิ๊กเพื่อมอบหมายผู้จัดการ
          </Typography>
          <CustomButton
            size={'small'}
            variant={'outlined'}
            onClick={() => {
              dispatch(setIsModalOpen(true))
            }}
          >
            <Typography variant="body2">มอบหมายผู้จัดการ</Typography>{' '}
            <KeyboardArrowRightSharpIcon />
          </CustomButton>
        </Box>
      </Box>
      <ManagerLookup
        uuid={data?.uuid ? data?.uuid : null}
        onConfirmClick={(row: ManagerGrid | undefined) =>
          dispatch(handleConfirmAssign(row, form))
        }
      />
    </CustomCard>
  )
}
