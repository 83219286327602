import { handleProcessSubmitForm } from './handleProcessSubmitForm'
import { openDialog } from '../../../components/dialog/slices'
import { store } from '../../../redux/store'
import { replaceFieldError, setFieldError } from '../../CRUD/slices'
import handleScrollToError from './handleScrollToError'
import type { NavigateFunction } from 'react-router-dom'
import { type ObjectSchema } from 'yup'

const DIALOG_TITLE = 'ยืนยันบันทึก'
const DIALOG_MESSAGE = 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่'

export const validateFormData = async (
  formData: any,
  validationSchema: any
) => {
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error: any) {
    store.dispatch(setFieldError(error.inner))
    handleScrollToError(error.inner[0].path)
    return false
  }
}

export const handleSubmitClick = async (
  uuid: string,
  postBodyMutation: any,
  validationSchema: any,
  navigate?: NavigateFunction
) => {
  const { formData } = store.getState().crud
  const isValid = await validateFormData(formData, validationSchema)

  if (isValid) {
    confirmDialog(uuid, postBodyMutation, navigate)
  }
}

function confirmDialog<T extends object>(
  uuid: string,
  postBodyMutation: ObjectSchema<T>,
  navigate?: NavigateFunction
) {
  store.dispatch(
    openDialog({
      title: DIALOG_TITLE,
      message: DIALOG_MESSAGE,
      handleConfirm: () => {
        handleProcessSubmitForm(uuid, postBodyMutation, navigate)
      },
      isCloseDialog: false,
      type: 'confirm',
    })
  )
}
