export const UPLOAD_IN_PROGRESS = 1
export const UPLOAD_SUCCESS = 100

export const STATUS = {
  ERROR: 'ERROR',
}

export const AREA_QUEUE_FIELD = 'Area Queue'
export const PROVINCE_QUEUE_FIELD = 'Province Queue'
export const SEQ_NO_FIELD = 'Seq No'

export const PROGRESS_SUCCESS = 100

export const STATUS_TEXT = 'สถานะ'
