import { store } from '../../../../../../redux/store'
import get from 'lodash/get'

export function mutateFilterPropsToBody() {
  const { filterProp } = store.getState().table.filter
  const { title, description } = store.getState().table.filterState

  return {
    name: 'user test',
    title,
    description,
    status: get(filterProp, 'status', []),
  }
}
