import isEmpty from 'lodash/isEmpty'

export function validateColTemplate(
  item: Record<string, unknown>,
  templateCol: string[]
) {
  // * is invalid
  if (isEmpty(item)) {
    return true
  }

  return Object.keys(item).some((col, i) => templateCol[i] !== col)
}
