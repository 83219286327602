import { Box, Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'
import { themeColor } from '../../../../../../styles/themes/themeData'

export function FullFormStat() {
  const { isLoading } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )
  const fullFormProcess = [
    {
      section: 1,
      processPersent: 50,
    },
    {
      section: 2,
      processPersent: 40,
    },
    {
      section: 3,
      processPersent: 60,
    },
    {
      section: 4,
      processPersent: 10,
    },
    {
      section: 1,
      processPersent: 100,
    },
  ]

  return (
    <CustomCard isLoading={isLoading} sx={{ height: '145px' }}>
      <Box mb={1}>
        <Typography variant="body1b">ความคืบหน้าใบสมัคร</Typography>
      </Box>
      <Box display={'flex'} gap={2}>
        <Box display={'flex'} gap={2}>
          {fullFormProcess.map((item, index) => (
            <Box
              key={index}
              sx={{
                height: '50px',
                width: '8px',
                borderRadius: '50px',
                backgroundColor: '#f1f1f1',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: item.processPersent + '%',
                  width: '100%',
                  borderRadius: '50px',
                  backgroundColor: themeColor.primary.main,
                }}
              ></Box>
            </Box>
          ))}
        </Box>
        <Box>
          <Typography sx={{ mb: 2, color: 'text.gray' }} variant="body2">
            ความคืบหน้า
          </Typography>
          <Typography variant="h6">20%</Typography>
        </Box>
        <Box>
          <Typography sx={{ mb: 2, color: 'text.gray' }} variant="body2">
            ใช้เวลาไป
          </Typography>
          <Typography variant="h6">47 นาที</Typography>
        </Box>
      </Box>
    </CustomCard>
  )
}
