import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'
import { CrudState } from '../types/CrudState'

const CrudSlice = createSlice({
  name: 'crud',
  initialState,
  reducers: {
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
    setInitialConfig: (state, { payload }) => {
      if (payload.isClearData) {
        _.set(state, 'formData', {})
        _.set(state, 'formErrors', [])
      }
      _.set(state, 'crudConfig', payload.crudConfig)
    },
    replaceFieldError: (state, { payload }) => {
      state.formErrors = payload
    },
    setFieldError: (state, { payload }) => {
      state.formErrors = [...state.formErrors, ...payload]
    },
    setFormInit: (state: CrudState, { payload }) => {
      _.set(state, 'formData', _.get(payload, 'formData', {}))
      _.set(state, 'isLoading', _.get(payload, 'isLoading', false))
      _.set(state, 'formErrors', _.get(payload, 'formErrors', []))
    },
    setAddFormErrors: (
      state: CrudState,
      action: PayloadAction<{ path: string; message: string }>
    ) => {
      const { path } = action.payload
      const errors = state.formErrors
      const myArray = _.filter(errors, (obj) => obj.path !== path)
      state.formErrors = [...myArray, action.payload]
    },
    setRemoveFieldError: (
      state: CrudState,
      action: PayloadAction<{ path: string }>
    ) => {
      const { path } = action.payload
      const errors = state.formErrors
      const myArray = _.filter(errors, (obj) => obj.path !== path)
      state.formErrors = myArray
    },
    setFormIsLoading: (state: CrudState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setClearForm: (state: CrudState) => {
      state.formData = {}
    },
  },
})
export const {
  setFieldValue,
  setFieldError,
  replaceFieldError,
  setInitialConfig,
  setFormInit,
  setAddFormErrors,
  setRemoveFieldError,
  setFormIsLoading,
  setClearForm,
} = CrudSlice.actions

export default CrudSlice.reducer
