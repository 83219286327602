// /* eslint-disable @typescript-eslint/no-explicit-any */
import { CPageContainer } from '../../../../../components/CRUD/components/pageContainer'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { crudConfig as initialCrudConfig } from '../../crudConfig'
import { getBodyMutation } from './mutation/get'
import Header from './Header'
import { setInitalValues } from './handler/setInitialValues'
import { BasicInfo } from './components/BasicInfo'
import { fetchDetail } from './handler/fetchDetail'
import CHistoryDrawer from '../../../../../components/CRUD/components/History'

const StaffProfileForm = () => {
  const { uuid } = useParams()
  const { crudConfig } = useSelector(
    (state: any) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual
  )

  useEffect(() => {
    const initializeCrud = () => {
      initialConfig(initialCrudConfig)
      if (uuid && !_.isUndefined(crudConfig)) {
        fetchDetail(uuid, getBodyMutation)
      } else {
        setInitalValues()
      }
    }
    initializeCrud()
  }, [uuid, crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <>
      <CPageContainer
        gap={3}
        header={<Header uuid={uuid} crudConfig={crudConfig} />}
        content={<BasicInfo />}
      />
      <CHistoryDrawer />
    </>
  )
}

export default StaffProfileForm
