import React from 'react'
import { useDispatch, useSelector } from '../../../redux/store'
import { selectTableDelete } from '../../../redux/selector'
import useHistory from '../../../utils/useHistory'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import ArrowForwardTwoTone from '@mui/icons-material/ArrowForwardTwoTone'

import IconButton from '@mui/material/IconButton'

import { StyledBox } from './Styled'
import { setOnEdit } from '../redux/index'

type RowType = {
  uuid: string
  [key: string]: any // Adjust according to the actual row structure
}

interface ActionProps {
  row: RowType
  rowIndex: number
  minWidth?: string | number
  condition: any
}

const Action: React.FC<ActionProps> = ({
  row,
  rowIndex,
  minWidth,
  condition,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const onDelete = useSelector(selectTableDelete)
  const editUrl = `${condition.editPath}/${row.uuid}`

  return (
    <StyledBox minWidth={minWidth}>
      {!condition.hideEdit && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnEdit({ history, editUrl }))}
        >
          <ArrowForwardTwoTone color="action" />
        </IconButton>
      )}
      {!condition.hideDelete && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onDelete?.(row)}
        >
          <DeleteTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default Action
