import {
  startLoading,
  stopLoading,
} from '../../../../../../components/Table/redux/index'
import { prospectListDownload } from '../../../../../../utils/apiPath'
import { downloadFile } from '../../../../../../services/utils/downloadFile'
import { dispatch, store } from '../../../../../../redux/store'

export const handleDownloadClick = async (
  selected: any[],
  sort: string,
  order: string
): Promise<void> => {
  onDownload(selected, sort, order)
}

const onDownload = async (selected: any[], sort: string, order: string) => {
  const { crudConfig } = store.getState().crud
  dispatch(startLoading())

  const body = {
    order: order.toUpperCase(),
    sort: sort,
    list: selected,
  }

  dispatch(
    downloadFile({
      url: prospectListDownload,
      body: body,
      fileName: `ไฟล์${crudConfig?.moduleTitleFull}.xlsx`,
      headers: { 'x-type': crudConfig?.moduleId ?? '' },
    })
  )
  dispatch(stopLoading())
}
