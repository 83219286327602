import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { TablePaginationProps } from '@mui/material'

import { visuallyHidden } from '@mui/utils'
import { useDispatch, useSelector, store } from '../../redux/store'
import {
  selectTableFilterTotal,
  selectTable,
  selectEnhancedTable,
} from '../../redux/selector'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import _ from 'lodash'
import { setToggleFilterDrawer } from './redux'
import {
  StyledBox,
  StyledCheckBoxCell,
  StyledDownloadButton,
  StyledFilterButton,
  StyledFullWidth,
  StyledTable,
  StyledTableBodyCell,
  StyledTableCell,
  StyledTableRow,
  StyledTableSortLabel,
  StyledToolbar,
  StyledToolbarButtonBox,
} from './Styled'
import CustomSearch from './Search'
import QuickFilter from './QuickFilter'
import * as events from './Events'
import { ROW_PAGE_PER_LIST } from '../../constants/table/rowPerPageList'

export const EnhancedTableToolbar = () => {
  const dispatch = useDispatch()

  const { onDownload, handleSearch, table, customToolbar, filterTotal } =
    useSelector(selectTableFilterTotal)

  const { selected, sort, order } = table

  return (
    <StyledToolbar>
      <StyledBox>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {handleSearch && <CustomSearch />}
        </Box>
        {onDownload && (
          <StyledToolbarButtonBox>
            {customToolbar}
            {onDownload && typeof onDownload === 'function' && (
              <StyledDownloadButton
                data-testid="btn-download"
                disabled={!(selected.length > 0)}
                onClick={() => onDownload(selected, sort, order)}
              >
                ดาวน์โหลด
              </StyledDownloadButton>
            )}
            <StyledFilterButton
              data-testid="btn-filter"
              onClick={() => dispatch(setToggleFilterDrawer(true))}
            >
              ตัวกรอง {filterTotal !== 0 && `(${filterTotal})`}
            </StyledFilterButton>
          </StyledToolbarButtonBox>
        )}
      </StyledBox>
    </StyledToolbar>
  )
}

export const EnhancedTableHead = () => {
  const dispatch = useDispatch()
  const table = useSelector(selectTable)
  const { rows, isCheckBox, selected, headCells, defaultSort, order, sort } =
    table

  const rowCount = rows.length
  const selectedCount = selected.length
  const isBasicTable = false

  return (
    <TableHead>
      <TableRow
        key="head"
        sx={{ '& .MuiTableCell-root': { borderColor: '#F5F5F5' } }}
      >
        {isCheckBox && (
          <StyledCheckBoxCell>
            <Checkbox
              data-testid="select-all-element"
              color="primary"
              indeterminate={selectedCount > 0 && selectedCount < rowCount}
              checked={rowCount > 0 && selectedCount === rowCount}
              onChange={(e) =>
                dispatch(
                  events.handleSelectAllClick(e, rows as { uuid: string }[])
                )
              }
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </StyledCheckBoxCell>
        )}
        {Array.isArray(headCells) &&
          headCells.length > 0 &&
          headCells.map((headCell) => {
            const valueId = headCell.id
            let checkActive = false

            if (defaultSort && valueId === defaultSort.id) {
              checkActive = defaultSort.active
            }

            return (
              <StyledTableCell
                key={valueId}
                align={'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={sort === valueId ? order : false}
                width={headCell.width}
                minWidth={headCell.minWidth}
              >
                <StyledTableSortLabel
                  data-testid={`sort-cell-${valueId}`}
                  active={checkActive}
                  direction={sort === valueId ? order : 'asc'}
                  onClick={() =>
                    store.dispatch(
                      events.handleRequestSort(
                        valueId,
                        table,
                        headCell,
                        isBasicTable
                      )
                    )
                  }
                  hideSortIcon={headCell.hideSortIcon}
                  headcell={headCell}
                >
                  {headCell.label}
                  {sort === valueId && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  )}
                </StyledTableSortLabel>
              </StyledTableCell>
            )
          })}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTable = ({ rowsPerPageOptions = ROW_PAGE_PER_LIST }) => {
  const { table, subActions, customStyle, onDownload, handleSearch, filter } =
    useSelector(selectEnhancedTable)

  const {
    rows,
    selected,
    isCheckBox,
    headCells,
    isShowPagination,
    allCount = 0,
    rowsPerPage = { value: 10 },
    tablePage,
  } = table
  const { quickFilter } = filter

  const tableWithDefaults = {
    ...table,
    allCount: allCount ?? 0,
    rows: rows ?? [],
    rowsPerPage: rowsPerPage ?? { value: 10 },
    tablePage: tablePage ?? 0,
    page: tablePage ?? 0,
  }

  const isBasicTable = false

  const labelRowsPerPage = (
    <Typography variant="caption" color="text.secondary">
      แถวต่อหน้า{'\xA0'} :
    </Typography>
  )

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    store.dispatch(events.handleChangeRowsPerPage(event, isBasicTable))
  }

  const labelDisplayedRows: TablePaginationProps['labelDisplayedRows'] = ({
    from,
    to,
    count,
  }) => {
    return rowsPerPage.value === -1
      ? `แสดงทั้งหมด ${count} รายการ`
      : `${from}-${to} จาก ${count} รายการ`
  }

  return (
    <StyledFullWidth sx={customStyle}>
      {((onDownload && typeof onDownload === 'function') || handleSearch) && (
        <EnhancedTableToolbar />
      )}
      {!_.isNil(quickFilter) && quickFilter.length > 0 && <QuickFilter />}
      {subActions}
      <TableContainer>
        <StyledTable>
          <EnhancedTableHead />
          <TableBody>
            {Array.isArray(rows) &&
              rows.length > 0 &&
              rows.map((row, rowIndex) => {
                const isItemSelected = events.isSelected(selected, row.uuid)
                const labelId = `enhanced-table-checkbox-${rowIndex}`

                return (
                  <StyledTableRow
                    key={row.uuid}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                  >
                    {isCheckBox && (
                      <StyledCheckBoxCell>
                        <Checkbox
                          data-testid={`select-${rowIndex}-element`}
                          onClick={() =>
                            store.dispatch(
                              events.handleClick(selected, row.uuid)
                            )
                          }
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </StyledCheckBoxCell>
                    )}
                    {Array.isArray(headCells) &&
                      headCells.length > 0 &&
                      headCells.map((i, cellIndex) =>
                        events.checkConditionView(
                          headCells as events.HeadCell[],
                          row as events.RowType,
                          rowIndex,
                          cellIndex
                        )
                      )}
                  </StyledTableRow>
                )
              })}
            {(rows?.length ?? 0) === 0 && (
              <TableRow>
                <StyledTableBodyCell colSpan={headCells.length + 1}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {isShowPagination && (
        <TablePagination
          data-testid="page-test"
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={allCount}
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={labelRowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
          page={
            +tableWithDefaults.tablePage < 0 ? 0 : +tableWithDefaults.tablePage
          }
          onPageChange={(e, tPage) =>
            store.dispatch(events.handleChangePage(tPage, isBasicTable))
          }
          onRowsPerPageChange={handleRowsPerPageChange}
          sx={{ '& .MuiSvgIcon-root': { top: 'unset !important' } }}
        />
      )}
    </StyledFullWidth>
  )
}

export default EnhancedTable
