import { prospectDeail } from '../../../utils/apiPath'

export const pageConfig = {
  moduleId: 'PROSPECT_DETAIL',
  moduleSlug: 'prospect/detail',
  moduleTitleFull: 'รายละเอียดผู้สมัคร',
  moduleTitleSecond: '',
  modulesTitleShort: '',
  modulePath: '/prospect/detail',
  filterPath: prospectDeail,
}
