import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import Radio from '@mui/material/Radio'
import Box from '@mui/material/Box'
import { ManagerGrid } from './slices/model'

export const useGridColumns = (
  selectedRow: ManagerGrid | undefined
): GridColDef[] => {
  return [
    {
      field: 'select',
      headerName: '',
      width: 60,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Radio checked={params.id === selectedRow?.id} value={params.id} />
        </Box>
      ),
      disableColumnMenu: true,
    },
    {
      field: 'agentCode',
      headerName: 'รหัสผู้จัดการ',
      flex: 0.45,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'managerName',
      headerName: 'ชื่อผู้จัดการ',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'workingArea',
      headerName: 'พื้นที่ปฏิบัติงาน',
      flex: 0.65,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'province',
      headerName: 'จังหวัด',
      flex: 0.75,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'inQueue',
      headerName: 'จำนวนผู้สมัครรอดำเนินการ',
      flex: 0.55,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
    },
  ]
}
