import { Box, Typography } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'
import { themeColor } from '../../../../../../styles/themes/themeData'
import {
  getPercentTime,
  showDayOrTime,
  convertFormatDateTimeV2,
} from '../../../../../../utils/convertFormatDateTime'
import UpdateIcon from '@mui/icons-material/Update'
import { setLineClamp } from '../../../../../../utils/lineClamp'
import { CustomCircularProgress } from './CustomCircularProgress'

export function TimerAppointment() {
  const { data, isLoading } = useSelector(
    (state: SliceModel) => ({
      data: state.prospect.detail.data,
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )

  return (
    <CustomCard isLoading={isLoading} sx={{ height: '145px' }}>
      <Box mb={1}>
        <Typography variant="body1b">ระยะเวลาในการทำนัดหมาย</Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Box
          sx={{
            width: 'auto',
            p: 1,
            pb: 0,
            borderRadius: 2,
            backgroundColor: themeColor.grey.grey100,
            position: 'relative',
          }}
          alignItems={'center'}
          textAlign={'center'}
        >
          <CustomCircularProgress
            value={100 - getPercentTime(data?.processedAt, data?.expireDate)}
          />
          <UpdateIcon
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          />
        </Box>
        <Box>
          <Typography
            sx={{ ...setLineClamp(1), color: 'text.gray' }}
            variant="body2"
          >
            เวลาที่เหลือในการทำนัดหมาย
          </Typography>
          <Typography variant="h5">
            {showDayOrTime(data?.expireDate)}
          </Typography>
          <Typography
            sx={{ ...setLineClamp(1), color: 'text.gray' }}
            variant="body2"
          >
            วันที่สิ้นสุด :{' '}
            <span style={{ color: '#000000' }}>
              {' '}
              {convertFormatDateTimeV2(data?.expireDate, 'dateDisplay')}
            </span>{' '}
            เวลา :{' '}
            <span style={{ color: '#000000' }}>
              {convertFormatDateTimeV2(data?.expireDate, 'timeDisplay')}
            </span>
          </Typography>
        </Box>
      </Box>
    </CustomCard>
  )
}
