import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProvinceState, Province } from './provinceModel'

const initialState: ProvinceState = {
  list: [],
  isLoading: false,
  error: null,
}

const provinceSlice = createSlice({
  name: 'province',
  initialState,
  reducers: {
    setProvinceList: (state, action: PayloadAction<Province[]>) => {
      state.list = action.payload.map((item, index) => {
        return { ...item, id: index }
      })
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export const { setProvinceList, setIsLoading } = provinceSlice.actions

export default provinceSlice.reducer
