import { CPageContainer } from '../../../../../components/CRUD/components/pageContainer'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'
import { useSelector } from '../../../../../redux/store'
import { selectCrudConfig } from '../../../../../redux/selector'
import isUndefined from 'lodash/isUndefined'
import { crudConfig as initialCrudConfig } from '../../crudConfig'
import { getBodyMutation } from './mutation/get'
import Header from './Header'
import { setInitalValues } from './handler/setInitialValues'
import { BasicInfo } from './components/BasicInfo'
import { fetchDetail } from './handler/fetchDetail'
import CHistoryDrawer from '../../../../../components/CRUD/components/History'

export const EventSubSourceForm = () => {
  const { uuid } = useParams()
  const crudConfig = useSelector(selectCrudConfig)

  useEffect(() => {
    const initializeCrud = () => {
      initialConfig(initialCrudConfig)
      if (uuid && !isUndefined(crudConfig)) {
        fetchDetail(uuid, getBodyMutation)
      } else {
        setInitalValues()
      }
    }

    initializeCrud()
  }, [uuid, crudConfig])

  if (isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <>
      <CPageContainer
        gap={3}
        header={<Header uuid={uuid} crudConfig={crudConfig} />}
        content={<BasicInfo />}
      />
      <CHistoryDrawer />
    </>
  )
}
