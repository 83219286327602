export const tableResultHeader = [
  'สถานะ',
  'Seq No.',
  'Agent Code',
  'Manager Name',
  'Working Area',
  'Area Queue',
  'Province',
  'Province Queue',
]

export enum TABLE_COL_ERR_INDEX {
  agentCode = 0,
  managerName = 1,
  workingArea = 2,
  areaQueue = 3,
  province = 4,
  provinceQueue = 5,
  seqNo = 6,
}
