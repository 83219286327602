import React from 'react'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase'
import { useTheme, styled } from '@mui/material/styles'

interface Props {
  searchText?: any
  setSearchText?: any
  handleSearch?: any
  searchKey?: any
  text?: boolean
  setText?: any
  boxSx?: any
  data?: any
  sx?: any
  placeholder?: any
  setRows?: any
  setPage?: any
}

const SearchCustom: React.FC<Props> = (props) => {
  const { setSearchText, handleSearch, text, setText, boxSx, sx } = props
  const theme = useTheme()

  const onEnterSearch = (e: any) => {
    if (e.charCode === 13) {
      setSearchText(e.target.value)
      handleSearch(e.target.value)
    }
  }
  return (
    <BoxSearch sx={boxSx}>
      <SearchInput
        data-testid="search-input"
        placeholder={props.placeholder}
        onChange={(e) => setText(e.target.value)}
        onKeyPress={(e: any) => onEnterSearch(e)}
        value={text}
      />
      <SearchIcon
        data-testid="search-icon"
        sx={{
          width: 20,
          height: 20,
          cursor: 'pointer',
          position: 'absolute',
          mt: 1,
          ...sx,
          [theme.breakpoints.down('md')]: {
            right: '12px',
          },
        }}
        color="primary"
        onClick={() => {
          setSearchText(text)
          handleSearch(text)
        }}
      />
    </BoxSearch>
  )
}

SearchCustom.propTypes = {
  placeholder: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
}
export default SearchCustom

interface SearchStyleProps {
  theme?: any
  minWidth?: number
}
const BoxSearch = styled(Box)<SearchStyleProps>(
  ({ theme, minWidth = 250 }) => ({
    position: 'relative',
    borderRadius: theme?.shape?.borderRadius,
    border: `1px solid ${theme?.palette?.text?.silver}`,
    backgroundColor: theme?.palette?.text?.white,
    width: '100%',
    minWidth,
    '&:hover': {
      backgroundColor: theme?.palette?.text?.wildSand,
    },
    marginLeft: 0,
    marginTop: 16,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      maxHeight: '37.5px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
)

const SearchInput = styled(InputBase)(({ theme }) => ({
  maxHeight: '37.5px',
  width: '90%',
  marginLeft: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    width: '85%',
  },
}))
