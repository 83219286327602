export const filterStatusItems = [
  {
    label: 'รอมอบหมายจากแอดมิน',
    name: 'waitingForReAssignChecked',
    status: 'DL_SUCCESSFULLY_SENT_ASSIGN_RULE',
  },
  {
    label: 'รอนัดหมาย',
    name: 'submittiedShortFormChecked',
    status: 'DL_ASSIGNED_MA',
  },
  {
    label: 'รอสัมภาษณ์',
    name: 'managerAcceptedInterviewChecked',
    status: 'I_ACCEPTED_INTERVIEW',
  },
  {
    label: 'รอนัดหมายและยังไม่เคยเข้าสู่ระบบ',
    name: 'submittiedShortFormInactiveChecked',
    status: 'DL_SUBMITTED_SHORT_FORM',
  },
]

export const filterItems = [
  {
    label: 'ชื่อผู้สมัคร',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    type: 'text',
  },
  {
    label: 'ช่องทาง',
    nameCheckBox: 'channelChecked',
    nameInput: 'channel',
    type: 'text',
  },
  {
    label: 'เลขที่ตัวแทนผู้จัดการ',
    nameCheckBox: 'managerCodeChecked',
    nameInput: 'managerCode',
    type: 'text',
  },
  {
    label: 'ชื่อผู้จัดการ',
    nameCheckBox: 'managerNameChecked',
    nameInput: 'managerName',
    type: 'text',
  },
  {
    label: 'วันที่สมัคร',
    placeholder: 'เลือก ระยะวันที่สมัคร',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    dateStateStart: 'startDate',
    dateStateFinish: 'endDate',
    dateStateDisplay: 'dateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'prospectStatusChecked',
    nameInput: 'prospectStatus',
    type: 'checkbox',
    list: filterStatusItems,
  },
  {
    label: 'สถานะการใช้งานของผู้สมัคร',
    nameCheckBox: 'loginStatusChecked',
    nameInput: 'loginStatus',
    type: 'checkbox',
    list: [
      {
        label: 'เคยเข้าสู่ระบบ',
        name: 'statusActiveChecked',
        status: 'ACTIVE',
      },
      {
        label: 'ไม่เคยเข้าสู่ระบบ',
        name: 'statusInactiveChecked',
        status: 'INACTIVE',
      },
    ],
  },
]
