export const getOptionsKey = ({ data, label, key }: any) => {
  if (!data.length) return []
  return data.map((item: any) => {
    let labelText = item[label]
    if (label === 'abbreviation') {
      labelText = item[label]?.toUpperCase()
    } else if (label === 'acquiredSkill') {
      labelText = item?.skillType?.skillType
        ? `${item.skillType.skillType}: ${item.acquiredSkill}`
        : item?.acquiredSkill
    } else if (label === 'fullname') {
      labelText = `${item?.firstName} ${item?.lastName}`
    } else if (label === 'fullnameTH') {
      labelText = `${item?.firstNameTH} ${item?.lastNameTH}`
    } else if (
      label === 'roadMap' ||
      label === 'levelOfLearner' ||
      label === 'otherLevelOfLearner'
    ) {
      labelText = item?.title
    }
    return { label: labelText || '', value: item[key], image: item.image }
  })
}
