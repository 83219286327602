import * as yup from 'yup'

export const formValidationSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .required('กรุณาระบุประเภทของเหตุผลการปฎิเสธนัดสัมภาษณ์"')
    .max(
      100,
      'กรุณาระบุประเภทของเหตุผลการปฎิเสธนัดสัมภาษณ์ไม่เกิน 100 ตัวอักษร'
    ),
})
