import { store } from '../../../redux/store'
import { setFieldValue } from '../../CRUD/slices'
import { callAPI } from '../../../utils/callAPI'
import { handleFetchError } from './handlerFetchError'
import { fetchDataList } from './fetchDataList'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../components/dialog/slices'

export const handleProcessDelete = async (
  uuid: string,
  mutatePropToBody: any
) => {
  startDialogLoading()

  try {
    await fetchData(uuid)
    handleSuccessfulDelete(mutatePropToBody)
  } catch (error) {
    handleFetchError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(loadingDialog())
}

const stopDialogLoading = () => {
  store.dispatch(stopLoadingDialog())
}

const fetchData = async (uuid = '') => {
  const { crudConfig } = store.getState().crud
  const url = `/crud/${uuid}`
  const headers = { 'x-type': crudConfig?.moduleId || '' }

  const response = await callAPI({
    method: 'delete',
    url,
    headers,
  })

  store.dispatch(setFieldValue({ key: 'formData', value: response }))
}

const handleSuccessfulDelete = (mutatePropToBody: any) => {
  store.dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      agreeText: 'ตกลง',
      isCloseDialog: false,
      handleConfirm: () => {
        store.dispatch(closeDialog())
        fetchDataList(mutatePropToBody).then()
      },
    })
  )
}
