export function handleAgentCode(agentCode: string): string {
  const agentCodeStr = String(agentCode)

  if (agentCodeStr.length >= 6) {
    return agentCodeStr
  }

  if (agentCodeStr.length > 6) {
    return agentCodeStr
  }

  return `${'0'.repeat(6 - agentCodeStr.length)}${agentCodeStr}`
}
