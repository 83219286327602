export const filterItems = [
  {
    label: 'Agent Code',
    nameCheckBox: 'agentCodeChecked',
    nameInput: 'agentCode',
    type: 'text',
  },
  {
    label: 'Manager Name',
    nameCheckBox: 'managerNameChecked',
    nameInput: 'managerName',
    type: 'text',
  },
  {
    label: 'Working Area',
    nameCheckBox: 'workingAreaChecked',
    nameInput: 'workingArea',
    type: 'text',
  },
  {
    label: 'Province',
    nameCheckBox: 'provinceChecked',
    nameInput: 'province',
    type: 'text',
  },
]
