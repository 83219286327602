import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import updateLocale from 'dayjs/plugin/updateLocale'

export function getFormatDate(date: string, format = 'DD/MM/YYYY') {
  dayjs.extend(updateLocale)
  dayjs.extend(buddhistEra)
  if (!dayjs(date).isValid()) return null
  dayjs.updateLocale('en', {
    months: [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ],
  })
  return dayjs(date).format(format)
}
