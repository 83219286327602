import { combineReducers } from '@reduxjs/toolkit'
import detail from '../detail/slices'
import managerList from '../detail/features/Form/Assign/slices'

const prospectReducer = combineReducers({
  detail,
  managerList,
  // assign,
})
export default prospectReducer
