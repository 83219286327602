import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import Row from '../components/Row'
import { Theme } from '@mui/material/styles'

export const dataGridRemoveOutline = {
  '.MuiDataGrid-cell:focus , .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
}

export const StyledDataGridNoSelectAll = styled(DataGrid)(() => ({
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
    {
      display: 'none',
    },
}))

export const boxInModalStyle = (theme: Theme) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: 300, // Mobile devices
      sm: 500, // Small devices
      md: 768, // Medium devices
      lg: 900, // Large devices
      xl: 1200, // Extra-large devices
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
  }
}

const customPaidAmt = {
  '& .MuiDataGrid-row': {
    ':hover': {
      '& .paidamt.lessThanTotalamt.MuiDataGrid-cell--editable': {
        backgroundColor: '#ffa9a9',
      },
    },
    '& .paidamt.lessThanTotalamt.MuiDataGrid-cell--editable': {
      backgroundColor: '#ff8585',
    },
  },
  '& .MuiDataGrid-row.Mui-selected': {
    ':hover': {
      '& .paidamt.lessThanTotalamt.MuiDataGrid-cell--editable': {
        backgroundColor: '#ffa9a9',
      },
    },
    '& .paidamt.lessThanTotalamt.MuiDataGrid-cell--editable': {
      backgroundColor: '#ff5757',
    },
  },
}
export const StyledDataGridNoSelectAllCustomPaidAmt = styled(
  StyledDataGridNoSelectAll
)(() => customPaidAmt)
export const StyledDataGridCustomPaidAmt = styled(DataGrid)(() => customPaidAmt)

export const StyleInputNumber = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    textAlign: 'right',
  },
}))

export const StyledButtonMini = styled(Button)({
  minWidth: 20,
  padding: '4px 12px',
})

export const StyledVatSellPanelDocuVatSell = styled(Row)({
  marginTop: -20,
})
