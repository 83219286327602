import _ from 'lodash'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from '../model'
import { getPermission, getStaffList } from '../../../services/utils/permission'

const permission = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    startLoading: (state: any) => {
      state.isLoading = true
    },
    stopLoading: (state: any) => {
      state.isLoading = false
    },
    startPageLoading: (state: any) => {
      state.isPageLoading = true
    },
    stopPageLoading: (state: any) => {
      state.isPageLoading = false
    },
    setInitialForm: (state: any, { payload }: PayloadAction<any>) => {
      state.data = payload
    },
    setChange: (
      state: any,
      { payload }: PayloadAction<{ key: string; value: any }>
    ) => {
      const { key, value } = payload
      _.set(state.data, key, value)
    },
    setAddStaff: (state: any, { payload }: PayloadAction<string>) => {
      const findStaff = state.staffList.find(
        (item: any) => item.uuid === payload
      )
      if (findStaff) {
        state.data.staff.push(findStaff)
      }
    },
    setDeleteStaff: (state: any, { payload }: PayloadAction<number>) => {
      state.data.staff.splice(payload, 1)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermission.fulfilled, (state: any, { payload }) => {
        state.permissionList = payload
      })
      .addCase(getPermission.rejected, (state: any, action: any) => {
        state.error = action.meta?.message
      })
      .addCase(getStaffList.fulfilled, (state: any, { payload }) => {
        state.staffList = _.get(payload, 'result', [])
      })
      .addCase(getStaffList.rejected, (state: any, action: any) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startPageLoading,
  stopPageLoading,
  setInitialForm,
  setChange,
  setAddStaff,
  setDeleteStaff,
} = permission.actions

export default permission.reducer
