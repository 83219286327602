import React from 'react'
import { useDispatch, useSelector } from '../../../redux/store'
import { selectCrudConfig, selectFormDataStatus } from '../../../redux/selector'
import { setFieldValue } from '../../CRUD/slices'
import { useInputField } from '../hooks/useInputField'
import { enumStatus } from '../enum/status'
import {
  Box,
  type SxProps,
  type Theme,
  Switch,
  Typography,
} from '@mui/material'

interface CustomSwitchInLineProps {
  fieldName: string
  title?: string
  disabled?: boolean
  required?: boolean
  boxSx?: SxProps<Theme>
}

const CustomSwitchInLine: React.FC<CustomSwitchInLineProps> = ({
  fieldName,
  title = 'สถานะ',
  disabled,
  required,
  boxSx = {},
}) => {
  const dispatch = useDispatch()
  const { value } = useInputField(fieldName)
  const crudConfig = useSelector(selectCrudConfig)
  const formDataStatus = useSelector(selectFormDataStatus)

  const setStatus = (checked: boolean) =>
    checked ? enumStatus.ACTIVE : enumStatus.INACTIVE

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch(
      setFieldValue({
        key: `formData.${fieldName}`,
        value: setStatus(e.target.checked),
      })
    )
  }

  function isStatusActive() {
    return value === enumStatus.ACTIVE
  }

  function getLabelText(value: string) {
    return value === enumStatus.ACTIVE
      ? 'เปิดการใช้งาน (Active)'
      : 'ปิดการใช้งาน (Inactive)'
  }

  const isDisabled = formDataStatus === 'DELETED' || disabled

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="body2" color="text.primary">
          {title}
        </Typography>
        {required && (
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
            *
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Switch
            id={`${crudConfig?.moduleSlug}-${fieldName}`}
            name={fieldName}
            required={required}
            checked={isStatusActive()}
            disabled={isDisabled}
            onChange={handleOnChange}
          />
          <Typography variant="body1" sx={{ lineHeight: 2 }}>
            {getLabelText(value)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CustomSwitchInLine
