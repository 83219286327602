import {
  resetTablePage,
  setFilterProp,
  setFilterTotal,
  setSearchText,
  setToggleFilterDrawer,
} from '../../Table/redux/index'
import { store } from '../../../redux/store'

export const handleFiltering =
  (mutateFilterSateToProp: any) => (dispatch: any) => {
    const { filterState } = store.getState().table
    dispatch(setSearchText(''))
    dispatch(setToggleFilterDrawer(false))
    const filterProp = mutateFilterSateToProp()
    dispatch(setFilterProp(filterProp))
    dispatch(setFilterTotal(filterState))
    dispatch(resetTablePage())
  }
