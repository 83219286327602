export const headerCells = [
  {
    id: 'status',
    label: 'Status',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '90px',
  },
  {
    id: 'title',
    label: 'หัวข้อ',
  },
  {
    id: 'description',
    label: 'รายละเอียด',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    editPath: '/offline-sub-source/form',
    hideDelete: false,
  },
]
