import * as yup from 'yup'

export const formValidationSchema = yup.object({
  title: yup
    .string()
    .required('กรุณาระบุหัวข้อ')
    .max(100, 'กรุณาระบุหัวข้อไม่เกิน 100 ตัวอักษร'),
  description: yup
    .string()
    .required('กรุณาระบุรายละเอียด')
    .max(100, 'กรุณาระบุรายละเอียดไม่เกิน 100 ตัวอักษร'),
})
