import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  filterStaffProfile,
  permission,
  permissionGroup,
} from '../../utils/apiPath'
import { callAPI } from '../../utils/callAPI'

interface RejectWithValue {
  rejectWithValue: (value: any, error?: any) => any
}

export const getPermissionById = createAsyncThunk(
  'permission/getPermissionById',
  async (uuid: string, { rejectWithValue }: RejectWithValue) => {
    try {
      return await callAPI({
        method: 'get',
        url: `${permission}/${uuid}`,
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getPermission = createAsyncThunk(
  'permission/getPermission',
  async (_, { rejectWithValue }: RejectWithValue) => {
    try {
      return await callAPI({
        method: 'get',
        url: `${permission}`,
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getStaffList = createAsyncThunk(
  'permission/getStaffList',
  async (_, { rejectWithValue }: RejectWithValue) => {
    try {
      const body = {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        agentCode: '',
        firstNameTH: '',
        lastNameTH: '',
        jobTitle: '',
        department: '',
        station: '',
        reportTo: '',
        position: '',
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'id',
        status: ['ACTIVE'],
      }
      return await callAPI({
        method: 'post',
        url: `${filterStaffProfile}`,
        body: body,
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

interface PermissionGroupPayload {
  method: 'post' | 'put'
  body: any
}

export const putPostPermissionGroup = createAsyncThunk(
  'permission/putPostPermissionGroup',
  async (
    payload: PermissionGroupPayload,
    { rejectWithValue }: RejectWithValue
  ) => {
    try {
      const { method, body } = payload
      return await callAPI({
        method: method,
        url: `${permissionGroup}`,
        body: body,
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
