import find from 'lodash/find'
import { createSelector } from 'reselect'
import { type RootState, useSelector } from '../../../redux/store'
import _ from 'lodash'

export const useInputField = (FIELD_NAME: string) => {
  const selectCrudForm = createSelector(
    [(state: RootState) => state.crud],
    (crud) => ({
      value: crud.formData[FIELD_NAME],
      formData: crud.formData,
      error: find(crud.formErrors, { path: FIELD_NAME }),
    })
  )

  const { value, error, formData } = useSelector(selectCrudForm)

  let thisValue = null
  if (!value) {
    thisValue = _.get(formData, FIELD_NAME, null)
  }

  const isError = !_.isEmpty(error)
  return {
    value: thisValue ? thisValue : value,
    message: isError && error.message,
    isError,
  }
}
