import { useEffect, useCallback, useState } from 'react'
import localforage from 'localforage'
import _ from 'lodash'
import Snackbar from '@mui/material/Snackbar'
import useTheme from '@mui/material/styles/useTheme'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import { CContainer } from '../../../../../components/CRUD/components/Container'
import { CHeader } from '../../../../../components/CRUD/components/Header'
import { pageConfig as initialPageConfig } from '../../pageConfig'
import { ListHeaderAction } from './components/ListHeaderAction'
import FilterDrawerAction from './components/FilterDrawerAction'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { breadCrumb } from './model/breadCrumb'
import { handleChange } from '../../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../../components/CRUD/handler/handleFiltering'
import { fetchDataList } from './handler/fetchDataList'
import useHistory from '../../../../../utils/useHistory'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'
import CustomDropdown, {
  CustomDropdownProps,
} from '../../../../../components/Input/CustomDropdown'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { useDispatch, useSelector } from '../../../../../redux/store'
import {
  selectTableSelected,
  selectPaginationTable,
  selectCrudConfig,
  selectCrudFormData,
  selectFilterProp,
} from '../../../../../redux/selector'
import { setOrder, setSubActions } from '../../../../../components/Table/redux'
import {
  closeDialog,
  openDialog,
} from '../../../../../components/dialog/slices'
import { callAPI } from '../../../../../utils/callAPI'
import { managerStatusPath } from '../../../../../utils/apiPath'
import { statusOptions } from './model/headerCellsList'

const DropdownStyle = styled((props: CustomDropdownProps) => (
  <CustomDropdown {...props} />
))(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: theme.spacing(1), // 8px
    borderRadius: '4px',
  },

  '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}))

export const ManagerQueueList = () => {
  const { page, sort, order, limit } = useSelector(selectPaginationTable)
  const crudConfig = useSelector(selectCrudConfig)

  const dispatch = useDispatch()

  const filterProp = useSelector(selectFilterProp)

  useEffect(() => {
    initialConfig(initialPageConfig)
    dispatch(setSubActions(<StatusAction />))
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // Set default order
    dispatch(
      setOrder({
        order: 'asc',
        sort: 'workingArea',
      })
    )

    return () => {
      dispatch(setSubActions(null)) // * Reset sub action when component unmounted
      dispatch(
        // * Reset sorting to default state when component unmounted
        setOrder({
          order: 'desc',
          sort: 'updatedAt',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory()

  useEffect(() => {
    if (!_.isUndefined(crudConfig)) {
      fetchDataList(mutateFilterPropToBody)
    }
  }, [limit, order, page, sort, crudConfig, filterProp])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <CContainer
      drawer={
        <FilterDrawerAction
          handleChange={handleChange}
          handleFiltering={() => handleFiltering(mutateFilterSateToProp)}
          filterItems={filterItems}
          defaultFilter={defaultFilter}
        />
      }
      header={
        <CHeader
          title={crudConfig.moduleTitleFull}
          breadcrumbs={breadCrumb}
          action={
            <ListHeaderAction
              onClick={() => {
                localforage.removeItem('persist:root').then()
                history.push(`/manager/import-manager`)
              }}
            />
          }
        />
      }
    />
  )
}

function StatusAction() {
  const tableSelected = useSelector(selectTableSelected)
  const formData = useSelector(selectCrudFormData)

  const [isSuccess, setIsSuccess] = useState(false)

  const theme = useTheme()

  const dispatch = useDispatch()

  const handleSubmit = useCallback(async () => {
    await callAPI({
      url: managerStatusPath,
      method: 'POST',
      body: {
        list: tableSelected,
        status: formData?.status,
        order: 'DESC',
        sort: 'updatedAt',
      },
      onSuccess() {
        setIsSuccess(true)
        dispatch(closeDialog())
      },
    })
  }, [dispatch, formData?.status, tableSelected])

  const handleClose = () => {
    setIsSuccess(false)
  }

  const handleClick = useCallback(() => {
    dispatch(
      openDialog({
        title: 'ยึนยันบันทึก',
        message: 'คุณต้องการบันทึกหรือไม่',
        handleConfirm() {
          handleSubmit()
        },
        isCloseDialog: false,
        type: 'confirm',
      })
    )
  }, [dispatch, handleSubmit])

  return (
    <>
      <Stack direction="row" alignItems="center" gap={2}>
        <DropdownStyle
          fullWidth
          options={statusOptions}
          label="Status"
          value={formData?.status ?? null}
          boxSx={{ width: '250px', ml: theme.spacing(2), mb: 0 }}
          crud="status"
        />

        <Button
          variant="outlined"
          onClick={handleClick}
          disabled={!formData?.status || !tableSelected.length}
          sx={{
            alignSelf: 'flex-end',
            paddingY: theme.spacing(0.40625),
            textTransform: 'capitalize',
          }}
        >
          เปลี่ยนแปลง Status
        </Button>
      </Stack>

      {/* SNACKBAR SUCCESS */}
      <Snackbar
        open={isSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
        message="บันทึกข้อมูลสำเร็จ"
        key="bottom-right"
        sx={{
          '&.MuiSnackbar-root .MuiSnackbarContent-root': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.black,
          },
        }}
        transitionDuration={1000}
      />
    </>
  )
}
