import _ from 'lodash'

export const postBodyMutation = (data: any) => {
  return {
    uuid: _.get(data, 'uuid', undefined),
    id: _.get(data, 'id', ''),
    email: _.get(data, 'email', ''),
    firstName: _.get(data, 'firstName', ''),
    lastName: _.get(data, 'lastName', ''),
  }
}
