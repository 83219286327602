import { useAuth } from 'react-oidc-context'
import React, { useEffect } from 'react'
import { Container, Typography, Box } from '@mui/material'

const Home: React.FC = () => {
  const { user, isAuthenticated } = useAuth()

  const env = window.__env__
  const appUrl = env.ENV

  useEffect(() => {
    if (isAuthenticated) {
      // Store the use in local storage when authenticated
      localStorage.setItem('authUser', JSON.stringify(user))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return (
    <Container maxWidth="lg">
      <Box>
        <Typography variant="h5" gutterBottom>
          Dashboard is coming at : UAT Part 2<p>ENV: {appUrl}</p>
        </Typography>
      </Box>
    </Container>
  )
}

export default Home
