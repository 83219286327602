interface TableState {
  isLoading: boolean
  isCheckBox: boolean
  rows: any[] // Replace 'any[]' with the actual type of your rows
  headCells: any[] // Replace 'any[]' with the actual type of your head cells
  allCount: number
  defaultRowsPerPage: { label: string | number; value: number }
  defaultPage: number
  page: number
  tablePage: number
  selected: any[] // Replace 'any[]' with the actual type of your selected items
  numSelected: number
  order: 'asc' | 'desc'
  sort: string
  defaultSortId: string
  defaultSort: { state: number; id: string; active: boolean }
  limit: string
  rowsPerPage: { label: string; value: number }
  isShowPagination: boolean
}

interface FilterState {
  isFilterDrawer: boolean
  status: any[] // Replace 'any[]' with the actual type of your status items
  filterProp: any // Replace 'any' with the actual type of your filter property
  filterTotal: number
  quickFilter: any[] // Replace 'any[]' with the actual type of your quick filters
  other: any // Replace 'any' with the actual type of your other properties
}

interface State {
  search: {
    placeholder: string
    searchText: string
  }
  table: TableState
  filter: FilterState
  handleSearch: (() => void) | null
  onDownload: (() => void) | null
  onDelete: (() => void) | null
  onQuickFilter: (() => void) | null
  onChangeStatus: (() => void) | null
  customStyle: Record<string, any> // Replace 'Record<string, any>' with the actual type of your custom style
  customToolbar: (() => void) | null
}

export const initialState: State = {
  search: { placeholder: '', searchText: '' },
  table: {
    isLoading: false,
    isCheckBox: false,
    rows: [],
    headCells: [],
    allCount: 0,
    defaultRowsPerPage: { label: '5', value: 5 },
    defaultPage: -1,
    page: -1,
    tablePage: 0,
    selected: [],
    numSelected: 0,
    order: 'desc',
    sort: 'updatedAt',
    defaultSortId: 'updatedAt',
    defaultSort: { state: 0, id: '', active: false },
    limit: '100',
    rowsPerPage: { label: '100', value: 100 },
    isShowPagination: true,
  },
  filter: {
    isFilterDrawer: false,
    status: [],
    filterProp: null,
    filterTotal: 0,
    quickFilter: [],
    other: null,
  },
  handleSearch: null,
  onDownload: null,
  onDelete: null,
  onQuickFilter: null,
  onChangeStatus: null,
  customStyle: {},
  customToolbar: null,
}

export type InitialStateBasic = {
  isLoading: boolean
  rows: {
    [key: string]: any
  }[]
  headCells: {
    [key: string]: any
  }[]
  allCount: number
  defaultRowsPerPage: {
    label: string | `${number}`
    value: number
  }
  defaultPage: number
  page: number
  tablePage: number
  order: 'desc' | 'asc'
  sort: string
  defaultSortId: string
  defaultSort: {
    state: number
    id: string
    active: boolean
  }
  limit: `${number}`
  rowsPerPage: {
    label: string | number
    value: number
  }
  isShowPagination: boolean
}

export const initialStateBasic: InitialStateBasic = {
  isLoading: false,
  rows: [],
  headCells: [],
  allCount: 0,
  defaultRowsPerPage: { label: '5', value: 5 },
  defaultPage: 1,
  page: 1,
  tablePage: 0,
  order: 'desc',
  sort: 'updatedAt',
  defaultSortId: 'updatedAt',
  defaultSort: { state: 0, id: '', active: false },
  limit: '100',
  rowsPerPage: { label: '100', value: 100 },
  isShowPagination: true,
}
