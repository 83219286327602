import _ from 'lodash'

export const getValue = (
  obj: any,
  key: string,
  def: any | null = null
): any => {
  let value = _.get(obj, key, def)
  if (_.isArray(obj)) {
    value = _.get(obj, `[0].${key}`, def)
  }
  if (typeof value === 'number') return value
  if (_.isArray(value) || _.isObject(value)) return value

  return _.isNull(value) ? null : _.trim(value)
}
export const getValueStr = (obj: any, key: string): string => {
  let value = _.get(obj, key, '')
  if (_.isArray(obj)) {
    value = _.get(obj, `[0].${key}`, '')
  }
  if (_.isNumber(value)) return String(value)
  if (_.isArray(value) || _.isObject(value)) return JSON.stringify(value)

  return _.isNull(value) || _.isUndefined(value) ? '' : _.trim(String(value))
}
export const getValueNumber = (obj: any, key: string): number => {
  let value = _.get(obj, key, 0)
  if (_.isArray(obj)) {
    value = _.get(obj, `[0].${key}`, 0)
  }
  if (_.isNull(value) || _.isUndefined(value) || _.isNaN(value)) return 0

  const _number = parseFloat(value)
  return isNaN(_number) ? 0 : _number
}
