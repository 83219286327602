import { useEffect, useState } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'

// redux
import { useDispatch } from '../../../redux/store'
import { clearUploadedData } from '../../../components/Upload/slices'

import UploadFileStep from './UploadFileStep'
import CheckInformationStep from './CheckInformationStep'
import ImportDataStep from './ImportDataStep'
import { CPageContainer } from '../../../components/CRUD/components/pageContainer/index'
import Header from './Header'

const StepperStyle = styled(Stepper)({
  '&.MuiStepper-horizontal': {
    '& .MuiStep-horizontal': {
      '&:first-of-type': {
        paddingLeft: 0,
      },
      '&:last-of-type': {
        paddingRight: 0,
      },
    },
  },
})

const StepLabelStyle = styled(StepLabel)({
  '& .MuiStepLabel-iconContainer .MuiSvgIcon-root': {
    width: 40,
    height: 40,
    fontSize: 20,
  },

  '& .MuiStepLabel-labelContainer .MuiStepLabel-label': {
    fontSize: 20,
  },
})

const steps = ['อัปโหลดไฟล์', 'ตรวจสอบข้อมูล', 'นำเข้าข้อมูล']

export default function ImportManager() {
  const [activeStep, setActiveStep] = useState(0)

  const dispatch = useDispatch()

  const theme = useTheme()

  const importStepNumber = new Map([
    [0, <UploadFileStep key="uploadFile" setActiveStep={setActiveStep} />],
    [1, <CheckInformationStep key="checkInfo" setActiveStep={setActiveStep} />],
    [2, <ImportDataStep key="importData" setActiveStep={setActiveStep} />],
  ])

  useEffect(() => {
    return () => {
      dispatch(clearUploadedData()) // * clear upload data when the component unmounts
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // * this runs only on unmount

  return (
    <CPageContainer
      header={<Header />}
      content={
        <>
          <Typography variant="h6" mb={3}>
            การนำเข้าข้อมูล
          </Typography>

          <StepperStyle activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabelStyle>{label}</StepLabelStyle>
              </Step>
            ))}
          </StepperStyle>

          {/* Render step content */}
          {importStepNumber.get(activeStep)}
        </>
      }
      sxProps={{ boxShadow: `0px 0px 4px 0px ${theme.palette.common.black}33` }}
    />
  )
}
