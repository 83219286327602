import axios from 'axios'
import isEmpty from 'lodash/isEmpty'

import { ERROR_NETWORK, ERRORS_STATUS } from '../constants/status'
import { handleDispatchException } from '../utils/dispatchError'

const axiosInstance = axios.create({
  baseURL: window.__env__.REACT_APP_API_URL,
  headers: {
    Pragma: 'no-cache',
  },
})

axiosInstance.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem('token')
    if (!isEmpty(token)) {
      req.headers['Content-Type'] = 'application/json'
      req.headers.Authorization = token
    }

    return req
  },
  (error) => Promise.reject(new Error(error))
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log('Status error from API => ', error.response?.status)
    console.log('Error => ', JSON.stringify(error?.response, null, 2))

    const { statusText } = error?.response ?? {}

    if (
      ERRORS_STATUS.includes(error.response?.status) ||
      error.code === ERROR_NETWORK
    ) {
      handleDispatchException()
    }

    return Promise.reject(
      error ?? new Error(statusText) ?? new Error('ระบบขัดข้อง')
    )
  }
)

export default axiosInstance
