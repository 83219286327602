import get from 'lodash/get'
import dayjs from 'dayjs'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'

export function postBodyMutation(data: Record<string, string>) {
  const status = get(data, 'status', enumStatus.INACTIVE)
  const startDate = formatDate(get(data, 'startDate', null))
  const endDate = formatDate(get(data, 'endDate', null))
  const renewDate = formatDate(get(data, 'renewDate', null))

  return {
    uuid: get(data, 'uuid', undefined),
    id: get(data, 'id', ''),
    name: 'user test',
    text: get(data, 'text', ''),
    status: status && status !== '' ? status : enumStatus.INACTIVE,
    startDate,
    endDate,
    renewDate,
  }
}

const formatDate = (value?: string | number | null) => {
  const format = 'MM/DD/YYYY'
  let dateValue = value

  if (dateValue) dateValue = dayjs(new Date(dateValue)).format(format)
  else dateValue = null

  return dateValue
}
