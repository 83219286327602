export const handleKeyValue = (
  obj: any,
  filterTotal: number,
  newTotal: number
): number => {
  let total = filterTotal

  if (obj.toString().includes('Time')) total++ // date object

  for (const key in obj) {
    if (typeof obj[key] === 'object' && newTotal === 0) {
      newTotal = handleKeyValue(obj[key], filterTotal, newTotal)
      total = newTotal > 0 ? total + 1 : total
    } else if (typeof obj[key] === 'boolean' && obj[key] && newTotal === 0) {
      total++
      break
    }
  }
  return total
}
