import * as yup from 'yup'

export const formValidationSchema = yup.object({
  title: yup
    .string()
    .required('กรุณาระบุชื่อแพลตฟอร์ม')
    .max(100, 'กรุณาระบุชื่อแพลตฟอร์มไม่เกิน 100 ตัวอักษร'),
  description: yup
    .string()
    .required('กรุณาระบุรายละเอียดของแพลตฟอร์ม')
    .max(100, 'กรุณาระบุรายละเอียดของแพลตฟอร์มไม่เกิน 100 ตัวอักษร'),
})
