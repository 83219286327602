import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ManagerGrid, model } from './model'

const slice = createSlice({
  name: 'managerLookup',
  initialState: model,
  reducers: {
    setManagerGrid: (state, action: PayloadAction<ManagerGrid[]>) => {
      state.grid = action.payload.map((item, index) => {
        return { ...item, id: index }
      })
    },
    setIsModalOpen(state, action: PayloadAction<boolean>) {
      state.grid = []
      state.isModalOpen = action.payload
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export const { setManagerGrid, setIsModalOpen, setIsLoading } = slice.actions

export default slice.reducer
