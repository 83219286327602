import { store } from '../../../../../../redux/store'
import get from 'lodash/get'

export function mutateFilterPropsToBody() {
  const { filterProp } = store.getState().table.filter

  return {
    name: get(filterProp, 'name', ''),
    channelEN: get(filterProp, 'channelEN', ''),
    channelTH: get(filterProp, 'channelTH', ''),
    status: get(filterProp, 'status', []),
  }
}
