import _ from 'lodash'
import {
  getPermission,
  getPermissionById,
  getStaffList,
  putPostPermissionGroup,
} from '../../services/utils/permission'
import {
  stopLoadingDialog,
  openDialog,
  loadingDialog,
} from '../../components/dialog/slices'
import {
  startPageLoading,
  setInitialForm,
  stopPageLoading,
} from '../../components/Permission/slices'
import { stopLoading } from '../../components/Table/redux'
import { initialState } from '../../components/Permission/model'
export const handleFetchData = (props: any) => async (dispatch: any) => {
  const { onEdit, setOnEdit, isEditForm, uuid } = props
  if (onEdit) return

  dispatch(getPermission())
  dispatch(getStaffList())

  if (isEditForm) {
    dispatch(startPageLoading())
    await dispatch(fetchPermissionById(uuid))
  } else {
    dispatch(setInitialForm(initialState.data))
  }

  setOnEdit(true)
}

export const fetchPermissionById = (uuid: any) => async (dispatch: any) => {
  const response = await dispatch(getPermissionById(uuid))
  const resType = _.get(response, 'type', '')

  if (resType == 'permission/getPermissionById/fulfilled') {
    const data = _.get(response, 'payload', null)
    const convertPermission: any = []
    const arrayPermissionGroup: any = []
    let permissionGroup = ''
    const permission = _.get(data, 'permission', [])
    const status =
      _.get(data, 'status', '').toString().toUpperCase() === 'ACTIVE'

    if (permission.length) {
      permission.map((item: any) => {
        convertPermission.push(item.uuid)
        arrayPermissionGroup.push(item.id)
      })
    }

    if (arrayPermissionGroup.length) {
      arrayPermissionGroup.sort(function (a: any, b: any) {
        return a - b
      })
      permissionGroup = arrayPermissionGroup.join(',')
    }

    const initialValues = {
      ...data,
      title: _.get(data, 'groupName', ''),
      status,
      permission: convertPermission,
      permissionGroup: permissionGroup,
    }

    dispatch(setInitialForm(initialValues))
  }

  dispatch(stopPageLoading())
}

export const submitPermission =
  (values: any, isEditForm: any) => async (dispatch: any) => {
    const method = isEditForm ? 'put' : 'post'
    const staff = _.get(values, 'staff', []).map((item: any) => ({
      uuid: item.uuid,
    }))
    const body = isEditForm
      ? {
          uuid: values.uuid,
          groupName: values.groupName,
          permission: values.permission,
          staff,
        }
      : {
          groupName: values.groupName,
          permission: values.permission,
          staff,
        }

    const response = await dispatch(putPostPermissionGroup({ method, body }))
    dispatch(stopLoadingDialog())
    const resType = _.get(response, 'type', '')
    if (resType == 'permission/putPostPermissionGroup/fulfilled') {
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'บันทึกสำเร็จ',
          isCloseDialog: false,
          handleConfirm: () => (window.location.href = '/manage/permission'),
        })
      )
    }
    dispatch(stopLoading())
  }

export const handleSubmitForm =
  (values: any, isEditForm: any) => (dispatch: any) => {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกข้อมูล',
        message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        isCloseDialog: false,
        handleConfirm: () => {
          dispatch(loadingDialog())
          dispatch(submitPermission(values, isEditForm))
        },
      })
    )
  }
