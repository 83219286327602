import Home from '../pages/home'
import Login from '../pages/login'

import { RejecteAfterInterviewListPage } from '../pages/master-data/reject-after-interview/list'
import { RejecteAfterInterviewFormPage } from '../pages/master-data/reject-after-interview/form/[uuid]'

import { RejectAppointmentListPage } from '../pages/master-data/reject-appointment/list'
import { RejectAppointmentFormPage } from '../pages/master-data/reject-appointment/form/[uuid]'

import KnowUsFromChannelListPage from '../pages/master-data/know-us-from-channel/list'
import KnowUsFromChannelFormPage from '../pages/master-data/know-us-from-channel/form/[uuid]'

import SocialSubSourceListPage from '../pages/master-data/social-sub-source/list'
import SocialSubSourceFormPage from '../pages/master-data/social-sub-source/form/[uuid]'

import OfflineSubSourceListPage from '../pages/master-data/offline-sub-source/list'
import OfflineSubSourceFormPage from '../pages/master-data/offline-sub-source/form/[uuid]'

import ReasonRequestMemoListPage from '../pages/master-data/reason-request-memo/list'
import ReasonRequestMemoFormPage from '../pages/master-data/reason-request-memo/form/[uuid]'

import { EventSubSourceListPage } from '../pages/master-data/event-sub-source/list'
import { EventSubSourceFormPage } from '../pages/master-data/event-sub-source/form/[uuid]'

import { ReasonsDropProspectByDCListPage } from '../pages/master-data/reasons-drop-prospect-by-dc/list'
import { ReasonsDropProspectByDCFormPage } from '../pages/master-data/reasons-drop-prospect-by-dc/form/[uuid]'

import { RamProfileListPage } from '../pages/master-data/ram-profile/list'
import { RamProfileFormPage } from '../pages/master-data/ram-profile/form/[uuid]'

import { SRamProfileListPage } from '../pages/master-data/sram-profile/list'
import { SRamProfileFormPage } from '../pages/master-data/sram-profile/form/[uuid]'

import { CADTOProfileListPage } from '../pages/master-data/cadto-profile/list'
import { CADTOProfileFormPage } from '../pages/master-data/cadto-profile/form/[uuid]'

import ProspectList from '../pages/prospect/list'
import CandidateImportData from '../pages/prospect/import/import'

import { ManagerQueueListPage } from '../pages/assign-rule/manager-queue/list'

import NearbyAreaFormPage from '../pages/assign-rule/nearby-area'

import ImportManagerPage from '../pages/manager/import-manager'
import ProspectDetailPage from '../pages/prospect/detail/[uuid]'
import ImportCandidateListPage from '../pages/prospect/import'

import StaffProfileListPage from '../pages/manage/staff-profile/list'
import { StaffProfileFormPage } from '../pages/manage/staff-profile/form/[uuid]'

import PermissionList from '../pages/manage/permission/list'
import AddPermission from '../pages/manage/permission/form'
import ViewPermission from '../pages/manage/permission/[id]'
import EditPermission from '../pages/manage/permission/edit/[id]'

interface Route {
  path: string
  component: React.FC
  exact?: boolean
  title?: string
}

type RouteList = Route[]

const routes: RouteList = [
  // { path: '/', component: Home, exact: true, title: 'home' },
  { path: '/home', component: Home },
  { path: '/', component: Login },

  { path: '/reject-after-interview', component: RejecteAfterInterviewListPage },
  {
    path: '/reject-after-interview/form',
    component: RejecteAfterInterviewFormPage,
  },
  {
    path: '/reject-after-interview/form/:uuid',
    component: RejecteAfterInterviewFormPage,
  },

  { path: '/reject-appointment', component: RejectAppointmentListPage },
  { path: '/reject-appointment/form', component: RejectAppointmentFormPage },
  {
    path: '/reject-appointment/form/:uuid',
    component: RejectAppointmentFormPage,
  },

  { path: '/know-us-from-channel', component: KnowUsFromChannelListPage },
  { path: '/know-us-from-channel/form', component: KnowUsFromChannelFormPage },
  {
    path: '/know-us-from-channel/form/:uuid',
    component: KnowUsFromChannelFormPage,
  },

  { path: '/social-sub-source', component: SocialSubSourceListPage },
  { path: '/social-sub-source/form', component: SocialSubSourceFormPage },
  {
    path: '/social-sub-source/form/:uuid',
    component: SocialSubSourceFormPage,
  },

  { path: '/offline-sub-source', component: OfflineSubSourceListPage },
  { path: '/offline-sub-source/form', component: OfflineSubSourceFormPage },
  {
    path: '/offline-sub-source/form/:uuid',
    component: OfflineSubSourceFormPage,
  },

  { path: '/reason-request-memo', component: ReasonRequestMemoListPage },
  { path: '/reason-request-memo/form', component: ReasonRequestMemoFormPage },
  {
    path: '/reason-request-memo/form/:uuid',
    component: ReasonRequestMemoFormPage,
  },

  { path: '/event-sub-source', component: EventSubSourceListPage },
  { path: '/event-sub-source/form', component: EventSubSourceFormPage },
  { path: '/event-sub-source/form/:uuid', component: EventSubSourceFormPage },

  {
    path: '/reasons-drop-prospect-by-dc',
    component: ReasonsDropProspectByDCListPage,
  },
  {
    path: '/reasons-drop-prospect-by-dc/form',
    component: ReasonsDropProspectByDCFormPage,
  },
  {
    path: '/reasons-drop-prospect-by-dc/form/:uuid',
    component: ReasonsDropProspectByDCFormPage,
  },

  { path: '/ram-profile', component: RamProfileListPage },
  { path: '/ram-profile/form', component: RamProfileFormPage },
  { path: '/ram-profile/form/:uuid', component: RamProfileFormPage },

  { path: '/sram-profile', component: SRamProfileListPage },
  { path: '/sram-profile/form', component: SRamProfileFormPage },
  { path: '/sram-profile/form/:uuid', component: SRamProfileFormPage },

  { path: '/cadto-profile', component: CADTOProfileListPage },
  { path: '/cadto-profile/form', component: CADTOProfileFormPage },
  { path: '/cadto-profile/form/:uuid', component: CADTOProfileFormPage },

  { path: '/manager-queue', component: ManagerQueueListPage },
  { path: '/manager/import-manager', component: ImportManagerPage },

  { path: '/nearby-area', component: NearbyAreaFormPage },

  //prospect menu
  { path: '/prospect', component: ProspectList },
  { path: '/prospect/detail/:uuid', component: ProspectDetailPage },
  // candidate
  { path: '/candidate', component: ImportCandidateListPage },
  { path: '/candidate/import', component: CandidateImportData },

  // manage
  { path: '/manage/staff-profile', component: StaffProfileListPage },
  { path: '/manage/staff-profile/form', component: StaffProfileFormPage },
  { path: '/manage/staff-profile/form/:uuid', component: StaffProfileFormPage },

  {
    path: '/manage/permission',
    component: PermissionList,
  },
  {
    path: '/manage/permission/form',
    component: AddPermission,
  },
  {
    path: '/manage/permission/:id',
    component: ViewPermission,
  },
  {
    path: '/manage/permission/edit/:id',
    component: EditPermission,
  },
]

export default routes
