import get from 'lodash/get'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'

export function getBodyMutation(data: Record<string, string> = {}) {
  return {
    id: get(data, 'id', ''),
    uuid: get(data, 'uuid', undefined),
    channelEN: get(data, 'channelEN', ''),
    channelTH: get(data, 'channelTH', ''),
    status: get(data, 'status', enumStatus.INACTIVE),
    createdBy: get(data, 'createdBy', ''),
    updatedBy: get(data, 'updatedBy', ''),
    createdAt: get(data, 'createdAt', ''),
    updatedAt: get(data, 'updatedAt', ''),
  }
}
