import * as yup from 'yup'
import Stack from '@mui/material/Stack'
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
} from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Button from '@mui/material/Button'
import uniqBy from 'lodash/unionBy'
import isEmpty from 'lodash/isEmpty'

import { themeColor } from '../../../styles/themes/themeData'
import {
  dispatch as appDispatch,
  useDispatch,
  useSelector,
  store,
} from '../../../redux/store'
import { openDialog, closeDialog } from '../../../components/dialog/slices'
import {
  clearUploadedData,
  getDataSuccess,
  hasError,
  uploadProgress as setUploadProgress,
  startLoading,
} from '../../../components/Upload/slices'
import ProgressCircularWithValueLabel from '../../../components/Progress/ProgressCircularWithLabel'
import CheckInfoResult from './CheckInfoResult'
import InProgress from './InProgress'
import { WrapperInProgressStyle } from './styled'
import { callAPI } from '../../../utils/callAPI'
import { validateProspect } from '../../../utils/apiPath'
import { validateColTemplate } from '../../../utils/validateColTemplate'
import { PROGRESS_SUCCESS } from '../../../constants/ma'
import {
  importTemplateCol,
  importProspectSchema,
  extractNumberFromPath,
  type IValidationError,
} from './model/import-data'
import { TABLE_COL_ERR_INDEX } from './model/table-result'
import { selectCrudFormData } from '../../../redux/selector'

type Props = {
  setActiveStep: Dispatch<SetStateAction<number>>
}

export type IImportProspect = {
  seqNo: number
  firstName: string
  lastName: string
  email: string
  phone: string
  agentCode: string
}

type FormData = IImportProspect & {
  error?: string[]
  status?: string
}

function validateImportProspect(
  data: FormData,
  cb?: (data: FormData) => FormData
) {
  return async () => {
    appDispatch(startLoading())

    try {
      await callAPI({
        baseURL: window.__env__.REACT_APP_API_URL,
        url: validateProspect,
        body: data,
        method: 'POST',
        onSuccess(data: FormData) {
          appDispatch(getDataSuccess(cb?.(data) ?? data))
        },
      })
    } catch (error) {
      appDispatch(hasError(error)) // * Set error
      appDispatch(setUploadProgress(0)) // * Reset progress in case of error
    }
  }
}

function handleMapError(
  validatedFields: IValidationError[] | null, /// -> validate from client
  item: IImportProspect,
  index: number,
  errorMessages: string[] = [] // errorMessages -> validate from api
) {
  const { uploadData } = store.getState().upload.data

  const getDuplicateIndices = (data: IImportProspect[]) => {
    const indices: { [key: string]: number } = {}
    const duplicates: number[] = []

    data.forEach((item, index) => {
      if (indices[item.agentCode] !== undefined) {
        duplicates.push(index)
      } else {
        indices[item.agentCode] = index
      }
    })

    return duplicates
  }

  // Check agent code is duplicated
  if (getDuplicateIndices(uploadData)[0] === index) {
    errorMessages[5] = `${errorMessages?.[5] ?? ''}\nรหัสผู้จัดการซ้ำกัน`
  }

  const errorMap = validatedFields?.reduce(
    (acc: Record<string, string[]>, error) => {
      if (!acc[error?.seqNo]) {
        acc[error?.seqNo] = Array.from(
          { length: 6 },
          (_, i) => errorMessages?.[i] ?? ''
        )
      }

      if (acc[error?.seqNo]) {
        acc[error?.seqNo].splice(
          error.col,
          1,
          acc[error?.seqNo][error.col] || error.message
        )
      }

      return acc
    },
    {}
  )

  return errorMap
}

export default function CheckInformationStep({
  setActiveStep,
}: Readonly<Props>) {
  const theme = useTheme()

  const { data, isLoading, validatedData, uploadProgress, error } = useSelector(
    (state) => state.upload
  )

  const formData = useSelector(selectCrudFormData)

  const dispatch = useDispatch()

  const handleCheckInfo = useCallback(() => {
    if (validatedData?.length === data.uploadData.length) {
      return
    }

    const processItem = async (item: IImportProspect, index: number) => {
      const isTemplateInvalid = validateColTemplate(item, importTemplateCol)

      if (isTemplateInvalid) {
        dispatch(
          hasError('ข้อมูลไม่ถูกต้อง กรุณาใส่ข้อมูลตามเทมเพลตที่กำหนดไว้')
        )

        dispatch(startLoading())

        return
      }

      const validated = validateFields(data.uploadData)

      await dispatch(
        validateImportProspect(item, (resData) => ({
          ...resData,
          error:
            handleMapError(validated, item, index, resData.error)?.[
              resData.seqNo
            ] ?? resData.error,
        }))
      )
    }

    const processAllItems = async () => {
      for (const [index, item] of data.uploadData.entries()) {
        await processItem(item, index)
      }
    }

    processAllItems()
  }, [data.uploadData, dispatch, validatedData?.length])

  const handleImportData = () => {
    dispatch(
      openDialog({
        type: '',
        title: 'ยืนยัน',
        message: 'คุณต้องการนำเข้าข้อมูลหรือไม่',
        handleConfirm() {
          setActiveStep((prev) => prev + 1)
          dispatch(closeDialog())
        },
        isCloseDialog: false,
      })
    )
  }

  const handlePrevStep = () => {
    setActiveStep((prev) => prev - 1)
  }

  const handlePrevError = () => {
    dispatch(clearUploadedData())
    dispatch(hasError(null))

    handlePrevStep()
  }

  useEffect(() => {
    handleCheckInfo()

    dispatch(setUploadProgress(0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (validatedData?.length && uploadProgress !== PROGRESS_SUCCESS) {
      dispatch(
        setUploadProgress(
          Math.round((validatedData?.length * 100) / data.uploadData.length) // * calculate percentage in complete to validate uploaded data
        )
      )
    }
  }, [data.uploadData.length, dispatch, uploadProgress, validatedData])

  return (
    <Box mt={2}>
      <Typography variant="h6">
        {isLoading ? 'นำเข้าข้อมูล' : 'ตรวจสอบข้อมูล'}
      </Typography>

      {isLoading ? (
        <WrapperInProgressStyle isError={!!error}>
          {error ? ( // * Invalid template
            <InProgress
              icon={
                <CancelRoundedIcon
                  color="error"
                  fontSize="large"
                  sx={{ width: 56, height: 56 }}
                />
              }
              title="ข้อมูลไม่ถูกต้อง"
              desc="กรุณาใส่ข้อมูลตามเทมเพลตที่กำหนดไว้"
              key="upload-failure"
            />
          ) : (
            <>
              {/* uploading in-progress */}
              <InProgress
                icon={<ProgressCircularWithValueLabel value={uploadProgress} />}
                title="กำลังตรวจสอบข้อมูล"
                desc="กรุณาอย่าปิดหน้าจนกว่าจะทำรายการสำเร็จ"
                key="upload-in-progress"
              />
            </>
          )}
        </WrapperInProgressStyle>
      ) : (
        <CheckInfoResult />
      )}

      {/* handle check information is not complete */}
      <Stack flexDirection="row" gap={1.5} justifyContent="flex-end">
        {isLoading ? (
          <Button
            variant="outlined"
            disabled={!error}
            sx={{
              color: themeColor.primary.light,
              borderRadius: '4px',
              marginTop: theme.spacing(2),
            }}
            onClick={handlePrevError}
          >
            {!error ? 'ยกเลิก' : 'ย้อนกลับ'}
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              sx={{ color: themeColor.primary.light, borderRadius: '4px' }}
              onClick={handlePrevStep}
            >
              ย้อนกลับ
            </Button>

            <Button
              variant="contained"
              sx={{
                color: themeColor.primary.contrast,
                borderRadius: '4px',
              }}
              onClick={handleImportData}
              disabled={
                validatedData?.some((item) =>
                  (item.error as string[]).some((err) => !!err)
                ) || isEmpty(formData?.subSource)
              }
            >
              เริ่มการนำเข้า
            </Button>
          </>
        )}
      </Stack>
    </Box>
  )
}

// ฟังก์ชันตรวจสอบการซ้ำ
function checkDuplicateSeqNo(
  uploadData: UploadData[],
  validated: IValidationError[] = []
): IValidationError[] {
  const seqNoMap: { [key: number]: number } = {}

  uploadData.forEach((data) => {
    const seqNo = data.seqNo
    if (seqNoMap[seqNo]) {
      seqNoMap[seqNo] += 1
    } else {
      seqNoMap[seqNo] = 1
    }
  })

  for (const seqNo in seqNoMap) {
    if (seqNoMap[seqNo] > 1) {
      const duplicateError = {
        seqNo: Number(seqNo),
        message: `Seq No ${seqNo} ซ้ำกัน`,
        col: 0,
      }
      validated?.push(duplicateError)
    }
  }

  return validated
}

type UploadData = {
  seqNo: number
  firstName: string
  lastName: string
  email: string
  phone: string
  agentCode: string
}
function validateFields(uploadData: UploadData[]): IValidationError[] | null {
  const validated: IValidationError[] = []

  try {
    // Validate data and return the values of successful
    importProspectSchema.validateSync(uploadData, {
      strict: false,
      abortEarly: false,
    })
  } catch (error: any) {
    if (error instanceof yup.ValidationError) {
      const extract = (path: string) => {
        return parseInt(
          RegExp(extractNumberFromPath).exec(path)?.[1] ?? '-9999',
          10
        )
      }

      const getSeqNo = (
        fieldName: keyof typeof TABLE_COL_ERR_INDEX,
        e: yup.ValidationError
      ): number => {
        if (fieldName === 'seqNo') {
          return e.value
        }

        return RegExp(extractNumberFromPath).exec(String(e.path))?.[1]
          ? extract(String(e.path)) + 1
          : -1
      }

      const err = uniqBy(
        error?.inner?.map((e) => {
          const col = (e.path ?? '').split(
            '.'
          )[1] as keyof typeof TABLE_COL_ERR_INDEX

          return {
            seqNo: getSeqNo(col, e),
            message: e.message,
            col: TABLE_COL_ERR_INDEX[col],
          }
        }),
        (val) => `${val.seqNo}${val.col}`
      )

      validated.push(...err)
    }
  }

  // ตรวจสอบการซ้ำของ seqNo
  validated.push(...checkDuplicateSeqNo(uploadData, validated))

  return validated
}
