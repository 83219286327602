import { setFieldValue } from '../slices'
import _ from 'lodash'
import { AppDispatch } from '../../../redux/store'

export function createDebouncedSetField(
  dispatch: AppDispatch,
  FIELD_NAME: string
) {
  return _.debounce((inputValue) => {
    dispatch(
      setFieldValue({ key: `formData.${FIELD_NAME}`, value: inputValue })
    )
  }, 300)
}
