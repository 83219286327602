import _ from 'lodash'
import Button, { ButtonProps } from '@mui/material/Button'
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
const getDefSizeSX = (size: string | undefined) => {
  const sizeSx = {
    boxShadow: 'none',
    borderRadius: 10,
    px: 4,
    py: '4px',
    pb: '3px',
  }
  if (size === 'small') {
    sizeSx.py = '1px'
    sizeSx.px = 2
    sizeSx.pb = '0px'
  } else if (size === 'large') {
    sizeSx.py = '5px'
    sizeSx.pb = '4px'
  }
  return sizeSx
}
const CustomButton = (props: ButtonProps) => {
  return (
    <Button
      variant="contained"
      color="info"
      {...props}
      sx={{
        ...getDefSizeSX(props.size),
        ..._.get(props, 'sx', {}),
      }}
    >
      {props.children}
    </Button>
  )
}

export const CustomLoadingButton = (props: LoadingButtonProps) => {
  const setSxIsLoading = (loading: boolean | undefined) => {
    if (!loading) return
    return {
      pr: 5,
    }
  }
  return (
    <LoadingButton
      variant="contained"
      color="info"
      loadingPosition="end"
      {...props}
      sx={{
        ...getDefSizeSX(props.size),
        ..._.get(props, 'sx', {}),
        ...setSxIsLoading(props?.loading),
      }}
    >
      {props.children}
    </LoadingButton>
  )
}
export default CustomButton
