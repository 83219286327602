import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'

export const BasicInfo = () => {
  return (
    <>
      <CustomInput required labelName="Email" fieldName="email" />
      <CustomInput required labelName="First Name" fieldName="firstName" />
      <CustomInput required labelName="Last Name" fieldName="lastName" />
    </>
  )
}
