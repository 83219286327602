import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'

const DataGridSkeleton = () => {
  const rows = Array.from(new Array(10)) // จำนวนแถวที่ต้องการแสดง skeleton (10 แถว)
  const columns = Array.from(new Array(6)) // จำนวนคอลัมน์ที่ต้องการแสดง skeleton (6 คอลัมน์)

  return (
    <Box
      sx={{
        height: 325,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        overflow: 'hidden',
      }}
    >
      {rows.map((_, rowIndex) => (
        <Grid
          container
          key={rowIndex}
          spacing={1}
          sx={{
            mb: 1,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          {columns.map((_, colIndex) => (
            <Grid item xs={2} key={colIndex}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={30} // ความสูงของ skeleton แต่ละแถว
                sx={{ borderRadius: 1 }}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  )
}

export default DataGridSkeleton
