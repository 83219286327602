export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterItems = [
  {
    label: 'ชื่ออีเว้นท์',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    type: 'text',
  },
  {
    label: 'รายละเอียดอีเว้นท์',
    nameCheckBox: 'descriptionChecked',
    nameInput: 'description',
    type: 'text',
  },
  {
    label: 'สถานที่อีเว้นท์',
    nameCheckBox: 'locationChecked',
    nameInput: 'location',
    type: 'text',
  },
  {
    label: 'ระยะอีเว้นท์',
    placeholder: 'เลือก ระยะอีเว้นท์',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    dateStateStart: 'dateStartDate',
    dateStateFinish: 'dateExprityDate',
    dateStateDisplay: 'dateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
