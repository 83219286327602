import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const HeadLabelHistory = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: 576,
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
}))

interface ContentHistoryProps {
  isLoading: boolean
}

export const ContentHistory = styled(Box)<ContentHistoryProps>(
  ({ isLoading }) => ({
    position: 'relative',
    opacity: isLoading ? 0.4 : 'unset',
    pointerEvents: isLoading ? 'none' : 'unset',
    '& > .MuiBox-root:first-of-type': {
      justifyContent: 'space-between',
      display: 'flex',
    },
  })
)

export const StyledTable = {
  background: '#FFFFFF',
  border: '1px solid rgba(219, 228, 241, 0.60)',
  borderRadius: '8px',
}
