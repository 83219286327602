import Box from '@mui/material/Box'
import { themeColor } from '../../../../../styles/themes/themeData'
import { HtmlContent } from '../../../../../components/HtmlContent'
import { Typography } from '@mui/material'
import { AgentStepList } from '../../constants/statusGroup'
import Lottie from 'react-lottie'
import * as animationData from '../../../../../assets/lottie/signal.json'
export enum stepStatus {
  'PENDING',
  'FAILED',
  'INPROGRESS',
  'DONE',
}
export interface StepItem {
  id: number
  icon: React.ReactNode
  name: string
  status: stepStatus
}
export const ItemDesktop = (props: {
  status: 'pending' | 'inprogress' | 'done' | string
  data: AgentStepList
}) => {
  const getColor = (status: string) => {
    if (status === 'done' || status === 'inprogress') {
      return themeColor.primary.main
    } else {
      return themeColor.grey.grey600
    }
  }
  const color = getColor(props.status)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <Box position={'relative'}>
      <Box color={color}>
        {props.status == 'inprogress' ? (
          <Box sx={{ position: 'relative', top: '-2px' }}>
            <Box sx={{ visibility: 'hidden' }}>{props.data.icon}</Box>
            <Box
              sx={{
                position: 'absolute',
                display: 'inline-block',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
            >
              <Lottie options={defaultOptions} height={100} width={100} />
            </Box>
          </Box>
        ) : (
          <> {props.data.icon}</>
        )}

        <Box mt={2}>
          <HtmlContent content={props?.data?.title} />
        </Box>
      </Box>
      {props.data.id > 1 && (
        <Box
          sx={{
            width: '50%',
            display: 'block',
            right: '75%',
            top: { xs: '15px', sm: '10px' },
            position: 'absolute',
            borderBottomWidth: 4,
            borderBottomStyle: 'dotted',
            borderBottomColor: color,
          }}
        ></Box>
      )}
    </Box>
  )
}
export const ItemMobile = (props: { status: string; data: AgentStepList }) => {
  const getColor = (status: string) => {
    if (status === 'done' || status === 'inprogress') {
      return themeColor.primary.main
    } else {
      return themeColor.grey.grey600
    }
  }
  const color = getColor(props.status)
  return (
    <Box>
      <Box
        color={color}
        sx={{
          textAlign: 'left',
          display: 'flex',
          justifyItems: 'center',

          gap: 2,
        }}
      >
        <Box>{props.data.icon}</Box>
        <Typography variant="body1">
          {props?.data?.title.replace('<br/>', '')}
        </Typography>
      </Box>
    </Box>
  )
}
