import {
  setInitialTable,
  stopLoading,
} from '../../../../../../components/Table/redux'
import _ from 'lodash'
import { store } from '../../../../../../redux/store'
import { callAPI } from '../../../../../../utils/callAPI'
import { convertFormatDateTime } from '../../../../../../utils/convertFormatDateTime'
import { ROW_PAGE_PER_LIST } from '../../../../../../constants/table/rowPerPageList'
import { handleDownloadClick } from './donwloadClick'

export const fetchDataList = async (mutatePropToBody: any) => {
  const { table, search, filter: filterState } = store.getState().table
  const { crudConfig } = store.getState().crud
  const { page } = table
  const { status, filterProp } = filterState
  const { searchText } = search

  const realPage = page <= 0 ? 1 : +page
  const tableProps = table
  const filter = filterProp

  const body = {
    limit: _.get(tableProps, 'limit', ROW_PAGE_PER_LIST[0].value),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'createdAt'),
    quickSearch: searchText,
    ...mutatePropToBody(),
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
  }

  store.dispatch(stopLoading())

  const isCancel = await callAPI({
    method: 'post',
    url: crudConfig?.filterPath,
    body: body,
    headers: { 'x-type': crudConfig?.moduleId ?? '' },
    onSuccess: (res) => {
      const row = _.get(res, 'result', []).map((item: any) => {
        return {
          ...item,
          createdAt: convertFormatDateTime({
            value: item.createdAt,
            type: 'date',
          }),
        }
      })

      store.dispatch(
        setInitialTable({
          rows: row,
          allCount: _.get(res, 'totalCount', 0),
          headCells: crudConfig?.headerCells,
          status: status,
          handleSearch: true,
          onDownload: (selected: string[], sort: string, order: string) =>
            handleDownloadClick(selected, sort, order),
        })
      )
    },
    onError: (e) => {
      store.dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: crudConfig?.headerCells,
        })
      )
      return e.message?.includes('method')
    },
  })
  if (isCancel) store.dispatch(stopLoading())
}
