import { store } from '../../../../../../redux/store'
import _ from 'lodash'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    name: _.get(filterProp, 'name', ''),
    description: _.get(filterProp, 'description', ''),
    location: _.get(filterProp, 'location', ''),
    dateStartDate: _.get(filterProp, 'dateStartDate', ''),
    dateExprityDate: _.get(filterProp, 'dateExprityDate', ''),
    status: _.get(filterProp, 'status', []),
  }
}
