import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { BoxProps } from '@mui/system'

interface StyledDateInputProps extends BoxProps {
  isError?: boolean
  disabled?: boolean
}

export const StyledDateRangeContainer = styled(Box)({
  position: 'relative',
})

export const StyledDateInput = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<StyledDateInputProps>(({ theme, disabled, isError }) => {
  let borderColor = theme.palette?.text?.silver
  if (isError) borderColor = theme.palette?.error?.main
  if (disabled) borderColor = theme.palette?.action?.disabled
  return {
    width: '100%',
    height: 40,
    border: `1px solid`,
    borderColor,
    cursor: disabled ? 'default' : 'pointer',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    background: disabled
      ? theme.palette?.action?.disabledBackground
      : 'transparent',
    ':hover': {
      borderColor: disabled ? borderColor : theme?.palette?.text?.primary,
    },
    '& .MuiIconButton-root': {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  }
})
