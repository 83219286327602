//CRUD
export const CRUDListFilterPath = '/crud/filter'
export const CRUDListDownloadPath = '/crud/download'

// assign-rule
export const ManagerListFilterPath = '/simple-rule/filter/manager'
export const ManagerDownloadAllPath = '/simple-rule/download/all/manager'
export const ManagerListDownloadPath = '/simple-rule/download/manager'
export const managerStatusPath = '/simple-rule/status/manager'
export const validateManager = '/simple-rule/validate/manager'
export const importManager = '/simple-rule/import/manager'
export const simpleRuleProvince = '/simple-rule/provinces'
export const simpleRuleWorkingArea = '/simple-rule/filter/wokingarea'
export const simpleRuleNearbyArea = '/simple-rule/nearby-area'

// manage
export const manageFile = '/file/manage'

//prospect
export const prospectList = '/appointment/list'
export const prospectListDownload = '/appointment/download'
export const validateProspect = '/candidate/validate/prospect'
export const candidateFilterList = '/candidate/filter'
export const importProspect = '/candidate/import/prospect'
export const prospectDeail = '/appointment/detail'
export const CandidateListDownloadPath = '/candidate/download'
export const CandidateDownloadAllPath = '/candidate/download/all'

//permission
export const permission = '/permission'
export const permissionGroup = '/permissionGroup'
export const filterStaffProfile = '/staff-profile/filter'
export const downloadPermissionGroup = '/permissionGroup/download'
export const filterPermissionGroup = '/permissionGroup/filter'
export const historyPermissionGroup = '/permissionGroup/history'
