import { CrudState } from '../../types/CrudState'

export const initialState: CrudState = {
  formData: {},
  formErrors: [],
  masterData: {},
  isLoading: false,
  isSubmitting: false,
  isShowHistoryDrawer: false,
  crudConfig: undefined,
  bodyMutation: undefined,
}
