import { setInitialTable, stopLoading } from '../../Table/redux'
import _ from 'lodash'
import { store } from '../../../redux/store'
import { callAPI } from '../../../utils/callAPI'
import { convertFormatDateTime } from '../../../utils/convertFormatDateTime'
import { ROW_PAGE_PER_LIST } from '../../../constants/table/rowPerPageList'
import { handleDeleteClick } from './handleDeleteClick'
import { handleDownloadClick } from './onDownload'

export const fetchDataList = async (mutatePropToBody: any) => {
  const { table, search, filter: filterState } = store.getState().table
  const { crudConfig } = store.getState().crud
  const { page } = table
  const { status, filterProp } = filterState
  const { searchText } = search

  const realPage = page <= 0 ? 1 : +page
  const tableProps = table
  const filter = filterProp

  const body = {
    limit: _.get(tableProps, 'limit', ROW_PAGE_PER_LIST[0].value),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    quickSearch: searchText,
    ...mutatePropToBody(),
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
  }

  store.dispatch(stopLoading())

  const isCancel = await callAPI({
    method: 'post',
    url: crudConfig?.filterPath,
    body: body,
    headers: { 'x-type': crudConfig?.moduleId ?? '' },
    onSuccess: (res) => {
      const row = _.get(res, 'result', []).map((item: any) => {
        let name
        if (crudConfig?.filterPath === '/appointment/list') {
          name = {
            href: `/prospect/detail/${item.uuid}`,
            text: item.name,
          }
        } else {
          name = item.name
        }
        return {
          ...item,
          createdAt: convertFormatDateTime({
            value: item.createdAt,
            type: 'date',
          }),
          name,
        }
      })
      store.dispatch(
        setInitialTable({
          rows: row,
          allCount: _.get(res, 'totalCount', 0),
          headCells: crudConfig?.headerCells,
          status: status,
          handleSearch: true,
          onDownload: (selected: string[], sort: string, order: string) =>
            handleDownloadClick(selected, sort, order),
          onDelete: <T extends { uuid: string }>(selected: T) => {
            handleDeleteClick(selected.uuid, mutatePropToBody)
          },
        })
      )
    },
    onError: (e) => {
      store.dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: crudConfig?.headerCells,
        })
      )
      return e.message?.includes('method')
    },
  })
  if (isCancel) store.dispatch(stopLoading())
}
