import { store } from '../../../redux/store'
import { closeDialog, openErrorDialog } from '../../../components/dialog/slices'

export const handleFetchError = ({ response }: any) => {
  if (response) {
    displayServerError()
  } else {
    displayClientError()
  }
}

export const displayServerError = (): void => {
  store.dispatch(
    openErrorDialog({
      message: 'ระบบขัดข้อง พบข้อผิดพลาดบนเซิฟเวอร์',
      title: 'ผิดพลาด',
      isCloseDialog: false,
      handleConfirm: () => {
        store.dispatch(closeDialog())
      },
    })
  )
}

export const displayClientError = () => {
  store.dispatch(
    openErrorDialog({
      message: 'ระบบขัดข้อง พบข้อผิดพลาดบนเว็บไซต์',
      title: 'ผิดพลาด',
      handleConfirm: () => {
        store.dispatch(closeDialog())
      },
      isCloseDialog: false,
    })
  )
}
