import {
  startLoading,
  stopLoading,
} from '../../../../../components/Table/redux'
import { dispatch, store } from '../../../../../redux/store'
import { downloadFileCandidate } from '../../../../../services/utils/downloadFile'
import { CandidateDownloadAllPath } from '../../../../../utils/apiPath'

export const onDownloadFile = async () => {
  const { crudConfig } = store.getState().crud
  dispatch(startLoading())

  dispatch(
    downloadFileCandidate({
      url: CandidateDownloadAllPath,
      fileName: `ตัวอย่างไฟล์นำเข้า${crudConfig?.moduleTitleFull}.xlsx`,
    })
  )

  dispatch(stopLoading())
}
