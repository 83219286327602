import { StatusEnum } from '../../../../../../constants/status'

export const headerCells: any = [
  {
    id: 'status',
    label: 'Status',
    width: '20px',
    minWidth: '20px',
    hideSortIcon: true,
    disablePadding: false,
  },
  {
    id: 'agentCode',
    label: 'เลขที่ตัวแทนผู้จัดการ',
  },
  {
    id: 'managerName',
    label: 'ชื่อผู้จัดการ',
    custom: true,
    customWidth: '150px',
  },
  {
    id: 'workingArea',
    label: 'Working Area',
    custom: true,
    customWidth: '200px',
  },
  {
    id: 'areaQueue',
    label: 'Area Queue',
  },
  {
    id: 'province',
    label: 'Province',
    custom: true,
    customWidth: '200px',
  },
  {
    id: 'provinceQueue',
    label: 'Province Queue',
  },
]

export const statusOptions = [
  {
    label: 'Active',
    value: StatusEnum.ACTIVE,
  },
  {
    label: 'Inactive',
    value: StatusEnum.INACTIVE,
  },
]
