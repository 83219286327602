import Box from '@mui/material/Box'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import IconButton from '@mui/material/IconButton'

const Action = ({ row, rowIndex, onView, onEdit, onDelete, condition }) => {
  const isValidatePermission = true
  const hasDeletePermission = true
  const hasDelete =
    !condition.hideDelete &&
    row.status !== 'DELETED' &&
    (!isValidatePermission || (isValidatePermission && hasDeletePermission))
  return (
    <Box sx={{ minWidth: 120 }}>
      {!condition.hideView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onView(row, rowIndex)}
        >
          <RemoveRedEyeTwoToneIcon color="action" />
        </IconButton>
      )}

      {!condition.hideEdit && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onEdit(row, rowIndex)}
        >
          <EditTwoToneIcon color="action" />
        </IconButton>
      )}
      {hasDelete && (
        <IconButton
          data-testid={`btn-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onDelete(row, rowIndex)}
        >
          <DeleteTwoToneIcon color="action" />
        </IconButton>
      )}
    </Box>
  )
}

export default Action
