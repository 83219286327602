import {
  useCallback,
  useEffect,
  useRef,
  type Dispatch,
  type SetStateAction,
} from 'react'
import Stack from '@mui/material/Stack'
import useTheme from '@mui/material/styles/useTheme'
import Typography from '@mui/material/Typography'
import { type AutocompleteChangeReason } from '@mui/material'

import { useDispatch, useSelector } from '../../../../../../redux/store'
import { setFieldValue } from '../../../../../../components/CRUD/slices'
import CustomTextfield from '../../../../../../components/Input/CustomTextfield'
import { selectCrudFormData } from '../../../../../../redux/selector'
import { type CrudFormData } from '../model/form'
import type { ResponseProvince } from '../../../../../../types/locations'
//
import { DropdownStyle } from '../styled'

type Props = {
  setTopPosition: Dispatch<SetStateAction<number>>
}

export default function BasicInfo({ setTopPosition }: Readonly<Props>) {
  const formData = useSelector(selectCrudFormData) as CrudFormData

  const dispatch = useDispatch()

  const headerRef = useRef<null | HTMLDivElement>(null)

  const theme = useTheme()

  const getDefaultWorkingArea = useCallback(
    (province: ResponseProvince) => {
      return formData.workingAreas.find((area) =>
        area.province.some((item) => item.uuid === province.uuid)
      )
    },
    [formData.workingAreas]
  )

  const handleChangeInput = (
    index: number,
    reason?: AutocompleteChangeReason
  ) => {
    if (reason === 'clear') {
      dispatch(
        setFieldValue({
          key: `formData.workingArea.${index}`,
          value: {
            ...formData.workingArea?.[index],
            status: 'RESET',
            uuid: '',
            area: '',
          },
        })
      )
    }

    dispatch(setFieldValue({ key: `formData.inputChanged`, value: true }))
  }

  useEffect(() => {
    const setPosition = () => {
      if (headerRef.current) {
        const { top } = headerRef.current.getBoundingClientRect()

        setTopPosition(top)
      }
    }

    window.addEventListener('scroll', setPosition)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', setPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack gap={2} ref={headerRef} mb={theme.spacing(6)}>
      {/* PROVINCE */}
      {formData?.provinces?.map((province, index) => (
        <Stack
          key={province.uuid}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={3}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            flex={1}
            gap={2}
          >
            <Typography variant="body1" minWidth={theme.spacing(2)}>
              {index + 1}
            </Typography>
            <CustomTextfield
              name="province"
              value={province.provinceName}
              disabled
              label="จังหวัด"
              boxSx={{ flex: 1, mb: 0 }}
              sx={{
                padding: theme.spacing(0.5, 1), // 4px, 8px
                borderRadius: '4px',
              }}
              fullWidth
            />
          </Stack>

          {/* WORKING AREA */}
          <DropdownStyle
            value={
              formData.workingArea?.[index] ?? // * value from input changed
              getDefaultWorkingArea(province) ?? // * value from api
              null
            }
            name={`workingArea.${index}`}
            handleExtraChange={(_e, _val, reason) =>
              handleChangeInput(index, reason)
            }
            label="Working Area"
            placeholder="ระบุ Working Area"
            fullWidth
            getOptionLabel={(option) => option?.[index]?.area ?? option.area}
            isOptionEqualToValue={(option, value) =>
              option.uuid === value?.[index]?.uuid
            }
            options={formData?.workingAreas}
            boxSx={{ flex: 1, mb: 0 }}
          />
        </Stack>
      ))}
    </Stack>
  )
}
