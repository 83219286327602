export const headerCells = [
  {
    id: 'status',
    label: 'Status',
    width: '20px',
    minWidth: '20px',
    hideSortIcon: true,
    disablePadding: false,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '90px',
    minWidth: '90px',
  },
  {
    id: 'name',
    label: 'ชื่ออีเว้นท์',
    disablePadding: false,
  },
  {
    id: 'description',
    label: 'รายละเอียดอีเว้นท์',
    custom: true,
    customWidth: '140px',
  },
  {
    id: 'startDate',
    label: 'เวลาเริ่มอีเว้นท์',
    width: '150px',
    disablePadding: false,
    minWidth: '150px',
  },
  {
    id: 'expiryDate',
    label: 'เวลาสิ้นสุดอีเว้นท์',
    width: '150px',
    disablePadding: false,
    minWidth: '150px',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    minWidth: '90px',
    hideSortIcon: true,
    editPath: '/event-sub-source/form',
    hideDelete: false,
  },
]
