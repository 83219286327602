import LoadingPageCircular from '../LoadingPageCircular'
import type { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { StyledFormCard, StyledFormWrapper } from '../../../Styled'
import { useSelector } from '../../../../redux/store'
import { selectCrudLoading } from '../../../../redux/selector'

type Props = {
  header?: React.ReactNode
  content: React.ReactNode
  gap?: number
  sxProps?: Omit<SxProps<Theme>, 'gap'>
}

export const CPageContainer = ({
  header,
  content,
  gap,
  sxProps,
}: Readonly<Props>) => {
  const isLoading = useSelector(selectCrudLoading)

  return (
    <>
      <LoadingPageCircular isLoading={isLoading} />
      <Box display="flex" flexDirection="column" gap={3} sx={{ mx: 3 }}>
        {header}
        <StyledFormWrapper>
          <StyledFormCard isUploadLoading={false} sx={{ gap, ...sxProps }}>
            {content}
          </StyledFormCard>
        </StyledFormWrapper>
      </Box>
    </>
  )
}
