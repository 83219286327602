import { initialConfig } from '../../../components/CRUD/handler/initialConfig'
import { Box, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppDispatch, useSelector } from '../../../redux/store'
import { selectCrudConfig } from '../../../redux/selector'
import { pageConfig as initialCrudConfig } from './pageConfig'
import { initProspectDetail } from './handler/initLoad'

import _ from 'lodash'
import CustomSection from '../../../components/Layout/CustomSection'
import Breadcrumbs from '../../../components/Breadcrumbs'
import { breadcrumb } from './model/breadcrumb'
import CardAppointment from './features/Appointment'
import CardInterview from './features/Interview'
import CardProfile from './features/Profile'
import { CardStepDesktop } from './features/Step'
import CardDetail from './features/Detail'

export default function ProspectDetail() {
  const dispatch: AppDispatch = useDispatch()
  const { uuid } = useParams()
  const crudConfig = useSelector(selectCrudConfig)

  useEffect(() => {
    initialConfig(initialCrudConfig)
  }, [])

  const navigate = useNavigate()

  useEffect(() => {
    const _init = async () => {
      try {
        if (uuid) {
          await dispatch(initProspectDetail(uuid))
        }
      } catch (error) {
        // Handle the error and redirect if necessary
        console.error('Error initializing prospect detail:', error)
        navigate('/prospect')
      }
    }

    _init()
  }, [uuid, dispatch, navigate])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <CustomSection>
      <Typography variant="h4">รายละเอียดผู้สมัคร</Typography>
      <Breadcrumbs sx={{ mb: 3 }} menuList={breadcrumb} />
      <Box>
        <CardProfile />
        <CardStepDesktop />
        <CardDetail />
        <CardAppointment />
        <CardInterview />
      </Box>
    </CustomSection>
  )
}
