export function formatEmail(email: string | undefined, maxLength: number) {
  if (email && email.length > maxLength) {
    const halfLength = Math.floor((maxLength - 3) / 2)
    return `${email.slice(0, halfLength)}...${email.slice(-halfLength)}`
  }
  return email
}

export function formatText(name: string | undefined, maxLength: number) {
  if (name && name.length > maxLength) {
    const halfLength = Math.floor((maxLength - 3) / 2)
    return `${name.slice(0, halfLength)}...${name.slice(-halfLength)}`
  }
  return name
}

export function formatNameSimple(name: string | undefined, maxLength: number) {
  if (name && name.length > maxLength) {
    return `${name.slice(0, maxLength - 2)}..`
  }
  return name
}

export function formatFullNameSimple(
  firstName: string | undefined,
  lastName: string | undefined,
  maxLength: number
) {
  const halfLength = Math.floor(maxLength / 2)

  const formattedFirstName = formatNameSimple(firstName, halfLength)
  const formattedLastName = formatNameSimple(lastName, halfLength)

  if (!formattedFirstName) {
    return `-`
  }

  return `${formattedFirstName} ${formattedLastName}`
}
