export interface ManagerLookup {
  isModalOpen: boolean
  grid: ManagerGrid[]
  isLoading: boolean
}
export interface ManagerGrid {
  id: number
  uuid: string
  agentCode: string
  managerName: string
  email: string
  workingArea: string
  areaQueue: number
  province: string
  provinceQueue: number
  inQueue: number
}
export const model: ManagerLookup = {
  isModalOpen: false,
  grid: [],
  isLoading: false,
}
