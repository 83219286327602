import { store } from '../../../../../../redux/store'
import { STATUS } from '../model/status'
import { getFormatDate } from '../../../../../../utils/getFormatDate'
import { formatDate } from '../constants/formatDate'

export function mutateFilterSateToProp() {
  const { filterState }: any = store.getState().table
  let status = []
  if (filterState?.statusChecked) {
    if (filterState?.status?.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState?.status?.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState?.status?.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }

  return {
    title: filterState.titleChecked ? filterState.title : '',
    description: filterState.descriptionChecked ? filterState.description : '',
    dateStartDate: filterState.dateChecked
      ? getFormatDate(filterState.dateStartDate, formatDate)
      : '',
    dateFinishDate: filterState.dateChecked
      ? getFormatDate(filterState.dateFinishDate, formatDate)
      : '',
    renewDateStartDate: filterState.renewDateChecked
      ? getFormatDate(filterState.renewDateStartDate, formatDate)
      : '',
    renewDateFinishDate: filterState.renewDateChecked
      ? getFormatDate(filterState.renewDateFinishDate, formatDate)
      : '',
    createStartDate: filterState.createDateChecked
      ? getFormatDate(filterState.createStartDate, formatDate)
      : '',
    createFinishDate: filterState.createDateChecked
      ? getFormatDate(filterState.createFinishDate, formatDate)
      : '',
    updateStartDate: filterState.updateDateChecked
      ? getFormatDate(filterState.updateStartDate, formatDate)
      : '',
    updateFinishDate: filterState.updateDateChecked
      ? getFormatDate(filterState.updateFinishDate, formatDate)
      : '',
    status: status,
  }
}
