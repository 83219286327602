import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'OFFLINE_SUB_SOURCE',
  moduleSlug: 'offline-sub-source',
  moduleTitleFull: 'รายการแหล่งย่อยออฟไลน์สำหรับการสร้างการเลือกแหล่งย่อย',
  moduleTitleSecond: 'รายการแหล่งย่อยออฟไลน์',
  modulesTitleShort: 'แหล่งย่อยออฟไลน์',
  modulePath: '/offline-sub-source',
  filterPath: `${CRUDListFilterPath}`,
  headerCells,
}
