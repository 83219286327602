import { Box, Typography, Collapse, IconButton } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { CustomCard } from '../../../../../components/Layout/CustomCard'
import { convertFormatDateTimeV2 } from '../../../../../utils/convertFormatDateTime'
import { SliceModel } from '../../../../../redux/models'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import BoxText from '../../../../../components/Layout/BoxText'
import {
  getStatusDisplayText,
  Keys,
  ProcessResultApprovalStatus,
} from '../../../../../constants/status'

export default function CardAppointment() {
  const { data } = useSelector(
    (state: SliceModel) => ({
      data: state.prospect.detail.data,
    }),
    shallowEqual
  )

  const [open, setOpen] = useState<{ [key: string]: boolean }>({})

  if (_.size(data?.appointment) === 0) return null

  const handleToggle = (uuid: string | null | undefined) => {
    if (typeof uuid !== 'string') return

    setOpen((prev) => ({ ...prev, [uuid]: !prev[uuid] }))
  }

  return (
    <>
      <Box
        display={'flex'}
        mb={2}
        justifyContent="space-between"
        alignItems={'center'}
      >
        <Box pt={1}>
          <Typography variant="h5" color={'primary'}>
            ข้อมูลการนัดหมาย
          </Typography>
        </Box>
      </Box>
      {data?.appointment?.map((appointment) => (
        <CustomCard sx={{ mb: { xs: 2, sm: 3 } }} key={appointment.uuid}>
          <Box
            display={'flex'}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display={'flex'} gap={2}>
              <Typography sx={{ color: 'text.gray' }}>
                {appointment.status ===
                ProcessResultApprovalStatus.I_ACCEPTED_INTERVIEW
                  ? 'วันที่รับนัดหมาย'
                  : 'วันที่ปฏิเสธการนัดหมาย'}
              </Typography>
              <Typography variant="body1b">
                {convertFormatDateTimeV2(
                  appointment.updatedAt,
                  'dateTimeDisplay'
                )}
              </Typography>

              <Typography sx={{ color: 'text.gray' }}>สถานะ </Typography>
              <Typography variant="body1b">
                {getStatusDisplayText(appointment?.status as Keys) || '-'}
              </Typography>
            </Box>
            <IconButton onClick={() => handleToggle(appointment.managerUuid)}>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
          <Collapse
            in={open[appointment.managerUuid ?? '']}
            timeout="auto"
            unmountOnExit
          >
            <Box display={'flex'} gap={2}>
              <Typography sx={{ color: 'text.gray' }}>ผู้จัดการ :</Typography>
              <Typography variant="body1b">
                {appointment?.manager?.name || '-'}
              </Typography>

              <Typography sx={{ color: 'text.gray' }}>รหัส :</Typography>
              <Typography variant="body1b">
                {appointment?.manager?.code || '-'}
              </Typography>
            </Box>

            <Typography sx={{ py: 1, color: 'text.gray' }}>
              รายละเอียดเพิ่มเติม
            </Typography>
            <BoxText>
              {appointment?.reasonAppointmentText && (
                <Typography variant="h6" color={'error'}>
                  {appointment?.reasonAppointmentText || '-'}
                </Typography>
              )}

              {appointment?.comment && (
                <Typography variant="body2">
                  {appointment?.comment || '-'}
                </Typography>
              )}
            </BoxText>
          </Collapse>
        </CustomCard>
      ))}
    </>
  )
}
