export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterItems = [
  {
    label: 'ชื่อแพลตฟอร์ม',
    nameCheckBox: 'titleChecked',
    nameInput: 'title',
    type: 'text',
  },
  {
    label: 'รายละเอียดแพลตฟอร์ม',
    nameCheckBox: 'descriptionChecked',
    nameInput: 'description',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
