import {
  styled,
  useTheme,
  type SxProps,
  type Theme,
} from '@mui/material/styles'
export const checkColorStatus = (status: string | undefined, theme: any) => {
  const statusText = status?.toString().toLowerCase()

  const colorList: any = {
    active: theme?.palette?.success?.main,
    inactive: theme?.palette?.text?.silver,
    deleted: theme?.palette?.text?.white,
    waiting: theme?.palette?.warning?.light,
    waiting_for_active: theme?.palette?.warning?.light,
    approve: theme?.palette?.success?.main,
    reject: theme?.palette?.error?.main,
    draft: theme?.palette?.silver?.primary,
    cancel: theme?.palette?.error?.main,
  }
  return colorList[`${statusText}`]
}

interface DotProps {
  sx?: SxProps<Theme>
  status?: string
}

const Dot: React.FC<DotProps> = ({ sx, status }) => {
  const theme = useTheme()

  return (
    <DotStyle sx={sx} status={status} color={checkColorStatus(status, theme)} />
  )
}
export default Dot

interface DotStyleProps {
  status?: string
  color?: string
}

const DotStyle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'status' && prop !== 'color',
})<DotStyleProps>(({ theme, status, color }) => ({
  borderRadius: 5,
  border: status?.toLowerCase() === 'deleted' ? '1px solid' : 'unset',
  backgroundColor: color,
  borderColor:
    status?.toLowerCase() === 'deleted'
      ? theme?.palette?.text?.primary
      : 'unset',
  width: 8,
  height: 8,
}))
