import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'REJECTED_REASON_APPOINTMENT',
  moduleSlug: 'reject-appointment',
  moduleTitleFull:
    'ประเภทเหตุผลการปฎิเสธนัดสัมภาษณ์ (Rejected Appointment Reason)',
  moduleTitleSecond: 'ประเภทเหตุผลการปฎิเสธนัดสัมภาษณ์',
  modulesTitleShort: 'ประเภทของเหตุผล',
  modulePath: '/reject-appointment',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
