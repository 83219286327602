export interface ManagerData {
  uuid: string
  agentCode: string
  managerName: string
  email: string
  workingArea: string
  areaQueue: number
  province: string
  provinceQueue: number
}

export interface ManagerState {
  isLoading: boolean
  items: ManagerData[]
}
export const initialState: ManagerState = {
  isLoading: false,
  items: [],
}
