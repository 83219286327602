import Box, { type BoxProps } from '@mui/material/Box'
import styled from '@mui/system/styled'

interface StyledBoxProps extends BoxProps {
  minWidth?: string | number
}

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'minWidth',
})<StyledBoxProps>(({ minWidth }) => ({
  minWidth: minWidth ?? 120,
}))
