import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomerSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import Divider from '@mui/material/Divider'

export default function BasicInfo() {
  return (
    <>
      <CustomInput
        required
        labelName="เหตุผล"
        fieldName="text"
        placeholder="ระบุเหตุผล"
      />
      <CustomerSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
    </>
  )
}
