import { useEffect, useState } from 'react'
import CircularProgress, {
  type CircularProgressProps,
} from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styled from '@mui/material/styles/styled'

const CircularProgressStyle = styled(CircularProgress)({
  '&.MuiCircularProgress-determinate': {
    '& .MuiCircularProgress-circle': {
      strokeLinecap: 'round',
    },
  },
})

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgressStyle variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body3b" component="div">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

interface Props extends Omit<CircularProgressProps, 'value'> {
  value: number
  delay?: number
  isCountProgress?: boolean
}

export default function ProgressCircularWithValueLabel({
  value,
  delay,
  isCountProgress = false,
  ...other
}: Readonly<Props>) {
  const [progress, setProgress] = useState(value ?? 0)

  useEffect(() => {
    if (isCountProgress) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 1
        )
      }, delay)

      return () => {
        clearInterval(timer)
      }
    }
  }, [delay, isCountProgress])

  return <CircularProgressWithLabel value={progress} {...other} />
}
