import { useNavigate } from 'react-router-dom'
import { memo } from 'react'
import { Typography, Box } from '@mui/material'
import { breadcrumb } from './model/breadcrumb'
import { getModuleTitle } from './../../utils/getModuleTitle'
import Button from '@mui/material/Button'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { BoxButton, BoxHeader } from './styled'
import { formValidationSchema } from './schema/schema'
import { handleSubmitClick } from '../../../../../components/CRUD/handler/handleSubmitClick'
import { postBodyMutation } from './mutation/post'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import { setFieldValue } from '../../../../../components/CRUD/slices'
import { useDispatch, useSelector } from '../../../../../redux/store'
import { selectCrudFormData } from '../../../../../redux/selector'

const Header = memo((props: { uuid: any; crudConfig: any }) => {
  const { uuid, crudConfig } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formData = useSelector(selectCrudFormData)

  return (
    <BoxHeader>
      <Box>
        <Typography variant="h4">
          {getModuleTitle(uuid, crudConfig.modulesTitleShort)}
        </Typography>
        <Breadcrumbs menuList={breadcrumb(uuid)} />
      </Box>
      <BoxButton>
        {uuid && (
          <Button
            startIcon={<RestoreTwoToneIcon />}
            variant="outlined"
            onClick={() =>
              dispatch(
                setFieldValue({ key: 'isShowHistoryDrawer', value: true })
              )
            }
          >
            ประวัติการแก้ไข
          </Button>
        )}

        {formData?.status !== 'DELETED' && (
          <Button
            type="submit"
            data-testid="btn-submit"
            variant="contained"
            size="medium"
            onClick={() =>
              handleSubmitClick(
                uuid,
                postBodyMutation,
                formValidationSchema,
                navigate
              )
            }
          >
            บันทึกและเผยแพร่
          </Button>
        )}
      </BoxButton>
    </BoxHeader>
  )
})

Header.displayName = 'Header'

export default Header
