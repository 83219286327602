import { store } from '../../../redux/store'

export default function handleScrollToError(key: any) {
  const { crudConfig } = store.getState().crud
  const elementId = `${crudConfig?.moduleSlug}-${key}`
  const errorElement = document.getElementById(elementId)
  if (errorElement) {
    errorElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
}
