import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, ManagerData, ManagerState } from './model'

const ManagerListSlice = createSlice({
  name: 'manager-list',
  initialState,
  reducers: {
    setManagerList: (
      state: ManagerState,
      action: PayloadAction<ManagerData[]>
    ) => {
      state.items = action.payload
    },
    setManagerListIsLoading: (
      state: ManagerState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload
    },
  },
})
export const { setManagerList, setManagerListIsLoading } =
  ManagerListSlice.actions

export default ManagerListSlice.reducer
