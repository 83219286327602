import Stack from '@mui/material/Stack'
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
} from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Button from '@mui/material/Button'

import {
  store,
  dispatch as appDispatch,
  useDispatch,
  useSelector,
} from '../../../redux/store'
import { openDialog, closeDialog } from '../../../components/dialog/slices'
import {
  clearUploadedData,
  getDataSuccess,
  hasError,
  uploadProgress as setUploadProgress,
  startLoading,
} from '../../../components/Upload/slices'
import ProgressCircularWithValueLabel from '../../../components/Progress/ProgressCircularWithLabel'
import CheckInfoResult from './CheckInfoResult'
import InProgress from './InProgress'
import { WrapperInProgressStyle } from './styled'
import { callAPI } from '../../../utils/callAPI'
import { validateManager } from '../../../utils/apiPath'
import { checkQueueData } from '../../../utils/checkImportQueueData'
import { validateColTemplate } from '../../../utils/validateColTemplate'
import {
  AREA_QUEUE_FIELD,
  PROGRESS_SUCCESS,
  SEQ_NO_FIELD,
} from '../../../constants/ma'
import { importTemplateCol } from './model/import-data'
import { handleAgentCode } from './service'
import { isDuplicateAgentCode } from '../../../utils'

type Props = {
  setActiveStep: Dispatch<SetStateAction<number>>
}

export type IImportManager = {
  seqNo: number
  agentCode: string
  email?: string
  managerName: string
  workingArea: string
  areaQueue: number
  province: string
  provinceQueue: number
}

type FormData = IImportManager & {
  error?: string[]
  status?: string
}

function validateImportManager(
  data: FormData,
  cb?: (data: FormData) => FormData
) {
  return async () => {
    appDispatch(startLoading())

    try {
      await callAPI({
        baseURL: window.__env__.REACT_APP_API_URL,
        url: validateManager,
        body: data,
        method: 'POST',
        onSuccess(data: FormData) {
          appDispatch(getDataSuccess(cb?.(data) ?? data))
        },
      })
    } catch (error) {
      appDispatch(hasError(error)) // * Set error
      appDispatch(setUploadProgress(0)) // * Reset progress in case of error
    }
  }
}

function handleMapError(
  validatedQueueData: ReturnType<typeof checkQueueData>, /// -> validate from client
  item: IImportManager,
  errorMessages: string[] = [] // errorMessages -> validate from api
) {
  const { uploadData } = store.getState().upload.data
  
  const errorMap = validatedQueueData.reduce(
    (acc: Record<string, string[]>, error) => {
      if (!acc[error?.seqNo]) {
        acc[error?.seqNo] = Array.from({ length: 7 }, (_, i) =>
          errorMessages ? errorMessages[i] : ''
        )
      }

      const getErrorIndex = (errorMessage: string) => {
        if (errorMessage.includes(SEQ_NO_FIELD)) {
          return 6
        }

        return error.message.includes(AREA_QUEUE_FIELD) ? 3 : 5
      }

      if (acc[error?.seqNo]) {
        acc[error?.seqNo][getErrorIndex(error.message)] = error.message
      }

      return acc
    },
    {}
  )

  // Check for duplicated agent code and add it to errorMap if needed
  if (isDuplicateAgentCode(item.agentCode, uploadData)) {
    console.log(item.agentCode, uploadData, 'check upload data')
    
    // Ensure the current item's seqNo is initialized in errorMap
    if (!errorMap[item.seqNo]) {
      errorMap[item.seqNo] = Array.from({ length: 7 }, () => '')
    }

    // Add the duplicated agent code error at the correct index (e.g., index 0 for this case)
    errorMap[item.seqNo][0] = `${errorMap[item.seqNo][0] ?? ''}\nรหัสผู้จัดการซ้ำกัน`
  }

  return errorMap
}

export default function CheckInformationStep({
  setActiveStep,
}: Readonly<Props>) {
  const theme = useTheme()

  const { data, isLoading, validatedData, uploadProgress, error } = useSelector(
    (state) => state.upload
  )

  const dispatch = useDispatch()

  const handleCheckInfo = useCallback(() => {
    if (validatedData?.length === data.uploadData.length) {
      return
    }

    const validatedQueueData = checkQueueData(data.uploadData)

    const isTemplateValid = validateColTemplate(
      (data.uploadData as IImportManager[]).find(
        (data) => Object.keys(data).length === importTemplateCol.length
      ) ?? {},
      importTemplateCol
    )

    if (isTemplateValid) {
      dispatch(hasError('ข้อมูลไม่ถูกต้อง กรุณาใส่ข้อมูลตามเทมเพลตที่กำหนดไว้'))
      dispatch(startLoading())
      return
    }

    const processItem = async (item: IImportManager) => {
      await dispatch(
        validateImportManager(
          // prettier-ignore
          { ...item, agentCode: handleAgentCode(item.agentCode) }, // handle agent code if length of agentCode is not equal 6
          (resData) => ({
            ...resData,
            error:
              handleMapError(validatedQueueData, item, resData.error)?.[
                resData.seqNo
              ] ?? resData.error,
          })
        )
      )
    }

    const processAllItems = async () => {
      for (const item of data.uploadData) {
        await processItem(item)
      }
    }

    processAllItems()
  }, [data.uploadData, dispatch, validatedData?.length])

  const handleImportData = () => {
    dispatch(
      openDialog({
        type: '',
        title: 'ยืนยัน',
        message: 'คุณต้องการนำเข้าข้อมูลหรือไม่',
        handleConfirm() {
          setActiveStep((prev) => prev + 1)
          dispatch(closeDialog())
        },
        isCloseDialog: false,
      })
    )
  }

  const handlePrevStep = () => {
    setActiveStep((prev) => prev - 1)
  }

  const handlePrevError = () => {
    dispatch(clearUploadedData())
    dispatch(hasError(null))

    handlePrevStep()
  }

  useEffect(() => {
    handleCheckInfo()

    dispatch(setUploadProgress(0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (validatedData?.length && uploadProgress !== PROGRESS_SUCCESS) {
      dispatch(
        setUploadProgress(
          Math.round((validatedData?.length * 100) / data.uploadData.length) // * calculate percentage in complete to validate uploaded data
        )
      )
    }
  }, [data.uploadData.length, dispatch, uploadProgress, validatedData])

  return (
    <Box mt={2}>
      <Typography variant="h6">
        {isLoading ? 'นำเข้าข้อมูล' : 'ตรวจสอบข้อมูล'}
      </Typography>

      {isLoading ? (
        <WrapperInProgressStyle isError={!!error}>
          {error ? ( // * Invalid template
            <InProgress
              icon={
                <CancelRoundedIcon
                  color="error"
                  fontSize="large"
                  sx={{ width: 56, height: 56 }}
                />
              }
              title="ข้อมูลไม่ถูกต้อง"
              desc="กรุณาใส่ข้อมูลตามเทมเพลตที่กำหนดไว้"
              key="upload-failure"
            />
          ) : (
            <>
              {/* uploading in-progress */}
              <InProgress
                icon={<ProgressCircularWithValueLabel value={uploadProgress} />}
                title="กำลังตรวจสอบข้อมูล"
                desc="กรุณาอย่าปิดหน้าจนกว่าจะทำรายการสำเร็จ"
                key="upload-in-progress"
              />
            </>
          )}
        </WrapperInProgressStyle>
      ) : (
        <CheckInfoResult />
      )}

      {/* handle check information is not complete */}
      <Stack flexDirection="row" gap={1.5} justifyContent="flex-end">
        {isLoading ? (
          <Button
            variant="outlined"
            disabled={!error}
            sx={{
              color: theme.palette.primary.light,
              borderRadius: '4px',
              marginTop: theme.spacing(2),
            }}
            onClick={handlePrevError}
          >
            {!error ? 'ยกเลิก' : 'ย้อนกลับ'}
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              sx={{ color: theme.palette.primary.light, borderRadius: '4px' }}
              onClick={handlePrevStep}
            >
              ย้อนกลับ
            </Button>

            <Button
              variant="contained"
              sx={{
                color: theme.palette.primary.contrastText,
                borderRadius: '4px',
              }}
              onClick={handleImportData}
              disabled={validatedData?.some((item) => item.error)} // * not any error
            >
              เริ่มการนำเข้า
            </Button>
          </>
        )}
      </Stack>
    </Box>
  )
}
