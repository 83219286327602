import _ from 'lodash'

export const formatPhoneNo = (data: string): string => {
  if (_.isEmpty(data)) return '-'
  let regExp = /(\d{3})(\d{3})(\d{4})/
  if (data.length === 9) regExp = /(\d{3})(\d{3})(\d{3})/
  if (data.length === 8) regExp = /(\d{3})(\d{3})(\d{2})/
  if (data.length === 7) regExp = /(\d{3})(\d{3})(\d{1})/
  if (data.length === 6) regExp = /(\d{3})(\d{3})/
  return _.defaultTo(data, '').replace(regExp, '$1 $2 $3')
}
