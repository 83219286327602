import Button, { ButtonProps } from '@mui/material/Button'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Box from '@mui/material/Box'
import DownloadIcon from '@mui/icons-material/Download'
import { onDownloadFile } from '../handler/onDownloadFile'

export function ListHeaderAction(props: ButtonProps) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Button
        endIcon={<DownloadIcon />}
        variant="outlined"
        sx={{ marginRight: '8px' }}
        onClick={onDownloadFile}
      >
        ดาวน์โหลดไฟล์ตัวอย่าง
      </Button>
      <Button
        endIcon={<UploadFileIcon />}
        variant="contained"
        onClick={props.onClick}
      >
        อัปโหลด
      </Button>
    </Box>
  )
}
