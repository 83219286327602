import { store } from '../../../../../redux/store'
import _ from 'lodash'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    agentCode: _.get(filterProp, 'agentCode', ''),
    firstName: _.get(filterProp, 'firstName', ''),
    lastName: _.get(filterProp, 'lastName', ''),
    phone: _.get(filterProp, 'phone', ''),
    email: _.get(filterProp, 'email', ''),
    leadSource: _.get(filterProp, 'leadSource', ''),
    importCount: _.get(filterProp, 'importCount', ''),
  }
}
